import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { DictionaryValueForm } from 'pages/DictionariesPage/ViewDictionaryPage/DictionaryValueForm';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { useDictionaries } from 'pages/DictionariesPage/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { Box } from '@mui/material';
import { EDictionaryTypes, IDictionaryValueFormValues } from 'models/dictionary';
import { useTranslations } from 'hooks/useTranslations';
import { initialDictionaryValueFormValues } from 'models/form';
import { stringToBoolean } from 'helpers/objects';

const EditDictionaryValuePage = () => {
  const navigate = useNavigate();
  const { type, id } = useParams<{ type: EDictionaryTypes; id: string }>();
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const {
    getDictionaryTypeLanguagesData,
    getDictionaryTypeValueData,
    dictionaryLanguages,
    dictionaryTypeValue,
    handleUpdate,
  } = useDictionaries();
  const [initialValues, setInitialValues] = useState<IDictionaryValueFormValues>(
    initialDictionaryValueFormValues,
  );
  const { t } = useTranslations();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (type && id) {
      (async function getDictionariesDataInit() {
        await getDictionaryTypeLanguagesData(type);
        await getDictionaryTypeValueData(id);
      })();
    }
  }, [type, id]);

  useEffect(() => {
    if (dictionaryTypeValue) {
      const { isActive, translations } = dictionaryTypeValue;
      const languagesData: any = [];
      dictionaryLanguages.forEach((lang) => {
        const valueLang = translations
          ? translations.find((item) => item.languageUniqueId === lang.uniqueId)
          : null;
        languagesData[`${lang.uniqueId}`] = valueLang ? valueLang.name : '';
      });
      setInitialValues({
        ...initialDictionaryValueFormValues,
        isActive: isActive ?? false,
        translations: languagesData,
      });
    }
  }, [type, dictionaryLanguages, dictionaryTypeValue]);

  const normalizeIsActive = (value: any): boolean => value === 'true' || value === true;

  const checkForChanges = (data: any): boolean => {
    const isActiveEqual =
      normalizeIsActive(initialValues?.isActive) === normalizeIsActive(data.isActive);
    const dataTranslationsKeys = Object.keys(data.translations);
    const translationsEqual = dataTranslationsKeys.every(
      (key) => initialValues?.translations[key] === data.translations[key],
    );
    return isActiveEqual && translationsEqual;
  };

  const handleSubmit = async (data: IDictionaryValueFormValues) => {
    if (data && id && type) {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
      if (!checkForChanges(data)) {
        try {
          await handleUpdate(data, id);
          const currentActiveStatus = stringToBoolean(data.isActive as string);
          if (initialValues.isActive !== currentActiveStatus) {
            setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
              text: currentActiveStatus
                ? t('activateDictionaryValue.success.text')
                : t('inactivateDictionaryValue.success.text'),
              variant: EAlertVariants.success,
            });
          } else {
            setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
              text: t('addDictionaryValue.success.text'),
              variant: EAlertVariants.success,
            });
          }
          navigate(ERouteLinks.ViewDictionary.replace(':type', type));
        } catch (e: any) {
          if (e.response.data.message) {
            setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
              text: e.response.data.message,
              variant: EAlertVariants.error,
            });
          }
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: e.response.data.errors[0].message,
            variant: EAlertVariants.error,
          });
        }
      } else {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: t('general.noChanged.warning.message'),
          variant: EAlertVariants.warning,
        });
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('dictionary.header'),
            route: ERouteLinks.Dictionaries,
          },
          {
            label: `${t('dictionary.header')} ${t(
              'dictionaries.dictionariesTable.view.link',
            )}`,
            route: ERouteLinks.ViewDictionary.replace(':type', type as string),
          },
          t('editDictionaryValue.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('editDictionaryValue.header')}</StyledPageTitle>

      <TableFormWrapper>
        <DictionaryValueForm
          onCancel={() =>
            navigate(ERouteLinks.ViewDictionary.replace(':type', type as string))
          }
          type={type}
          onSubmit={handleSubmit}
          initialFormValues={initialValues}
          dictionaryLanguages={dictionaryLanguages}
          managedByAdmin={dictionaryTypeValue?.managedByAdmin ?? false}
          translatedValues={dictionaryTypeValue?.translatedValues ?? false}
          isEdit={true}
        />
      </TableFormWrapper>

      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export { EditDictionaryValuePage };

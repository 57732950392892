import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  initialLegalEntityIndividualContactFormValues,
  initialLegalEntityOrganizationContactFormValues,
} from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ILegalEntityContact } from 'models/legalEntity';
import { FormSelect } from 'components/molecules/FormSelect';
import { useDictionary } from 'models/dictionaryContext';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { useTranslations } from 'hooks/useTranslations';
import { useLEContactSchema } from 'pages/LegalEntitiesPage/schemaValidation';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useScroll } from 'hooks/useScroll';
import { Box } from '@mui/system';
import { useAlert } from '../../../models/alertContext';
import { ActionTypes } from '../../../state/actions/alert';
import { Alert, EAlertVariants } from '../../../components/atoms/Alert';

export interface ILegalEntityContactFormValues {
  uniqueId: string;
  name?: string;
  role?: string;
  email: string;
  codeDictionaryUniqueId: string;
  value: string;
}

export interface ILegalEntityForm {
  onCancel?: () => void;
  onSubmit: (arg: ILegalEntityContactFormValues | any) => void;
  initialFormValues?: ILegalEntityContact | ILegalEntityContactFormValues | null;
  type: ELegalEntityTypes;
}

const LegalEntityContactForm = ({
  onCancel,
  onSubmit,
  initialFormValues,
  type,
}: ILegalEntityForm) => {
  const { id } = useParams<{ id: string }>();
  const { loadDictionaries } = useLegalEntities();
  const { phoneCode } = useDictionary();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { leContactSchema } = useLEContactSchema(type);
  const { elRef, scrollToError } = useScroll();
  const { setAlert, clearAlert, samePageAlert } = useAlert();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ILegalEntityContact | ILegalEntityContactFormValues>({
    defaultValues:
      initialFormValues || type === ELegalEntityTypes.individual
        ? initialLegalEntityIndividualContactFormValues
        : initialLegalEntityOrganizationContactFormValues,
    resolver: yupResolver(leContactSchema),
  });

  useEffect(() => {
    reset(
      initialFormValues ||
        initialLegalEntityIndividualContactFormValues ||
        initialLegalEntityOrganizationContactFormValues,
    );
  }, [reset, initialFormValues]);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const formChanged = (
    initialValues?: ILegalEntityContact | ILegalEntityContactFormValues | null,
    currentValues?: ILegalEntityContact | ILegalEntityContactFormValues | null,
  ): boolean => {
    const baseDataChanged =
      initialValues?.name !== currentValues?.name ||
      initialValues?.email !== currentValues?.email ||
      initialValues?.role !== currentValues?.role;

    if (initialValues && Object.prototype.hasOwnProperty.call(initialValues, 'phoneNumber')) {
      const initialContact = initialValues as ILegalEntityContact;
      const currentContact = currentValues as ILegalEntityContact;
      const contactDataChanged =
        initialContact.phoneNumber?.codeDictionaryUniqueId !==
          currentContact.phoneNumber?.codeDictionaryUniqueId ||
        initialContact.phoneNumber?.value !== currentContact.phoneNumber?.value;
      return baseDataChanged || contactDataChanged;
    }
    const initialContact = initialValues as ILegalEntityContactFormValues;
    const currentContact = currentValues as ILegalEntityContactFormValues;
    const contactDataChanged =
      initialContact?.codeDictionaryUniqueId !== currentContact?.codeDictionaryUniqueId ||
      initialContact?.value !== currentContact?.value;
    return baseDataChanged || contactDataChanged;
  };

  const onSubmitForm = async (data: any) => {
    if (!formChanged(initialFormValues, data)) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('general.noChanged.warning.message'),
        variant: EAlertVariants.warning,
      });
      return;
    }
    setIsSubmitLoading(true);
    if (initialFormValues) {
      await onSubmit({
        ...initialFormValues,
        ...data,
      });
      setIsSubmitLoading(false);
    } else {
      await onSubmit({
        uniqueId: id as string,
        name: data.name,
        email: data.email,
        role: data.role,
        phoneNumber: {
          codeDictionaryUniqueId: data.codeDictionaryUniqueId,
          value: data.value,
        },
      });
      setIsSubmitLoading(false);
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    (async function init() {
      await loadDictionaries([EDictionaryTypes.PhoneCode]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container ref={elRef}>
        <Grid item xs={12} lg={9} container columnSpacing={2}>
          {type === ELegalEntityTypes.organisation && (
            <Grid item xs={12} sm={6}>
              <FormInput
                name="name"
                label={`${t('addContact.contactName.label')}*`}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            </Grid>
          )}
          {type === ELegalEntityTypes.organisation && (
            <Grid item xs={12} sm={6}>
              <FormInput
                name="role"
                label={`${t('addContact.role.label')}*`}
                setValue={setValue}
                placeholder={t('addContact.role.label')}
                control={control}
                errors={errors}
              />
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <FormInput
              name="email"
              label={`${t('addContact.emailAddress.label')}*`}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} lg={6} container columnSpacing={2}>
            <Grid item xs={6} sm={4} lg={6}>
              <FormSelect
                name="codeDictionaryUniqueId"
                options={mapDictionaryToOptionProp(phoneCode, '+')}
                label={`${t('addContact.phonePrefix.label')}*`}
                setValue={setValue}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6} sm={8} lg={6}>
              <FormInput
                name="value"
                label={`${t('addContact.phoneNumber.label')}*`}
                setValue={setValue}
                control={control}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                label={t('general.save.button')}
                id="save-button"
                variant={EButtonVariants.contained}
                isLoading={isSubmitLoading}
              />
            </Grid>

            {onCancel && (
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('general.cancel.button')}
                  id="cancel-button"
                  variant={EButtonVariants.outlined}
                  onClick={onCancel}
                />
              </Grid>
            )}
          </Grid>
          <Grid item mt={2}>
            <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {samePageAlert && (
        <Box mt={2} sx={{ marginBottom: '10px' }}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </form>
  );
};

export { LegalEntityContactForm };

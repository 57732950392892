import { Box, Tooltip } from '@mui/material';
import { Accordion } from 'components/molecules/Accordion';
import {
  IndividualSearchForm,
  initialValues as initialValuesIndividual,
  ISearchFormCustomFieldValue,
  ISearchFormValues as ISearchFormIndividualValues,
} from 'pages/LegalEntitiesPage/IndividualSearchForm';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import { Table } from 'components/molecules/Table';
import {
  legalEntitiesTableColumns,
  mapLEToDataSource,
  mapOLEToDataSource,
  organizationLegalEntityTableColumns,
} from 'pages/LegalEntitiesPage/helpers';
import {
  initialValues as initialValuesOrganisation,
  ISearchFormILEValues,
  ISearchFormValues as ISearchFormOLEValues,
  OrganisationSearchForm,
} from 'pages/LegalEntitiesPage/OrganisationSearchForm';
import { ERouteLinks } from 'models/route';
import { Alert } from 'components/atoms/Alert';
import { Pagination } from 'components/molecules/Pagination';
import { parseDictionary } from 'helpers/dictionary';
import { LegalEntityTableCard } from 'pages/LegalEntitiesPage/LegalEntityTableCard';
import { CardList } from 'components/molecules/CardList';
import { OrganisationLegalEntityTableCard } from 'pages/LegalEntitiesPage/OrganisationLegalEntityTableCard';
import { Loader } from 'components/atoms/Loader';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAlert } from 'models/alertContext';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { EPerPages, ISortOptions } from 'models/table';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { ActionTypes } from 'state/actions/alert';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { exportLECSV } from 'requests/report';
import { useTranslations } from 'hooks/useTranslations';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { ITranslationKeys } from 'components/organisms/SelectedFilters';
import { useLang } from 'models/langContext';
import { EDictionaryTypes } from 'models/dictionary';
import { useQuery } from 'hooks/useQuery';
import { LETypes } from 'pages/LegalEntitiesPage/LegalEntityAddTransactionForGroupPage';
import {
  ECustomFieldObjectType,
  ECustomFieldType,
  ICustomFieldByObjectType,
} from 'models/customField';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { formatDateTimeToIso } from 'utils/date';
import { isExportAvailable } from 'constants/Export';
import {
  customFieldsPrepareToSearch,
  objectToQueryString,
  prepareDataWithCustomFieldsToSearch,
  prepareQueryValues,
} from 'helpers/searchQuery';

export enum LEAccordionNames {
  INDIVIDUAL = 'individual',
  ORGANISATION = 'organisation',
}

interface ILEAccordions {
  type: ELEAccordionsPageTypes;
  handleSelect?: (arg: any) => void;
  translationKeys?: ITranslationKeys;
  saveRouterStateAfterClearAll: boolean;
}

export enum ELEAccordionsPageTypes {
  LegalEntity = 'LegalEntity',
  UserManagement = 'UserManagement',
  SelectLegalEntity = 'SelectLegalEntity',
}

const LEAccordions = ({
  type,
  handleSelect,
  translationKeys = {},
  saveRouterStateAfterClearAll,
}: ILEAccordions) => {
  const navigate = useNavigate();
  const { pathname }: any = useLocation();
  const { legalEntityAlert, legalEntityOrgAlert, clearAlert } = useAlert();
  const { state: routerState }: any = useLocation();
  const { id: userId = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const limitTo = query.get('limitTo');
  const {
    legalEntities,
    getLegalEntitiesData,
    paginatorILE,
    organizationLegalEntity,
    getOrganizationLegalEntitiesData,
    paginatorOLE,
    organizationType,
    legalVisibility,
    gender,
    loadDictionaries,
    getCustomFields,
    customFieldsILE,
    customFieldsOLE,
    customFieldsLoaded,
  } = useLegalEntities();

  const { t } = useTranslations();
  const [searchParams] = useSearchParams();

  const queryData = useMemo(() => new URLSearchParams(searchParams), []);

  const expandQuery = queryData.get('expand');
  const { selectedLanguage } = useLang();

  const perPageFromQuery = Number.parseInt(searchParams.get('perPage') || '25', 10);
  const currentPageFromQuery = Number.parseInt(searchParams.get('currentPage') || '1', 10);

  const [currentPageILE, setCurrentPageILE] = useState<number>(
    expandQuery === LEAccordionNames.INDIVIDUAL ? currentPageFromQuery : 1,
  );
  const [perPageILE, setPerPageILE] = useState<EPerPages>(
    expandQuery === LEAccordionNames.INDIVIDUAL ? perPageFromQuery : EPerPages.perPage25,
  );

  const [currentPageOLE, setCurrentPageOLE] = useState<number>(
    expandQuery === LEAccordionNames.ORGANISATION ? currentPageFromQuery : 1,
  );
  const [perPageOLE, setPerPageOLE] = useState<EPerPages>(
    expandQuery === LEAccordionNames.ORGANISATION ? perPageFromQuery : EPerPages.perPage25,
  );

  const [isIndividualLoading, setIsIndividualLoading] = useState<boolean>(false);
  const [isOrganizationLoading, setIsOrganizationLoading] = useState<boolean>(false);

  const [LEIndividualSort, setLEIndividualSort] = useState<ISortOptions | null>(null);
  const [isLEIndividualSortLoaded, setIsLEIndividualSortLoaded] = useState<boolean>(true);
  const [LEOrganisationSort, setLEOrganisationSort] = useState<ISortOptions | null>(null);
  const [isLEOrganisationSortLoaded, setIsLEOrganisationSortLoaded] = useState<boolean>(true);

  const [queryOLE, setQueryOLE] = useState<Partial<ISearchFormOLEValues>>({});
  const [isQueryOLELoading, setIsQueryOLELoading] = useState<boolean>(false);
  const [queryILE, setQueryILE] = useState<Partial<ISearchFormILEValues>>({});
  const [isQueryILELoading, setIsQueryILELoading] = useState<boolean>(false);

  const { isDesktop } = useThemeBreakpoint();
  const { hasPermissions } = usePermission();
  const [isIndividualDataLoaded, setIsIndividualDataLoaded] = useState<boolean>(false);
  const [isOrganizationDataLoaded, setIsOrganizationDataLoaded] = useState<boolean>(false);
  const [initialValuesIndividualQuery, setInitialValuesIndividualQuery] =
    useState<ISearchFormIndividualValues | null>(null);
  const [isInitialValuesIndividualQueryLoaded, setIsInitialValuesIndividualQueryLoaded] =
    useState(false);
  const [initialValuesOrganisationQuery, setInitialValuesOrganisationQuery] =
    useState<ISearchFormOLEValues | null>(null);
  const [isInitialValuesOrganisationQueryLoaded, setIsInitialValuesOrganisationQueryLoaded] =
    useState(false);
  const [accordSearchParams, setAccordSearchParams] = useState<LEAccordionNames | null>(null);
  const { shortDateFormat, getDateFormat } = useGlobalProperty();
  const [isIndividualExportLoading, setIndividualExportLoading] = useState(false);
  const [isOrganisationExportLoading, setOrganisationExportLoading] = useState(false);
  const [isIndividualPaginationLoading, setIsIndividualPaginationLoading] = useState(false);
  const [isOrganisationPaginationLoading, setIsOrganisationPaginationLoading] =
    useState(false);
  const [isIndividualInitialLoad, setIsIndividualInitialLoad] = useState(true);
  const [isOrganisationInitialLoad, setIsOrganisationInitialLoad] = useState(true);

  const scrollPosition = useRef(0);
  const [hiddenUniqueIds, setHiddenUniqueIds] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    let hiddenIds = [];
    if (routerState?.currentKeeperUniqueId) {
      hiddenIds.push(routerState?.currentKeeperUniqueId);
    }
    if (routerState?.currentOwnerUniqueId) {
      hiddenIds.push(routerState?.currentOwnerUniqueId);
    }
    if (routerState?.assignedUniqueIds) {
      hiddenIds = routerState?.assignedUniqueIds;
    }
    if (hiddenIds.length > 0) {
      setHiddenUniqueIds(hiddenIds);
    }
  }, [routerState?.currentKeeperUniqueId, routerState?.currentOwnerUniqueId]);

  useEffect(() => {
    getDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customFieldsLoaded) {
      if (expandQuery === LEAccordionNames.INDIVIDUAL) {
        const queryValues = prepareQueryValues(
          initialValuesIndividual,
          queryData,
          [
            'birthdayFrom',
            'birthdayTo',
            ...customFieldsILE
              .filter((cf) => cf.customFieldType === ECustomFieldType.DATE)
              .map((cf) => [`from_${cf.name}`, `to_${cf.name}`]),
          ].flat(),
        );
        if (queryValues) {
          setAccordSearchParams(LEAccordionNames.INDIVIDUAL);
          setInitialValuesIndividualQuery({
            ...initialValuesIndividual,
            ...queryValues,
          });
        } else {
          setIsInitialValuesIndividualQueryLoaded(true);
        }
      } else {
        setIsInitialValuesIndividualQueryLoaded(true);
      }
    }
  }, [queryData, customFieldsLoaded]);

  useEffect(() => {
    if (customFieldsLoaded) {
      setIsInitialValuesOrganisationQueryLoaded(false);
      if (expandQuery === LEAccordionNames.ORGANISATION) {
        const queryValues = prepareQueryValues(
          initialValuesOrganisation,
          queryData,
          [
            ...customFieldsOLE
              .filter((cf) => cf.customFieldType === ECustomFieldType.DATE)
              .map((cf) => [`from_${cf.name}`, `to_${cf.name}`]),
          ].flat(),
        );
        if (queryValues) {
          setAccordSearchParams(LEAccordionNames.ORGANISATION);
          setInitialValuesOrganisationQuery({ ...initialValuesOrganisation, ...queryValues });
        } else {
          setIsInitialValuesOrganisationQueryLoaded(true);
        }
      } else {
        setIsInitialValuesOrganisationQueryLoaded(true);
      }
    }
  }, [queryData, customFieldsLoaded]);

  useEffect(() => {
    if (initialValuesOrganisationQuery !== null) {
      setIsInitialValuesOrganisationQueryLoaded(true);
    }
  }, [initialValuesOrganisationQuery]);

  useEffect(() => {
    if (initialValuesIndividualQuery !== null) {
      setIsInitialValuesIndividualQueryLoaded(true);
    }
  }, [initialValuesIndividualQuery]);

  const previousLEIndividualSortRef = useRef(LEIndividualSort);

  useEffect(() => {
    if (Object.keys(queryILE).length) {
      (async function getLegalEntitiesDataInit() {
        setIsIndividualPaginationLoading(true);
        if (!legalEntities.length) {
          setIsIndividualLoading(true);
        }
        if (LEIndividualSort !== previousLEIndividualSortRef.current) {
          setIsLEIndividualSortLoaded(false);
        }
        customFieldsPrepareToSearch(queryILE.customFields);
        const queryILEData = prepareDataWithCustomFieldsToSearch(
          {
            ...queryILE,
            hiddenUniqueIds,
          },
          customFieldsILE.filter((item: ICustomFieldByObjectType) => item.searchCriteria),
        );
        await getLegalEntitiesData(currentPageILE, perPageILE, LEIndividualSort, queryILEData);
        setIsIndividualLoading(false);
        setIsQueryILELoading(false);
        setIsIndividualPaginationLoading(false);
        setIsLEIndividualSortLoaded(true);
        previousLEIndividualSortRef.current = LEIndividualSort;
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LEIndividualSort, queryILE, customFieldsILE]);

  const previousLEOrganisationSortRef = useRef(LEOrganisationSort);

  useEffect(() => {
    if (Object.keys(queryOLE).length) {
      (async function getOrganizationLegalEntities() {
        setIsOrganisationPaginationLoading(true);
        if (!organizationLegalEntity.length) {
          setIsOrganizationLoading(true);
        }
        if (LEOrganisationSort !== previousLEOrganisationSortRef.current) {
          setIsLEOrganisationSortLoaded(false);
        }
        customFieldsPrepareToSearch(queryOLE.customFields);
        const queryOLEData = prepareDataWithCustomFieldsToSearch(
          { ...queryOLE, hiddenUniqueIds },
          customFieldsOLE.filter((item: ICustomFieldByObjectType) => item.searchCriteria),
        );
        await getOrganizationLegalEntitiesData(
          currentPageOLE,
          perPageOLE,
          LEOrganisationSort,
          queryOLEData,
        );
        setIsOrganizationLoading(false);
        setIsQueryOLELoading(false);
        setIsOrganisationPaginationLoading(false);
        setIsLEOrganisationSortLoaded(true);
        previousLEOrganisationSortRef.current = LEOrganisationSort;
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LEOrganisationSort, queryOLE, customFieldsOLE]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_LEGAL_ENTITY_ALERT);
      clearAlert(ActionTypes.CLEAR_LEGAL_ENTITY_ORG_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.Gender, EDictionaryTypes.LegalVisibility]);
      await getCustomFields([
        ECustomFieldObjectType.LE_INDIVIDUAL,
        ECustomFieldObjectType.LE_ORGANIZATION,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (isIndividualLoading === false) {
      setIsIndividualDataLoaded(true);
    }
  }, [isIndividualLoading, setIsIndividualDataLoaded]);

  useEffect(() => {
    if (!isOrganizationLoading) {
      setIsOrganizationDataLoaded(true);
    }
  }, [isOrganizationLoading, setIsOrganizationDataLoaded]);

  useEffect(() => {
    if (isLEOrganisationSortLoaded && isLEIndividualSortLoaded) {
      window.scrollTo({ top: scrollPosition.current, behavior: 'auto' });
      scrollPosition.current = 0;
    }
  }, [isLEOrganisationSortLoaded, isLEIndividualSortLoaded]);

  const getNewQueryILEPath = (values: ISearchFormILEValues): string => {
    const birthdayFrom = values.birthdayFrom ? formatDateTimeToIso(values.birthdayFrom) : null;
    const birthdayTo = values.birthdayTo ? formatDateTimeToIso(values.birthdayTo) : null;
    const queryILEvalues = { ...values, birthdayFrom, birthdayTo };
    queryILEvalues.preset = undefined;

    const queryString = objectToQueryString(
      { ...queryILEvalues, ...{ perPage: perPageILE, currentPage: currentPageILE } },
      customFieldsILE,
    );
    return `${pathname}?expand=individual&${queryString}`;
  };

  const areCustomFieldValuesSame = (
    cfValues: ISearchFormCustomFieldValue[] | undefined,
    queryValues: ISearchFormCustomFieldValue[] | undefined,
  ): boolean => {
    if (!cfValues || !queryValues) {
      return true;
    }
    let areSame = true;
    cfValues.forEach((cfValue) => {
      const queryCf = queryValues.find(
        (it) => it.customFieldUniqueId === cfValue.customFieldUniqueId,
      );
      if (
        cfValue.bool !== queryCf?.bool ||
        cfValue.text !== queryCf?.text ||
        cfValue.dateFrom !== queryCf?.dateFrom ||
        cfValue.dateTo !== queryCf?.dateTo
      ) {
        areSame = false;
      }
    });

    return areSame;
  };

  const areDatesEqual = (
    date1: Date | null | undefined | string | number,
    date2: Date | null | undefined | string | number,
  ): boolean => {
    if (!date1 && !date2) return true;
    if (!date1 || !date2) return false;

    return new Date(date1).getTime() === new Date(date2).getTime();
  };

  const handleSetQueryILE = (values: ISearchFormILEValues) => {
    if (values.invokedByPageChange !== true) {
      setIsQueryILELoading(true);
    }

    values.invokedByPageChange = undefined;

    const birthdayFrom = values.birthdayFrom ? formatDateTimeToIso(values.birthdayFrom) : null;
    const birthdayTo = values.birthdayTo ? formatDateTimeToIso(values.birthdayTo) : null;

    const queryILEvalues = { ...values, birthdayFrom, birthdayTo };

    if (isIndividualInitialLoad) {
      setIsIndividualInitialLoad(false);
    } else {
      const queryString = objectToQueryString(
        { ...queryILEvalues, perPage: perPageILE, currentPage: currentPageILE },
        customFieldsILE,
      );

      navigate(`${pathname}?expand=individual&${queryString}`, {
        replace: true,
        state: routerState,
      });

      if (
        values.firstname !== queryILE.firstname ||
        values.lastName !== queryILE.lastName ||
        values.city !== queryILE.city ||
        !areDatesEqual(values.birthdayFrom, queryILE.birthdayFrom) ||
        !areDatesEqual(values.birthdayTo, queryILE.birthdayTo) ||
        values.prefixGroupUniqueId !== queryILE.prefixGroupUniqueId ||
        values.visibilityUniqueId !== queryILE.visibilityUniqueId ||
        values.genderUniqueId !== queryILE.genderUniqueId ||
        !areCustomFieldValuesSame(values.customFields, queryILE.customFields)
      ) {
        setCurrentPageILE(1);
      }
    }

    setQueryILE(queryILEvalues);
  };

  const getNewQueryOLEPath = (values: ISearchFormOLEValues): string => {
    const valuesWithoutPreset = { ...values };
    valuesWithoutPreset.preset = undefined;

    const queryString = objectToQueryString(valuesWithoutPreset, customFieldsOLE);
    return `${pathname}?expand=organisation&${queryString}`;
  };

  const handleSetQueryOLE = (values: ISearchFormOLEValues) => {
    if (values.invokedByPageChange !== true) {
      setIsQueryOLELoading(true);
    }

    values.invokedByPageChange = undefined;

    if (isOrganisationInitialLoad) {
      setIsOrganisationInitialLoad(false);
    } else {
      const queryString = objectToQueryString(
        { ...values, perPage: perPageOLE, currentPage: currentPageOLE },
        customFieldsOLE,
      );

      navigate(`${pathname}?expand=organisation&${queryString}`, {
        replace: true,
        state: routerState,
      });

      if (
        values.name !== queryOLE.name ||
        values.organizationTypeUniqueId !== queryOLE.organizationTypeUniqueId ||
        values.departmentName !== queryOLE.departmentName ||
        values.city !== queryOLE.city ||
        values.visibilityUniqueId !== queryOLE.visibilityUniqueId ||
        !areCustomFieldValuesSame(values.customFields, queryOLE.customFields)
      ) {
        setCurrentPageOLE(1);
      }
    }

    setQueryOLE(values);
  };

  const handleIndividualExport = async () => {
    setIndividualExportLoading(true);
    await exportLECSV(ELegalEntityTypes.individual, queryILE, LEIndividualSort);
    setIndividualExportLoading(false);
  };

  const handleOrganisationExport = async () => {
    setOrganisationExportLoading(true);
    await exportLECSV(ELegalEntityTypes.organisation, queryOLE, LEOrganisationSort);
    setOrganisationExportLoading(false);
  };

  const ileTableColumnsEvents = () => {
    switch (type) {
      case ELEAccordionsPageTypes.UserManagement:
        return {
          handleSelect: (LEId: string) =>
            navigate(
              `${ERouteLinks.AssignLE.replace(':id', userId).replace(':LEId', LEId)}?type=${
                ELegalEntityTypes.individual
              }`,
            ),
          type,
        };

      case ELEAccordionsPageTypes.SelectLegalEntity:
        return {
          handleSelect: handleSelect
            ? (legalEntity: any) => handleSelect({ legalEntity })
            : undefined,
          type,
        };
      default:
        return {
          handleView: (id: string) =>
            navigate(
              `${ERouteLinks.ViewLegalEntity.replace(':id', id)}?type=${
                ELegalEntityTypes.individual
              }`,
            ),
          handleEdit: (id: string) =>
            navigate(
              `${ERouteLinks.EditLegalEntity.replace(':id', id)}?type=${
                ELegalEntityTypes.individual
              }`,
            ),
          handleDelete: (id: string) =>
            navigate(
              `${ERouteLinks.DeleteLegalEntity.replace(':id', id)}?type=${
                ELegalEntityTypes.individual
              }`,
            ),
          type,
          customFields: customFieldsILE,
          t,
        };
    }
  };

  const oleTableColumnsEvents = () => {
    switch (type) {
      case ELEAccordionsPageTypes.UserManagement:
        return {
          handleSelect: (LEId: string) =>
            navigate(
              `${ERouteLinks.AssignLE.replace(':id', userId).replace(':LEId', LEId)}?type=${
                ELegalEntityTypes.organisation
              }`,
            ),
          type,
        };
      case ELEAccordionsPageTypes.SelectLegalEntity:
        return {
          handleSelect: handleSelect
            ? (legalEntity: any) => handleSelect({ legalEntity })
            : undefined,
          type,
        };
      case ELEAccordionsPageTypes.LegalEntity:
      default:
        return {
          handleEdit: (id: string) =>
            navigate(
              `${ERouteLinks.EditLegalEntity.replace(':id', id)}?type=${
                ELegalEntityTypes.organisation
              }`,
            ),
          handleDelete: (id: string) =>
            navigate(
              `${ERouteLinks.DeleteLegalEntity.replace(':id', id)}?type=${
                ELegalEntityTypes.organisation
              }`,
            ),
          type,
          customFields: customFieldsOLE,
          t,
        };
    }
  };

  return (
    <Box sx={{ mt: 2 }} width={!isDesktop ? 'calc(100vw - 48px)' : '100%'}>
      {limitTo !== LETypes.organization && (
        <Accordion title={t('legalEntities.individual.accordion.label')} name="individual">
          <div>
            {legalEntityAlert && (
              <Box mb={2}>
                <Alert text={legalEntityAlert.text} variant={legalEntityAlert.variant} />
              </Box>
            )}
            {isIndividualDataLoaded &&
            customFieldsLoaded &&
            isInitialValuesIndividualQueryLoaded ? (
              <IndividualSearchForm
                onSubmit={handleSetQueryILE}
                type={type}
                translationKeys={translationKeys}
                getNewQueryPath={getNewQueryILEPath}
                saveRouterStateAfterClearAll={saveRouterStateAfterClearAll}
                initialFormValues={
                  accordSearchParams === LEAccordionNames.INDIVIDUAL &&
                  initialValuesIndividualQuery
                    ? initialValuesIndividualQuery
                    : initialValuesIndividual
                }
                isSearchButtonDisabled={isIndividualLoading || isQueryILELoading}
                isSearchParams={accordSearchParams === LEAccordionNames.INDIVIDUAL}
                perPage={perPageILE}
                currentPage={currentPageILE}
              />
            ) : null}
            {type === ELEAccordionsPageTypes.LegalEntity && !isIndividualLoading ? (
              <TableTopButtons>
                {hasPermissions([EPermission.EXPORT_LE]) &&
                  (isExportAvailable(paginatorILE) ? (
                    <Tooltip title={t('dataExportRecordsLimitAmount.label')} arrow>
                      <span>
                        <Button
                          label={t('legalEntities.individual.accordion.export.button')}
                          id="legal_entities-individual-export"
                          variant={EButtonVariants.outlined}
                          onClick={handleIndividualExport}
                          size={EButtonSizes.small}
                          isLoading={isIndividualExportLoading}
                          disabled
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    <Button
                      label={t('legalEntities.individual.accordion.export.button')}
                      id="legal_entities-individual-export"
                      variant={EButtonVariants.outlined}
                      onClick={handleIndividualExport}
                      size={EButtonSizes.small}
                      isLoading={isIndividualExportLoading}
                    />
                  ))}
                {hasPermissions([EPermission.MANAGE_LE]) && (
                  <Button
                    label={t('legalEntities.individual.accordion.addLegalEntity.button')}
                    id="legal_entities-individual-add_legal_entities"
                    variant={EButtonVariants.contained}
                    icon={EIconTypes.plus}
                    size={EButtonSizes.small}
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.AddLegalEntity}?type=${ELegalEntityTypes.individual}`,
                      )
                    }
                    sx={{ ml: 1 }}
                  />
                )}
              </TableTopButtons>
            ) : (
              <Box sx={{ mt: 2 }} />
            )}

            {isIndividualLoading || isQueryILELoading ? (
              <Loader isLoading={true} />
            ) : (
              <>
                {isDesktop ? (
                  <Table
                    columns={legalEntitiesTableColumns(
                      ileTableColumnsEvents(),
                      selectedLanguage?.uniqueId,
                      shortDateFormat,
                      hasPermissions,
                    )}
                    dataSource={mapLEToDataSource(
                      legalEntities,
                      hasPermissions([EPermission.VIEW_ALL_LE, EPermission.VIEW_LE], true),
                    )}
                    onSort={(it) => {
                      setLEIndividualSort(it);
                      scrollPosition.current = window.scrollY;
                    }}
                    translationsKeys={{ noResults: translationKeys.noResultIndividual }}
                    isSortLoaded={isLEIndividualSortLoaded}
                  />
                ) : (
                  <CardList
                    items={mapLEToDataSource(
                      legalEntities,
                      hasPermissions([EPermission.VIEW_ALL_LE, EPermission.VIEW_LE], true),
                      parseDictionary(legalVisibility, 'name'),
                      parseDictionary(gender, 'name'),
                    )}
                    render={(data, index) => (
                      <LegalEntityTableCard
                        data={data}
                        key={index}
                        {...ileTableColumnsEvents()}
                      />
                    )}
                    translationsKeys={{ noResults: translationKeys.noResultIndividual }}
                  />
                )}

                {paginatorILE && (
                  <Pagination
                    count={paginatorILE?.totalElements}
                    perPage={perPageILE}
                    onChangePage={(page) => {
                      setCurrentPageILE(page);
                    }}
                    onChangePerPage={(value) => {
                      setPerPageILE(value);
                    }}
                    current={currentPageILE}
                    isLoading={isIndividualPaginationLoading}
                    isVisible={isLEIndividualSortLoaded}
                  />
                )}
              </>
            )}
          </div>
        </Accordion>
      )}
      {limitTo !== LETypes.individual && (
        <Accordion title={t('legalEntities.organization.accordion.label')} name="organisation">
          <>
            {legalEntityOrgAlert && (
              <Box mb={2}>
                <Alert text={legalEntityOrgAlert.text} variant={legalEntityOrgAlert.variant} />
              </Box>
            )}
            {isOrganizationDataLoaded &&
            customFieldsLoaded &&
            isInitialValuesOrganisationQueryLoaded ? (
              <OrganisationSearchForm
                onSubmit={handleSetQueryOLE}
                type={type}
                getNewQueryPath={getNewQueryOLEPath}
                saveRouterStateAfterClearAll={saveRouterStateAfterClearAll}
                translationKeys={{ noResult: translationKeys?.noResultOrganisation }}
                initialFormValues={
                  accordSearchParams === LEAccordionNames.ORGANISATION &&
                  initialValuesOrganisationQuery
                    ? initialValuesOrganisationQuery
                    : initialValuesOrganisation
                }
                isSearchButtonDisabled={isOrganizationLoading || isQueryOLELoading}
                isSearchParams={accordSearchParams === LEAccordionNames.ORGANISATION}
                perPage={perPageOLE}
                currentPage={currentPageOLE}
              />
            ) : null}
            {type === ELEAccordionsPageTypes.LegalEntity && !isOrganizationLoading ? (
              <TableTopButtons>
                {hasPermissions([EPermission.EXPORT_LE]) &&
                  (isExportAvailable(paginatorOLE) ? (
                    <Tooltip title={t('dataExportRecordsLimitAmount.label')} arrow>
                      <span>
                        <Button
                          label={t('legalEntities.individual.accordion.export.button')}
                          id="legal_entities-organisation-export"
                          variant={EButtonVariants.outlined}
                          onClick={handleOrganisationExport}
                          size={EButtonSizes.small}
                          isLoading={isOrganisationExportLoading}
                          disabled
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    <Button
                      label={t('legalEntities.individual.accordion.export.button')}
                      id="legal_entities-organisation-export"
                      variant={EButtonVariants.outlined}
                      onClick={handleOrganisationExport}
                      size={EButtonSizes.small}
                      isLoading={isOrganisationExportLoading}
                    />
                  ))}
                {hasPermissions([EPermission.MANAGE_LE]) && (
                  <Button
                    label={t('legalEntities.individual.accordion.addLegalEntity.button')}
                    id="legal_entities-organisation-add_legal_entities"
                    variant={EButtonVariants.contained}
                    icon={EIconTypes.plus}
                    size={EButtonSizes.small}
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.AddLegalEntity}?type=${ELegalEntityTypes.organisation}`,
                      )
                    }
                    sx={{ ml: 1 }}
                  />
                )}
              </TableTopButtons>
            ) : (
              <Box sx={{ mt: 2 }} />
            )}

            {isOrganizationLoading || isQueryOLELoading ? (
              <Loader isLoading={true} />
            ) : (
              <>
                {isDesktop ? (
                  <Table
                    columns={organizationLegalEntityTableColumns(
                      oleTableColumnsEvents(),
                      selectedLanguage?.uniqueId,
                      shortDateFormat,
                      hasPermissions,
                    )}
                    dataSource={mapOLEToDataSource(
                      organizationLegalEntity,
                      parseDictionary(organizationType, 'name'),
                      parseDictionary(legalVisibility, 'name'),
                    )}
                    onSort={(it) => {
                      setLEOrganisationSort(it);
                      scrollPosition.current = window.scrollY;
                    }}
                    translationsKeys={{ noResults: translationKeys.noResultOrganization }}
                    isSortLoaded={isLEOrganisationSortLoaded}
                  />
                ) : (
                  <CardList
                    items={mapOLEToDataSource(
                      organizationLegalEntity,
                      parseDictionary(organizationType, 'name'),
                      parseDictionary(legalVisibility, 'name'),
                    )}
                    render={(data, index) => (
                      <OrganisationLegalEntityTableCard
                        data={data}
                        key={index}
                        type={type}
                        handleView={(id) =>
                          navigate(
                            `${ERouteLinks.ViewLegalEntity.replace(':id', id)}?type=${
                              ELegalEntityTypes.organisation
                            }`,
                          )
                        }
                        handleEdit={(id) =>
                          navigate(
                            `${ERouteLinks.EditLegalEntity.replace(':id', id)}?type=${
                              ELegalEntityTypes.organisation
                            }`,
                          )
                        }
                        handleDelete={(id) =>
                          navigate(
                            `${ERouteLinks.DeleteLegalEntity.replace(':id', id)}?type=${
                              ELegalEntityTypes.organisation
                            }`,
                          )
                        }
                        {...oleTableColumnsEvents}
                      />
                    )}
                    translationsKeys={{ noResults: translationKeys.noResultOrganization }}
                  />
                )}

                {paginatorOLE && (
                  <Pagination
                    count={paginatorOLE?.totalElements}
                    onChangePage={(page) => setCurrentPageOLE(page)}
                    current={currentPageOLE}
                    perPage={perPageOLE}
                    onChangePerPage={(value) => setPerPageOLE(value)}
                    isLoading={isOrganisationPaginationLoading}
                    isVisible={isLEOrganisationSortLoaded}
                  />
                )}
              </>
            )}
          </>
        </Accordion>
      )}
    </Box>
  );
};

export default LEAccordions;

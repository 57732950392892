import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import { useAuth } from 'models/authContext';
import { useAccount } from 'pages/AccountPage/hooks';
import { useForm } from 'react-hook-form';
import { FormInput } from 'components/molecules/FormInput';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { IAccountResponse } from 'models/account';
import { useDictionary } from 'models/dictionaryContext';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { FormSelect } from 'components/molecules/FormSelect';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'hooks/useTranslations';
import useAccountFormSchema from 'pages/AccountPage/EditAccountPage/schemaValidation';
import {
  FC_SYSTEM_AUTH_ACCESS_TOKEN,
  FC_SYSTEM_AUTH_REFRESH_TOKEN,
  refreshAuthToken,
} from 'requests/auth';
import { Alert, EAlertVariants } from '../../../components/atoms/Alert';
import { ActionTypes } from '../../../state/actions/alert';
import { ERegExpTypes } from '../../../constants/RegExpTypes';
import { useAlert } from '../../../models/alertContext';

interface IAccountFormValues {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  prefix: string;
}

const initialFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  prefix: '',
};

const mapAccountToInitialFormValues = (account: IAccountResponse) => ({
  email: account.email,
  firstName: account.firstName,
  lastName: account.lastName,
  phoneNumber: account?.phoneNumber?.value,
  prefix: account?.phoneNumber?.codeDictionaryUniqueId,
});

const EditAccountPage = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { user, getUser } = useAuth();
  const { account, getSelfAccountData, updateSelf } = useAccount();
  const { phoneCode, getPhoneCode } = useDictionary();
  const { schema } = useAccountFormSchema();
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IAccountFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!phoneCode) {
      getPhoneCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(account ? mapAccountToInitialFormValues(account) : initialFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, initialFormValues, account]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    [],
  );

  useEffect(() => {
    (async function init() {
      if (user?.userId) {
        await getSelfAccountData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasUserMadeNoChanges = (data: IAccountFormValues): boolean => {
    const regexExp = new RegExp(ERegExpTypes.startsWith0);
    const normalizedUserPhoneNumber = data.phoneNumber.replace(regexExp, '');
    const normalizedFormPhoneNumber = account?.phoneNumber.value.replace(regexExp, '');
    return (
      data.firstName === account?.firstName &&
      data.lastName === account?.lastName &&
      data.prefix === account?.phoneNumber.codeDictionaryUniqueId &&
      normalizedUserPhoneNumber === normalizedFormPhoneNumber
    );
  };

  const onSubmitForm = async (data: IAccountFormValues) => {
    if (account) {
      if (hasUserMadeNoChanges(data)) {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: t('general.noChanged.warning.message'),
          variant: EAlertVariants.warning,
        });
      } else {
        setSubmitLoading(true);

        const response = await updateSelf({
          uniqueId: account.uniqueId,
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: {
            value: data.phoneNumber,
            codeDictionaryUniqueId: data.prefix,
          },
          roleIds: account.roleIds,
        });

        setSubmitLoading(false);

        const refreshToken = localStorage.getItem(FC_SYSTEM_AUTH_REFRESH_TOKEN) || '';
        const res = await refreshAuthToken({
          refreshToken,
        });

        if (res?.accessToken && res?.refreshToken) {
          localStorage.setItem(FC_SYSTEM_AUTH_ACCESS_TOKEN, res.accessToken);
          localStorage.setItem(FC_SYSTEM_AUTH_REFRESH_TOKEN, res.refreshToken);
          getUser();
        }

        if (response) {
          navigate(ERouteLinks.Account);
        }
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('userSettings.header'), route: ERouteLinks.Account },
          t('editUserData.header'),
        ]}
      />
      <Box sx={{ mb: 1 }}>
        <StyledPageTitle variant="h4">{t('editUserData.header')}</StyledPageTitle>
      </Box>

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container>
          <Grid item lg={6} sm={12} xs={12}>
            <Grid item xs={12}>
              <Box mb={1}>
                <FormInput
                  name="email"
                  label={`${t('editUserData.username.label')}*`}
                  placeholder={t('editUserData.username.label')}
                  setValue={setValue}
                  disabled={true}
                  control={control}
                  errors={errors}
                />
              </Box>
            </Grid>

            <Grid container item xs={12} columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <FormInput
                  name="firstName"
                  label={`${t('editUserData.firstName.label')}*`}
                  placeholder={t('editUserData.firstName.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormInput
                  name="lastName"
                  label={`${t('editUserData.lastName.label')}*`}
                  placeholder={t('editUserData.lastName.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} columnSpacing={2}>
              <Grid item xs={12} sm={3} lg={4}>
                <FormSelect
                  name="prefix"
                  options={mapDictionaryToOptionProp(phoneCode)}
                  label={`${t('editUserData.phonePrefix.label')}*`}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} sm={9} lg={8}>
                <FormInput
                  name="phoneNumber"
                  label={`${t('editUserData.phoneNumber.label')}*`}
                  placeholder={t('editUserData.phoneNumber.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
              <Grid item xs={12} sm={6} lg={6}>
                <Button
                  type="submit"
                  fullWidth
                  label={t('general.save.button')}
                  id="save-button"
                  variant={EButtonVariants.contained}
                  isLoading={isSubmitLoading}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <Button
                  fullWidth
                  label={t('general.cancel.button')}
                  id="cancel-button"
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(ERouteLinks.Account)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item mt={2}>
            <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
          </Grid>
          <Grid item mt={3} xs={12}>
            {samePageAlert && (
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export { EditAccountPage };

import React, { useEffect } from 'react';
import { IAuditLogItemResponse } from 'models/auditLog';
import { Typography } from '@mui/material';
import {
  AuditLogDetailsWrapper,
  AuditLogEventName,
} from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { useDictionaries } from 'pages/DictionariesPage/hooks';
import Grid from '@mui/material/Grid';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';

interface IAdministrationDictionaryEventDetails {
  auditLog: IAuditLogItemResponse;
}

const AdministrationDictionaryEventDetails = ({
  auditLog,
}: IAdministrationDictionaryEventDetails) => {
  const { getDictionaryTypeValueData, dictionaryTypeValue } = useDictionaries();
  const navigate = useNavigate();
  const { t } = useTranslations();

  useEffect(() => {
    if (auditLog?.objectUniqueId) {
      (async () => {
        await getDictionaryTypeValueData(auditLog.objectUniqueId);
      })();
    }
  }, [auditLog]);

  return (
    <>
      <AuditLogEventName>
        <Typography variant="h5">Dictionary Item Details</Typography>
      </AuditLogEventName>

      {dictionaryTypeValue && (
        <AuditLogDetailsWrapper container rowSpacing={2} sx={{ marginTop: '-6px' }}>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              Dictionary Name
            </Typography>
            <Typography variant="body2">
              {dictionaryTypeValue?.type ? dictionaryTypeValue.type.toUpperCase() : '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              Value Name
            </Typography>
            <Typography variant="body2">{dictionaryTypeValue?.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              Status
            </Typography>
            <Typography variant="body2">
              {dictionaryTypeValue.isActive ? (
                <Chips
                  text={t('general.active.label').toUpperCase()}
                  variant={EChipsVariants.success}
                />
              ) : (
                <Chips
                  text={t('general.inactive.label').toUpperCase()}
                  variant={EChipsVariants.error}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Button
              variant={EButtonVariants.outlined}
              size={EButtonSizes.small}
              onClick={() =>
                navigate(
                  ERouteLinks.ViewDictionary.replace(
                    ':type',
                    dictionaryTypeValue.type as string,
                  ),
                )
              }
              label="Dictionary Details"
              type="button"
              fullWidth
              sx={{
                mt: {
                  lg: 1,
                  sm: 3.5,
                },
              }}
            />
          </Grid>
        </AuditLogDetailsWrapper>
      )}
    </>
  );
};

export default AdministrationDictionaryEventDetails;

import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useFRTFormSchema from 'pages/FRT/schemaValidation';
import { IFRTFormValues, initialFRTFormValues } from 'models/form';
import Grid from '@mui/material/Grid';
import { Accordion } from 'components/molecules/Accordion';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { Alert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { IFRTDetails } from 'models/frt';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { StyledDetailsWrapper } from 'pages/FRT/FRTEditPage/styles';
import { useFRT } from 'pages/FRT/hooks';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapSpecificDictionaryToOptionProp } from 'helpers/dictionary';
import { EIconTypes } from 'constants/Icons';
import { FormAutocomplete } from 'components/molecules/FormAutocomplete';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { EPermission } from 'models/permissions';
import { StyledForm } from 'theme/styles';
import { useScroll } from 'hooks/useScroll';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { FormWyswigEditor } from 'components/molecules/FormWyswigEditor';

export interface IFRTForm {
  frtDetails?: IFRTDetails | null;
  frtDetailsTitle?: string;
  calibres?: string;
  frtCheckIsUsed?: boolean | null;
  initialFormValues?: IFRTFormValues | null;
  isEdit?: boolean;
  onCancel?: () => void;
  onSubmit: (arg: IFRTFormValues, isChanged?: boolean) => void;
}

const FRTForm = ({
  frtDetails,
  frtDetailsTitle,
  calibres,
  frtCheckIsUsed,
  onCancel,
  onSubmit,
  initialFormValues,
  isEdit = false,
}: IFRTForm) => {
  const {
    loadDictionaries,
    manufacturerCountry,
    firearmAction,
    firearmType,
    level,
    legalClassification,
  } = useFRT();
  const MAX_PRODUCT_CODES = 50;

  const { t } = useTranslations();
  const { schema } = useFRTFormSchema();
  const { selectedLanguage } = useLang();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const { samePageAlert, clearAlert } = useAlert();
  const { isDesktop } = useThemeBreakpoint();
  const { elRef, scrollToError } = useScroll();
  const {
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFRTFormValues>({
    defaultValues: initialFormValues || initialFRTFormValues,
    resolver: yupResolver(schema),
  });

  const productCodes = useFieldArray({
    control,
    name: 'productCodes',
  });

  useEffect(() => {
    (async function init() {
      await loadDictionaries([
        EDictionaryTypes.Make,
        EDictionaryTypes.Model,
        EDictionaryTypes.Manufacturer,
        EDictionaryTypes.ManufacturerCountry,
        EDictionaryTypes.FirearmAction,
        EDictionaryTypes.FirearmType,
        EDictionaryTypes.Level,
        EDictionaryTypes.LegalClassification,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    reset(
      initialFormValues !== undefined && initialFormValues !== null
        ? initialFormValues
        : initialFRTFormValues,
    );
  }, [reset, initialFormValues]);

  const areValuesSame = (dataValue: any, formValue: any): boolean => {
    const isEmptyValue = (value: any): boolean =>
      value == null || (typeof value === 'string' && value.trim() === '');

    const isDataValueEmpty = isEmptyValue(dataValue);
    const isFormValueEmpty = isEmptyValue(formValue);

    if (isDataValueEmpty && isFormValueEmpty) return true;
    if (isDataValueEmpty !== isFormValueEmpty) return false;

    return dataValue.toString() === formValue.toString();
  };

  const hasProductCodesChanged = (data: IFRTFormValues): boolean =>
    data?.productCodes.some(
      (item, index) =>
        !areValuesSame(
          item.productCode,
          initialFormValues?.productCodes?.[index]?.productCode,
        ),
    ) || false;

  const formChanged = (data: IFRTFormValues) => {
    if (initialFormValues && data) {
      if (
        areValuesSame(data?.ACT, initialFormValues?.ACT) &&
        areValuesSame(data?.AKA, initialFormValues?.AKA) &&
        areValuesSame(data?.BAR, initialFormValues?.BAR) &&
        areValuesSame(data?.CAL, initialFormValues?.CAL) &&
        areValuesSame(data?.IMG, initialFormValues?.IMG) &&
        areValuesSame(data?.MAK, initialFormValues?.MAK) &&
        areValuesSame(data?.MAN, initialFormValues?.MAN) &&
        areValuesSame(data?.MDT, initialFormValues?.MDT) &&
        areValuesSame(data?.MOD, initialFormValues?.MOD) &&
        areValuesSame(data?.MRK, initialFormValues?.MRK) &&
        areValuesSame(data?.REM, initialFormValues?.REM) &&
        areValuesSame(data?.SER, initialFormValues?.SER) &&
        areValuesSame(data?.SHT, initialFormValues?.SHT) &&
        areValuesSame(data?.TYP, initialFormValues?.TYP) &&
        areValuesSame(data?.action, initialFormValues?.action) &&
        areValuesSame(data?.country, initialFormValues?.country) &&
        areValuesSame(data?.legalClassification, initialFormValues?.legalClassification) &&
        areValuesSame(data?.level, initialFormValues?.level) &&
        areValuesSame(data?.manufacturer, initialFormValues?.manufacturer) &&
        areValuesSame(data?.model, initialFormValues?.model) &&
        areValuesSame(data?.type, initialFormValues?.type) &&
        !hasProductCodesChanged(data)
      ) {
        return false;
      }
    }
    return true;
  };

  const onSubmitForm = async (data: IFRTFormValues) => {
    setIsSubmitLoading(true);
    if (isEdit) {
      onSubmit(data, formChanged(data));
    } else {
      onSubmit(data);
    }
    setIsSubmitLoading(false);
  };

  useEffect(
    () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  const renderAddRemoveProductCodeButton = (index: number) => {
    if (productCodes?.fields?.length > 1 && productCodes?.fields?.length !== index + 1) {
      return (
        <Grid item xs={1}>
          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.delete}
            onClick={() => productCodes.remove(index)}
            sizePX={EButtonSizesPX.px32}
            sx={{ marginTop: '28px' }}
          />
        </Grid>
      );
    }

    return (
      <Grid item xs={12} sm={4} lg={6}>
        <Button
          onClick={() => {
            if (productCodes.fields.length < MAX_PRODUCT_CODES) {
              productCodes.append({
                productCode: '',
              });
            }
          }}
          label={t('addFirearm.addNew.button')}
          variant={EButtonVariants.outlined}
          size={EButtonSizes.small}
          sx={{
            marginTop: { sm: '28px', xs: '0px' },
            width: { xs: '100%', md: 'auto' },
            minWidth: { md: '256px' },
          }}
        />
      </Grid>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <div ref={elRef}>
        {frtCheckIsUsed && (
          <StyledForm container spacing={1} id="main_frtCheckIsUsed">
            <Grid container>
              <Grid item xs={12} mb={2}>
                <DetailsTemplate
                  title={frtDetailsTitle}
                  deletePermissions={[EPermission.MANAGE_FRT_FIREARM]}
                  pageType={EDetailsPageTypes.view}
                  hasLongTitle={true}
                >
                  <Grid item xs={12} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('firearmsReferenceTable.editFirearm.calibres.label')}
                    </Typography>
                    <Typography variant="body2">{calibres}</Typography>
                  </Grid>
                </DetailsTemplate>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} mb={2}>
                <Accordion
                  title={t('firearmsReferenceTable.editFirearm.general.accordion.name')}
                  alwaysExpanded={true}
                >
                  <StyledDetailsWrapper>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6} lg={4}>
                        <Typography variant="subtitle2">
                          {t(
                            'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceLocalID.label',
                          )}
                        </Typography>
                        <Typography variant="body2">{frtDetails?.id}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Typography variant="subtitle2">
                          {t(
                            'firearmsReferenceTable.editFirearm.general.accordion.manufacturer.label',
                          )}
                        </Typography>
                        <Typography variant="body2">{frtDetails?.manufacturer}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <Typography variant="subtitle2">
                          {t(
                            'firearmsReferenceTable.editFirearm.general.accordion.countryOfManufacturer.label',
                          )}
                        </Typography>
                        <Typography variant="body2">{frtDetails?.country}</Typography>
                      </Grid>
                    </Grid>
                  </StyledDetailsWrapper>
                </Accordion>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <FormSelect
                  options={mapSpecificDictionaryToOptionProp(level)}
                  setValue={setValue}
                  name="level"
                  label={`${t(
                    'firearmsReferenceTable.editFirearm.general.accordion.level.label',
                  )}*`}
                  control={control}
                  errors={errors}
                />
              </Grid>

              <Grid item xs={12} lg={6}>
                <FormSelect
                  options={mapSpecificDictionaryToOptionProp(legalClassification)}
                  setValue={setValue}
                  name="legalClassification"
                  label={`${t(
                    'firearmsReferenceTable.editFirearm.general.accordion.legalClassification.label',
                  )}*`}
                  control={control}
                  errors={errors}
                />
              </Grid>
            </Grid>
          </StyledForm>
        )}
        {!frtCheckIsUsed && (
          <StyledForm container spacing={1} id="main">
            <Grid item xs={12} lg={3}>
              <FormAutocomplete
                name="make"
                label={`${t('addFirearm.make.label')}*`}
                control={control}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                optionsLimiter={EDictionaryTypes.Make}
                freeSolo
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <FormAutocomplete
                name="model"
                label={`${t('addFirearm.model.label')}*`}
                control={control}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                optionsLimiter={EDictionaryTypes.Model}
                freeSolo
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <FormAutocomplete
                name="manufacturer"
                label={`${t('addFirearm.manufacturer.label')}*`}
                control={control}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                optionsLimiter={EDictionaryTypes.Manufacturer}
                freeSolo
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <FormSelect
                options={mapSpecificDictionaryToOptionProp(manufacturerCountry, true)}
                setValue={setValue}
                name="country"
                label={`${t('addFirearm.countryOfManufacturer.label')}*`}
                control={control}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <FormSelect
                options={mapSpecificDictionaryToOptionProp(firearmAction)}
                setValue={setValue}
                name="action"
                label={`${t('addFirearm.firearmAction.label')}*`}
                control={control}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <FormSelect
                options={mapSpecificDictionaryToOptionProp(firearmType)}
                setValue={setValue}
                name="type"
                label={`${t('addFirearm.type.label')}*`}
                control={control}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <FormSelect
                options={mapSpecificDictionaryToOptionProp(level)}
                setValue={setValue}
                name="level"
                label={`${t('addFirearm.level.label')}*`}
                control={control}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <FormSelect
                options={mapSpecificDictionaryToOptionProp(legalClassification)}
                setValue={setValue}
                name="legalClassification"
                label={`${t('addFirearm.legalClassification.label')}*`}
                control={control}
                errors={errors}
              />
            </Grid>
          </StyledForm>
        )}

        <Grid container>
          <Grid item xs={12}>
            <Accordion title={t('addFirearm.notes.accordion.name')} alwaysExpanded={true} />
          </Grid>
        </Grid>
        <StyledForm style={{ marginTop: 0 }} container spacing={2} id="notes">
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="MAK"
              label={t('addFirearm.make.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="MOD"
              label={t('addFirearm.model.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="MAN"
              label={t('addFirearm.manufacturer.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="ACT"
              label={t('addFirearm.action.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="CAL"
              label={t('addFirearm.caliber.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="SHT"
              label={t('addFirearm.shots.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="SER"
              label={t('addFirearm.serialNumbering.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="AKA"
              placeholder="Also knows as/ Product code"
              label={t('addFirearm.alsoKnownAsProductCode.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="MRK"
              label={t('addFirearm.otherMarkings.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="REM"
              label={t('addFirearm.remarks.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="BAR"
              label={t('addFirearm.barrelLength.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="IMP"
              label={t('addFirearm.importer.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="TYP"
              label={t('addFirearm.type.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="IMG"
              label={t('addFirearm.image.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormWyswigEditor
              name="MDT"
              label={t('addFirearm.manufacturerDate.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              charsLimit={4000}
            />
          </Grid>
          {!frtCheckIsUsed && (
            <>
              <Grid item xs={12}>
                <Accordion
                  title={t('addFirearm.alsoKnownAsProductCode.label')}
                  alwaysExpanded={true}
                />
              </Grid>

              {productCodes?.fields?.map((productCode, index) => (
                <Grid
                  key={productCode.id}
                  item
                  xs={12}
                  container
                  mb={!isDesktop ? 2 : 0}
                  columnSpacing={2}
                >
                  <Grid item xs={11} sm={8} lg={6}>
                    <FormInput
                      name={`productCodes[${index}].productCode`}
                      label={t('addFirearm.productCode.label')}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      arrayName="productCodes"
                      arrayIndex={index}
                      fieldName="productCode"
                    />
                  </Grid>
                  {renderAddRemoveProductCodeButton(index)}
                </Grid>
              ))}
            </>
          )}

          <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
            <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  type="submit"
                  fullWidth
                  label={t('general.save.button')}
                  id="save-button"
                  variant={EButtonVariants.contained}
                  isLoading={isSubmitLoading}
                />
              </Grid>

              {onCancel && (
                <Grid item xs={12} sm={6} lg={3}>
                  <Button
                    fullWidth
                    label={t('general.cancel.button')}
                    id="cancel-button"
                    variant={EButtonVariants.outlined}
                    onClick={onCancel}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item mt={2} xs={12}>
            <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
          </Grid>
          <Grid item mt={3} xs={12}>
            {samePageAlert && (
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            )}
          </Grid>
        </StyledForm>
      </div>
    </form>
  );
};

export { FRTForm };

import React, { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useTranslations } from 'hooks/useTranslations';
import { StyledPageTitle } from 'theme/styles';
import {
  EArtAmmunitionType,
  EArtAncillaryTypeName,
  EArtComponentType,
} from 'constants/ArtAccessoryType';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes, ETransactionDictionaryItemTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { ERouteLinks } from 'models/route';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { Typography } from '@mui/material';
import {
  getFieldsByAccessoriesType,
  getFieldsByAmmunitionType,
  getFieldsByComponentType,
} from 'pages/AncillariesPage/helpers';
import { colors } from 'theme/colors';
import {
  EAccessoryLabels,
  EAmmunitionLabels,
  EComponentLabels,
} from 'pages/AncillariesPage/AncillariesReferenceTablePage/DetailAncillariesReference';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { EPermission } from 'models/permissions';
import { emptyFieldHolder } from 'components/molecules/CardList';

interface IArtDetails {
  id: string;
  type: string;
}
const ArtDetails = ({ id, type }: IArtDetails) => {
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { isDesktop } = useThemeBreakpoint();
  const navigate = useNavigate();
  const [fields, setFields] = useState<string[] | undefined>(undefined);
  const transactionPagePath = useMatch(ERouteLinks.LegalEntityViewAncillary);
  const isTransactionPage = !!transactionPagePath;
  const ancillaryDetails = useMatch(ERouteLinks.ViewAncillaryDetails);

  const {
    loadDictionaries,
    artAccessoryDetail,
    artAmmunitionDetail,
    artComponentDetail,
    getARTAccessoryDetail,
    getARTAmmunitionDetail,
    getARTComponentDetail,
  } = useAncillaries();

  useEffect(() => {
    if (type && id) {
      if (type === EArtAncillaryTypeName.ACCESSORY.toLowerCase()) {
        (async () => {
          await getARTAccessoryDetail(id);
        })();
      } else if (type === EArtAncillaryTypeName.AMMUNITION.toLowerCase()) {
        (async () => {
          await getARTAmmunitionDetail(id);
        })();
      } else if (type === EArtAncillaryTypeName.COMPONENTS.toLowerCase()) {
        (async () => {
          await getARTComponentDetail(id);
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, id]);

  useEffect(() => {
    if (artAccessoryDetail?.accessoryTypeUniqueId) {
      setFields(getFieldsByAccessoriesType(artAccessoryDetail?.accessoryTypeUniqueId));
    } else if (artAmmunitionDetail?.ammunitionTypeUniqueId) {
      setFields(
        getFieldsByAmmunitionType(
          artAmmunitionDetail?.ammunitionTypeUniqueId as EArtAmmunitionType,
        ),
      );
    } else if (artComponentDetail?.componentTypeUniqueId) {
      setFields(
        getFieldsByComponentType(
          artComponentDetail?.componentTypeUniqueId as EArtComponentType,
        ),
      );
    }
  }, [artAccessoryDetail, artAmmunitionDetail, artComponentDetail]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  return (
    <div>
      {type === EArtAncillaryTypeName.ACCESSORY.toLowerCase() && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {artAccessoryDetail && fields && (
            <Grid container item gap={3}>
              <DetailsTemplate
                deletePermissions={[EPermission.MANAGE_LOCAL_ART]}
                editPermissions={[EPermission.MANAGE_LOCAL_ART]}
                title={artAccessoryDetail?.accessoryType}
                pageType={
                  isTransactionPage
                    ? EDetailsPageTypes.transactionSelectView
                    : EDetailsPageTypes.view
                }
                redirectToView={
                  !ancillaryDetails
                    ? () => {
                        navigate(
                          ERouteLinks.ViewAncillaries.replace(
                            ':id',
                            artAccessoryDetail.uniqueId,
                          ).replace(':type', EArtAncillaryTypeName.ACCESSORY.toLowerCase()),
                        );
                      }
                    : undefined
                }
                actionButtonProps={{
                  ...{ label: t('legalEntityDetails.ancillary.accordion.details.link') },
                  ...(isDesktop ? {} : { size: EButtonSizes.small }),
                  ...(isDesktop ? {} : { sizePX: EButtonSizesPX.px24 }),
                  ...{ variant: EButtonVariants.outlined },
                }}
              >
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.ancillary)}
                  </Typography>
                  <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                    {ETransactionDictionaryItemTypes.ACCESSORY.toLocaleLowerCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.accessoryType)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artAccessoryDetail?.accessoryType ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.artId)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artAccessoryDetail?.artId ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAccessoryLabels.manufacturer)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artAccessoryDetail?.manufacturer ?? '-'}
                  </Typography>
                </Grid>

                {fields.includes('hostTypeUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.hostType)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAccessoryDetail?.hostType ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('calibreUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.calibre)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAccessoryDetail?.calibre ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('compositionUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.composition)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAccessoryDetail?.composition ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('proofHouseUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.proofHouse)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAccessoryDetail?.proofHouse ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('lightColourUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.lightColour)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAccessoryDetail?.lightColour ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('capacity') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.capacity)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAccessoryDetail?.capacity ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('magnificationUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.magnification)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAccessoryDetail?.magnification ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('utilityUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.utility)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAccessoryDetail?.utility ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('rectileUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.rectile)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAccessoryDetail?.rectile ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('productName') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.productName)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAccessoryDetail?.productName ?? '-'}
                    </Typography>
                  </Grid>
                )}
              </DetailsTemplate>
            </Grid>
          )}
        </>
      )}

      {type === EArtAncillaryTypeName.AMMUNITION.toLowerCase() && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {artAmmunitionDetail && fields && (
            <Grid container item gap={3}>
              <DetailsTemplate
                deletePermissions={[EPermission.MANAGE_LOCAL_ART]}
                editPermissions={[EPermission.MANAGE_LOCAL_ART]}
                title={artAmmunitionDetail?.ammunitionType}
                pageType={
                  isTransactionPage
                    ? EDetailsPageTypes.transactionSelectView
                    : EDetailsPageTypes.view
                }
                redirectToView={
                  !ancillaryDetails
                    ? () => {
                        navigate(
                          ERouteLinks.ViewAncillaries.replace(
                            ':id',
                            artAmmunitionDetail.uniqueId,
                          ).replace(':type', EArtAncillaryTypeName.AMMUNITION.toLowerCase()),
                        );
                      }
                    : undefined
                }
                actionButtonProps={{
                  ...{ label: t('legalEntityDetails.ancillary.accordion.details.link') },
                  ...(isDesktop ? {} : { size: EButtonSizes.small }),
                  ...(isDesktop ? {} : { sizePX: EButtonSizesPX.px24 }),
                  ...{ variant: EButtonVariants.outlined },
                }}
              >
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.ancillary)}
                  </Typography>
                  <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                    {ETransactionDictionaryItemTypes.AMMUNITION.toLocaleLowerCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.ammunitionType)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artAmmunitionDetail?.ammunitionType ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.artId)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artAmmunitionDetail?.artId ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.manufacturer)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artAmmunitionDetail?.manufacturer ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.factory)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artAmmunitionDetail?.factory ?? '-'}
                  </Typography>
                </Grid>
                {fields.includes('calibreUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.calibre)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAmmunitionDetail?.calibre ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('cartridgeCompositionUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.cartridgeComposition)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAmmunitionDetail?.cartridgeComposition ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('colourOfPrimerSealantUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.colourOfPrimerSealant)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAmmunitionDetail?.colourOfPrimerSealant ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('projectileShapeUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.projectileShape)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAmmunitionDetail?.projectileShape ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('functionalTypeUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.functionalType)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAmmunitionDetail?.functionalType ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('shotSizeUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.shotSize)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAmmunitionDetail?.shotSize ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('munitionTypeUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.munitionType)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAmmunitionDetail?.munitionType ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('munitionVelocityUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.munitionVelocity)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAmmunitionDetail?.munitionVelocity ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('headstampYearMarking') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.headstampYearMarking)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAmmunitionDetail?.headstampYearMarking ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('alphanumericalMarkings') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.alphanumericalMarkings)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAmmunitionDetail?.alphanumericalMarkings ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('productName') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAmmunitionLabels.productName)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artAmmunitionDetail?.productName ?? '-'}
                    </Typography>
                  </Grid>
                )}
              </DetailsTemplate>
            </Grid>
          )}
        </>
      )}

      {type === EArtAncillaryTypeName.COMPONENTS.toLowerCase() && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {artComponentDetail && fields && (
            <Grid container item gap={3}>
              <DetailsTemplate
                deletePermissions={[EPermission.MANAGE_LOCAL_ART]}
                editPermissions={[EPermission.MANAGE_LOCAL_ART]}
                title={artComponentDetail?.componentType}
                pageType={
                  isTransactionPage
                    ? EDetailsPageTypes.transactionSelectView
                    : EDetailsPageTypes.view
                }
                redirectToView={
                  !ancillaryDetails
                    ? () => {
                        navigate(
                          ERouteLinks.ViewAncillaries.replace(
                            ':id',
                            artComponentDetail.uniqueId,
                          ).replace(':type', EArtAncillaryTypeName.COMPONENTS.toLowerCase()),
                        );
                      }
                    : undefined
                }
                actionButtonProps={{
                  ...{ label: t('legalEntityDetails.ancillary.accordion.details.link') },
                  ...(isDesktop ? {} : { size: EButtonSizes.small }),
                  ...(isDesktop ? {} : { sizePX: EButtonSizesPX.px24 }),
                  ...{ variant: EButtonVariants.outlined },
                }}
              >
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.ancillary)}
                  </Typography>
                  <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                    {ETransactionDictionaryItemTypes.COMPONENT.toLocaleLowerCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.componentType)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artComponentDetail?.componentType ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.artId)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artComponentDetail?.artId ?? '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.manufacturer)}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {artComponentDetail?.manufacturer ?? '-'}
                  </Typography>
                </Grid>
                {fields.includes('calibreUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.calibre)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artComponentDetail?.calibre ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('length') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.length)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artComponentDetail?.length ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('compositionUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.composition)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artComponentDetail?.composition ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('proofHouseUniqueId') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.proofHouse)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artComponentDetail?.proofHouse ?? '-'}
                    </Typography>
                  </Grid>
                )}
                {fields.includes('productName') && (
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.productName)}
                    </Typography>
                    <Typography variant="body2" style={{}}>
                      {artComponentDetail?.productName ?? '-'}
                    </Typography>
                  </Grid>
                )}

                <Grid
                  container
                  sx={{
                    borderTop: `1px solid ${colors.primary25}`,
                    marginTop: '30px',
                    paddingTop: '25px',
                  }}
                >
                  <Grid item lg={12} container direction="column">
                    <StyledPageTitle variant="h5" sx={{ marginBottom: '20px' }}>
                      {t('ancillaries.accordion.firearmInformation.label')}
                    </StyledPageTitle>
                  </Grid>

                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.frtType)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {artComponentDetail?.frtType ?? '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.frtManufacturer)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {emptyFieldHolder(artComponentDetail?.frtManufacturer)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.frtMake)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {artComponentDetail?.frtMake ?? '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.frtModel)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {artComponentDetail?.frtModel ?? '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </DetailsTemplate>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default ArtDetails;

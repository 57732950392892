import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import FormLabel from 'components/atoms/FormLabel';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { StyledFormElementWrapper } from 'theme/styles';
import { IFormElementProps } from 'models/form';
import { Editor, createEditor, Transforms, Element as SlateElement, Node } from 'slate';
import { withReact, useSlate, Slate } from 'slate-react';
import { Grid } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { countEditorCharacters } from 'components/organisms/HtmlSlate';
import { getNestedErrorMessage, initialValue, LIST_TYPES, TEXT_ALIGN_TYPES } from './helpers';
import { ButtonToolboxStyle, IconStyle, StyledEditable } from './styles';

interface IElement {
  attributes: any;
  children: any;
  element: any;
}
const Element = ({ attributes, children, element }: IElement) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'h1':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

interface ILeaf {
  attributes: any;
  children: any;
  leaf: any;
}

const Leaf = ({ attributes, children, leaf }: ILeaf) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.code) {
    children = <code>{children}</code>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  return <span {...attributes}>{children}</span>;
};

const isBlockActive = (editor: Editor, format: string, blockType: string = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: Node) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any)[blockType] === format,
    }),
  );
  return !!match;
};

const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? (marks as any)[format] === true : false;
};

const toggleMark = (editor: Editor, format: string) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const toggleBlock = (editor: Editor, format: string) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
  );
  const isList = LIST_TYPES.includes(format);
  Transforms.unwrapNodes(editor, {
    match: (n: Node) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes((n as any).type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties = {};
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? 'undefined' : format,
    };
  } else {
    newProperties = {
      // eslint-disable-next-line no-nested-ternary
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes(editor, newProperties);
  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

interface IButton {
  format: string;
  icon: string;
  disabled?: boolean;
}

const BlockButton = ({ format, icon, disabled }: IButton) => {
  const editor = useSlate();
  return (
    <ButtonToolboxStyle
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
      )}
      onMouseDown={(event: any) => {
        if (!disabled) {
          event.preventDefault();
          toggleBlock(editor, format);
        }
      }}
      reversed={disabled}
    >
      <IconStyle>{icon}</IconStyle>
    </ButtonToolboxStyle>
  );
};

const MarkButton = ({ format, icon, disabled }: IButton) => {
  const editor = useSlate();
  return (
    <ButtonToolboxStyle
      active={isMarkActive(editor, format)}
      onMouseDown={(event: any) => {
        if (!disabled) {
          event.preventDefault();
          toggleMark(editor, format);
        }
      }}
      reversed={disabled}
    >
      <IconStyle>{icon}</IconStyle>
    </ButtonToolboxStyle>
  );
};

interface IFormWyswigEditor extends IFormElementProps {
  disabled?: boolean;
  required?: boolean;
  arrayName?: string;
  arrayIndex?: number;
  setValue?: any;
  setError?: any;
  clearErrors?: any;
  fieldName?: string;
  charsLimit?: number;
  withValidation?: boolean;
  withLabel?: boolean;
  showCounter?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
}

export const FormWyswigEditor = ({
  name,
  label,
  control,
  errors,
  setError,
  clearErrors,
  setValue,
  disabled,
  required,
  arrayName,
  arrayIndex,
  fieldName,
  charsLimit,
  withValidation = true,
  withLabel = true,
  placeholder,
  autoFocus = false,
}: IFormWyswigEditor) => {
  const { t } = useTranslations();
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const [editor] = useState(() => withReact(createEditor()));

  useEffect(() => {
    let isError = false;
    if (name.includes('.') && !name.includes('[') && Object.keys(errors).length) {
      const keys = name.split('.');
      const errorMessage = getNestedErrorMessage(errors, keys);
      if (keys && errorMessage) {
        isError = true;
        setError(errorMessage);
      }
    } else {
      const errorMessage =
        arrayName && arrayIndex !== undefined && fieldName
          ? errors?.[arrayName]?.[arrayIndex]?.[fieldName]?.message
          : errors[name]?.message;

      isError = true;
      setError(errorMessage);
    }
    if (!isError) {
      clearErrors(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, name]);

  useEffect(() => {
    if (charsLimit) {
      if (countEditorCharacters(editor.children, true) >= charsLimit) {
        setError(name, {
          type: 'custom',
          message: t('general.field.validation.maxCharacters').replace(
            '{0}',
            charsLimit.toString(),
          ),
        });
      }
    }
  }, [charsLimit]);

  const getValueFormat = (HtmlValue: string) => {
    if (HtmlValue && HtmlValue.length) {
      try {
        const json = JSON.parse(HtmlValue);
        editor.children = json;
        return json;
      } catch {
        const temp = [
          {
            type: 'paragraph',
            children: [{ text: HtmlValue }],
          },
        ];
        editor.children = temp;
        return temp;
      }
    }
    editor.children = initialValue;
    return initialValue;
  };

  return (
    <StyledFormElementWrapper style={{ marginBottom: '14px' }}>
      {withLabel && (
        <FormLabel name={name} label={label} disabled={disabled} required={required} />
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Slate
            editor={editor}
            initialValue={getValueFormat(field.value)}
            onChange={(value) => {
              const isAstChange = editor.operations.some((op) => op.type !== 'set_selection');
              if (isAstChange) {
                const content = JSON.stringify(value);
                setValue(name, content);
              }
            }}
          >
            <Grid container item xs={12}>
              <MarkButton disabled={disabled} format="bold" icon="format_bold" />
              <MarkButton disabled={disabled} format="italic" icon="format_italic" />
              <MarkButton disabled={disabled} format="underline" icon="format_underlined" />
              <MarkButton disabled={disabled} format="code" icon="code" />
              <BlockButton disabled={disabled} format="h1" icon="looks_one" />
              <BlockButton disabled={disabled} format="h2" icon="looks_two" />
              <BlockButton disabled={disabled} format="quote" icon="format_quote" />
              <BlockButton
                disabled={disabled}
                format="numbered-list"
                icon="format_list_numbered"
              />
              <BlockButton
                disabled={disabled}
                format="bulleted-list"
                icon="format_list_bulleted"
              />
              <BlockButton disabled={disabled} format="left" icon="format_align_left" />
              <BlockButton disabled={disabled} format="center" icon="format_align_center" />
              <BlockButton disabled={disabled} format="right" icon="format_align_right" />
              <BlockButton disabled={disabled} format="justify" icon="format_align_justify" />
            </Grid>

            <StyledEditable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              readOnly={disabled}
              placeholder={placeholder}
              spellCheck
              autoFocus={autoFocus}
              style={{ overflowWrap: 'anywhere' }}
              // eslint-disable-next-line consistent-return
              onKeyDown={(event: { key: any; preventDefault: () => void }) => {
                if (
                  charsLimit &&
                  event.key !== 'Backspace' &&
                  countEditorCharacters(editor.children, true) >= charsLimit
                ) {
                  setError(name, {
                    message: t('general.field.validation.maxCharacters').replace(
                      '{0}',
                      charsLimit.toString(),
                    ),
                  });
                  return false;
                }
                clearErrors(name);
              }}
            />
          </Slate>
        )}
      />
      {withValidation && errors[name]?.message && (
        <FormErrorLabel label={errors[name]?.message} />
      )}
    </StyledFormElementWrapper>
  );
};

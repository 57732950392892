import React, { ReactElement, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { AuditLogEventName } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { IAuditLogItemResponse } from 'models/auditLog';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import Grid from '@mui/material/Grid';
import { useTranslations } from 'hooks/useTranslations';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { formatDate, getDateFromISO } from 'utils/date';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useUsers } from 'pages/UsersPage/hooks';
import { EAuditLogEvents } from 'pages/AuditLogPage/AuditLogViewPage/index';
import { StyledDivider, StyledViewSectionTitle } from 'pages/ApiKeysPage/styles';
import { apiKeyScopeList, IApiKeyResponse } from 'models/apiKey';
import { format } from 'date-fns';
import { useApiKeys } from 'pages/ApiKeysPage/hooks';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useDictionary } from 'models/dictionaryContext';

interface IApiKeyEventDetails {
  auditLog: IAuditLogItemResponse;
}

const renderScopeName = (scope: string, item: string) => {
  const key = apiKeyScopeList.find((row) => row.value === scope);
  if (key) {
    const itemName = key?.items.find((name) => name.value === `${scope}_${item}`);
    if (itemName) {
      return itemName.label;
    }
  }
  return false;
};

const ApiKeyEventDetails = ({ auditLog }: IApiKeyEventDetails) => {
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();
  const { loadDictionaries } = useUsers();
  const { phoneCode } = useDictionary();
  const { getApiKeyHandler, apiKey } = useApiKeys();
  const { shortDateFormat, longDateFormat, getDateFormat } = useGlobalProperty();
  const [previousApiKey, setPreviousApiKey] = useState<IApiKeyResponse | null>();
  const [previousKeyScope, setPreviousKeyScope] = useState({
    apikeyAncillaryAccessory: [],
    apikeyAncillaryAmmunition: [],
    apikeyAncillaryComponent: [],
    apikeyFirearm: [],
    apikeyLegalEntity: [],
    apikeyTransaction: [],
  });
  const [keyScope, setKeyScope] = useState({
    apikeyAncillaryAccessory: [],
    apikeyAncillaryAmmunition: [],
    apikeyAncillaryComponent: [],
    apikeyFirearm: [],
    apikeyLegalEntity: [],
    apikeyTransaction: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (auditLog?.previousObjectValue) {
      (async () => {
        const previousObjectValue = auditLog?.previousObjectValue as any;
        const previousApiKeyData: IApiKeyResponse = {
          apikeyAncillaryAccessory: previousObjectValue.apikeyAncillaryAccessory || {},
          apikeyAncillaryAmmunition: previousObjectValue.apikeyAncillaryAmmunition || {},
          apikeyAncillaryComponent: previousObjectValue.apikeyAncillaryComponent || {},
          apikeyFirearm: previousObjectValue.apikeyFirearm || {},
          apikeyLegalEntity: previousObjectValue.apikeyLegalEntity || {},
          apikeyTransaction: previousObjectValue.apikeyTransaction || {},
          contactEmail: previousObjectValue.contactEmail || '',
          description: previousObjectValue.description || '',
          expiryDate: previousObjectValue.expiryDate || '',
          firstName: previousObjectValue.firstName || '',
          key: previousObjectValue.key || '',
          lastName: previousObjectValue.lastName || '',
          lastUsedDate: previousObjectValue.lastUsedDate || '',
          phoneNumber: previousObjectValue.phoneNumber || '',
          phonePrefixDictionaryUniqueId:
            previousObjectValue.phonePrefixDictionaryUniqueId || '',
          phonePrefixValue: previousObjectValue.phonePrefixValue || [],
          statusDictionaryUniqueId: previousObjectValue.statusDictionaryUniqueId || '',
          type: previousObjectValue.type || '',
          username: previousObjectValue.username || '',
          uniqueId: '',
        };
        setPreviousApiKey(previousApiKeyData);
        const previousScopeKeys = {
          apikeyAncillaryAccessory: [],
          apikeyAncillaryAmmunition: [],
          apikeyAncillaryComponent: [],
          apikeyFirearm: [],
          apikeyLegalEntity: [],
          apikeyTransaction: [],
        };
        Object.keys(previousScopeKeys).map((key) => {
          // @ts-ignore
          const apiKeyItem = previousObjectValue?.[key];

          if (apiKeyItem) {
            Object.keys(apiKeyItem).map((item) => {
              if (apiKeyItem[item] === true) {
                // @ts-ignore
                previousScopeKeys[key].push(renderScopeName(key, item));
              }
              return false;
            });
          }
          return false;
        });
        setPreviousKeyScope(previousScopeKeys);
      })();
    }

    if (auditLog?.objectUniqueId && hasPermissions([EPermission.MANAGE_API_KEYS])) {
      (async () => {
        if (auditLog.objectUniqueId) {
          await getApiKeyHandler(auditLog.objectUniqueId);
        }
      })();
    }
  }, [auditLog]);

  useEffect(() => {
    if (apiKey) {
      const scopeKeys = {
        apikeyAncillaryAccessory: [],
        apikeyAncillaryAmmunition: [],
        apikeyAncillaryComponent: [],
        apikeyFirearm: [],
        apikeyLegalEntity: [],
        apikeyTransaction: [],
      };

      Object.keys(scopeKeys).map((key) => {
        // @ts-ignore
        const apiKeyItem = apiKey?.[key];

        if (apiKeyItem) {
          Object.keys(apiKeyItem).map((item) => {
            if (apiKeyItem[item] === true) {
              // @ts-ignore
              scopeKeys[key].push(renderScopeName(key, item));
            }
            return false;
          });
        }
        return false;
      });

      setKeyScope(scopeKeys);
    }
  }, [apiKey]);

  useEffect(() => {
    getDateFormat();
    loadDictionaries();
  }, []);

  const renderExpiryDate = () => {
    if (!apiKey) {
      return null;
    }

    const date = getDateFromISO(apiKey.expiryDate);
    if (date === '') {
      return (
        <Chips
          text={t('deleteApiKey.state.perpetual.label')}
          variant={EChipsVariants.success100}
        />
      );
    }
    if (date > new Date()) {
      return format(date, shortDateFormat);
    }
    return (
      <Chips text={t('deleteApiKey.state.expired.label')} variant={EChipsVariants.error} />
    );
  };

  const renderContactPhone = (phonePrefix: string, phoneNumber: string) => {
    if (!apiKey) {
      return null;
    }
    return `00${phonePrefix} ${phoneNumber}`;
  };

  const renderKeyScope = (customKeyScope: {
    apikeyAncillaryAccessory: never[];
    apikeyAncillaryAmmunition: never[];
    apikeyAncillaryComponent: never[];
    apikeyFirearm: never[];
    apikeyLegalEntity: never[];
    apikeyTransaction: never[];
  }) => {
    const renderItem: ReactElement[] = [];

    Object.keys(customKeyScope).map((key) => {
      // @ts-ignore
      const scope = customKeyScope?.[key];
      if (scope?.length) {
        const title = apiKeyScopeList.find((item) => item.value === key)?.label;

        renderItem.push(
          <>
            <Grid item xs={12} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '10px' }}>
                {title ? t(title) : '-'}
              </Typography>
            </Grid>
            {scope.map((item: string) => (
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ paddingTop: '0px' }}>
                <Typography variant="body2">{t(item)}</Typography>
              </Grid>
            ))}
          </>,
        );
      }
      return false;
    });

    return renderItem;
  };

  return (
    <>
      {previousApiKey && (
        <>
          <AuditLogEventName>
            <Typography variant="h5">
              {auditLog?.eventNameUniqueId !== EAuditLogEvents.ADD_API_KEY
                ? `${t('viewApiKey.header')} ${t('auditLogDetails.before.event.title')}`
                : `${t('viewApiKey.header')} ${t('auditLogDetails.after.event.title')}`}
            </Typography>
          </AuditLogEventName>
          <DetailsTemplate
            title={previousApiKey?.key}
            pageType={EDetailsPageTypes.auditLogView}
            redirectToView={() =>
              navigate(
                `${ERouteLinks.ViewApiKeyPage.replace(':id', previousApiKey?.uniqueId)}`,
              )
            }
            disableActions={!previousApiKey.uniqueId}
          >
            <Grid container item xs={12} lg={12} rowGap="18px">
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.key.label')}
                </Typography>
                <Typography variant="body2">
                  <Chips text={previousApiKey.type} variant={EChipsVariants.primary} />
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.user.label')}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(previousApiKey?.username)}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.expiryDate.label')}
                </Typography>
                <Typography variant="body2">{renderExpiryDate()}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.lastUsed.label')}
                </Typography>
                <Typography variant="body2">
                  {previousApiKey?.lastUsedDate
                    ? formatDate(previousApiKey.lastUsedDate, longDateFormat)
                    : '-'}
                </Typography>
              </Grid>

              {previousApiKey?.description && (
                <Grid item xs={12} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('viewApiKey.description.label')}
                  </Typography>
                  <Typography variant="body2">
                    {emptyFieldHolder(previousApiKey?.description)}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <StyledDivider />

            <StyledViewSectionTitle variant="subtitle1">
              {t('viewApiKey.administrator.title')}
            </StyledViewSectionTitle>

            <Grid container item xs={12} lg={12} rowGap="18px">
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.contactEmail.label')}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(previousApiKey?.contactEmail)}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.contactName.label')}
                </Typography>
                <Typography variant="body2">{`${previousApiKey?.firstName} ${previousApiKey.lastName}`}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.contactPhone.label')}
                </Typography>
                {phoneCode && phoneCode.length > 0 && (
                  <Typography variant="body2">
                    {renderContactPhone(
                      phoneCode?.find(
                        (it) => it.uniqueId === previousApiKey.phonePrefixDictionaryUniqueId,
                      )?.name || '',
                      previousApiKey.phoneNumber,
                    )}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <StyledDivider />

            <StyledViewSectionTitle variant="subtitle1">
              {t('viewApiKey.scope.title')}
            </StyledViewSectionTitle>

            {renderKeyScope(previousKeyScope)}
          </DetailsTemplate>
        </>
      )}

      {auditLog && auditLog?.eventNameUniqueId !== EAuditLogEvents.DELETE_API_KEY && (
        <AuditLogEventName sx={{ marginTop: '20px' }}>
          <Typography variant="h5">
            {`${t('viewApiKey.header')} ${t('auditLogDetails.current.data.event.title')}`}
          </Typography>
        </AuditLogEventName>
      )}

      {apiKey && (
        <DetailsTemplate
          title={apiKey?.key}
          pageType={EDetailsPageTypes.auditLogView}
          redirectToView={() =>
            navigate(`${ERouteLinks.ViewApiKeyPage.replace(':id', apiKey?.uniqueId)}`)
          }
          disableActions={!apiKey?.uniqueId}
        >
          <Grid container item xs={12} lg={12} rowGap="18px">
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('viewApiKey.key.label')}
              </Typography>
              <Typography variant="body2">
                <Chips text={apiKey.type} variant={EChipsVariants.primary} />
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('viewApiKey.user.label')}
              </Typography>
              <Typography variant="body2">{emptyFieldHolder(apiKey?.username)}</Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('viewApiKey.expiryDate.label')}
              </Typography>
              <Typography variant="body2">{renderExpiryDate()}</Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('viewApiKey.lastUsed.label')}
              </Typography>
              <Typography variant="body2">
                {apiKey?.lastUsedDate ? formatDate(apiKey.lastUsedDate, longDateFormat) : '-'}
              </Typography>
            </Grid>

            {apiKey?.description && (
              <Grid item xs={12} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.description.label')}
                </Typography>
                <Typography variant="body2">
                  {emptyFieldHolder(apiKey?.description)}
                </Typography>
              </Grid>
            )}
          </Grid>

          <StyledDivider />

          <StyledViewSectionTitle variant="subtitle1">
            {t('viewApiKey.administrator.title')}
          </StyledViewSectionTitle>

          <Grid container item xs={12} lg={12} rowGap="18px">
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('viewApiKey.contactEmail.label')}
              </Typography>
              <Typography variant="body2">{emptyFieldHolder(apiKey?.contactEmail)}</Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('viewApiKey.contactName.label')}
              </Typography>
              <Typography variant="body2">{`${apiKey?.firstName} ${apiKey.lastName}`}</Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('viewApiKey.contactPhone.label')}
              </Typography>
              <Typography variant="body2">
                {renderContactPhone(apiKey?.phonePrefixValue?.[0]?.name, apiKey?.phoneNumber)}
              </Typography>
            </Grid>
          </Grid>

          <StyledDivider />

          <StyledViewSectionTitle variant="subtitle1">
            {t('viewApiKey.scope.title')}
          </StyledViewSectionTitle>

          {renderKeyScope(keyScope)}
        </DetailsTemplate>
      )}
    </>
  );
};

export default ApiKeyEventDetails;

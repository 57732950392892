import styled from '@emotion/styled';
import { Editable } from 'slate-react';
import { colors } from 'theme/colors';
import Icon from '@mui/material/Icon';

export const StyledEditable = styled(Editable)((props: any) => ({
  borderRadius: 4,
  borderColor: colors.gray300,
  borderWidth: 1,
  borderStyle: 'solid',
  padding: '13px 8px',
  minHeight: '120px !important',
  ...(props.readOnly ? { backgroundColor: colors.gray100 } : {}),
  '&:focus': {
    outline: '2px solid #1976d2',
  },
  p: {
    margin: '1px 0',
    fontSize: 14,
    boxSizing: 'border-box',
    ...(props.readOnly ? { color: colors.gray300 } : {}),
  },
  li: {
    margin: '1px 0',
    listStyle: 'inside',
  },
  ol: {
    padding: 0,
    margin: 0,
    '& li': {
      listStyle: 'decimal',
      listStylePosition: 'inside',
    },
  },
}));

export const IconStyle = styled(Icon)(() => ({
  fontSize: '22px',
  verticalAlign: 'text-bottom',
}));

export const ButtonToolboxStyle = styled.span((props: any) => ({
  cursor: 'pointer',
  padding: '2px',
  margin: '2px',

  // eslint-disable-next-line no-nested-ternary
  color: props.reversed
    ? props.active
      ? colors.gray300
      : colors.gray200
    : props.active
    ? colors.black
    : colors.gray300,
}));

import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';
import { getFieldsByAccessoriesType } from 'pages/AncillariesPage/helpers';
import { countEditorCharacters } from 'components/organisms/HtmlSlate';

const useAccessoriesFieldsFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    accessoryTypeUniqueId: yup.string().required(t('general.field.validation.required')),
    manufacturerUniqueId: yup.string().test('manufacturerUniqueId', '', (val, obj: any) => {
      const fields = getFieldsByAccessoriesType(obj.parent.accessoryTypeUniqueId);
      if (fields.includes(obj.path) && !val) {
        return new yup.ValidationError(t('general.field.validation.required'), null, obj.path);
      }
      return true;
    }),
    productName: yup
      .string()
      .max(80, t('general.field.validation.maxCharacters').replace('{0}', '80')),
    hostTypeUniqueId: yup.string().test('hostTypeUniqueId', '', (val, obj: any) => {
      const fields = getFieldsByAccessoriesType(obj.parent.accessoryTypeUniqueId);
      if (fields.includes(obj.path) && !val) {
        return new yup.ValidationError(t('general.field.validation.required'), null, obj.path);
      }
      return true;
    }),
    calibreUniqueId: yup.string().test('calibreUniqueId', '', (val, obj: any) => {
      const fields = getFieldsByAccessoriesType(obj.parent.accessoryTypeUniqueId);
      if (fields.includes(obj.path) && !val) {
        return new yup.ValidationError(t('general.field.validation.required'), null, obj.path);
      }
      return true;
    }),
    magnificationUniqueId: yup.string().test('magnificationUniqueId', '', (val, obj: any) => {
      const fields = getFieldsByAccessoriesType(obj.parent.accessoryTypeUniqueId);
      if (fields.includes(obj.path) && !val) {
        return new yup.ValidationError(t('general.field.validation.required'), null, obj.path);
      }
      return true;
    }),
    utilityUniqueId: yup.string().test('utilityUniqueId', '', (val, obj: any) => {
      const fields = getFieldsByAccessoriesType(obj.parent.accessoryTypeUniqueId);
      if (fields.includes(obj.path) && !val) {
        return new yup.ValidationError(t('general.field.validation.required'), null, obj.path);
      }
      return true;
    }),
    rectileUniqueId: yup.string().test('rectileUniqueId', '', (val, obj: any) => {
      const fields = getFieldsByAccessoriesType(obj.parent.accessoryTypeUniqueId);
      if (fields.includes(obj.path) && !val) {
        return new yup.ValidationError(t('general.field.validation.required'), null, obj.path);
      }
      return true;
    }),
    lightColourUniqueId: yup.string().test('lightColourUniqueId', '', (val, obj: any) => {
      const fields = getFieldsByAccessoriesType(obj.parent.accessoryTypeUniqueId);
      if (fields.includes(obj.path) && !val) {
        return new yup.ValidationError(t('general.field.validation.required'), null, obj.path);
      }
      return true;
    }),
    compositionUniqueId: yup.string().test('compositionUniqueId', '', (val, obj: any) => {
      const fields = getFieldsByAccessoriesType(obj.parent.accessoryTypeUniqueId);
      if (fields.includes(obj.path) && !val) {
        return new yup.ValidationError(t('general.field.validation.required'), null, obj.path);
      }
      return true;
    }),
    proofHouseUniqueId: yup.string(),
    capacity: yup
      .string()
      .max(256, t('general.field.validation.maxCharacters').replace('{0}', '256'))
      .trim()
      .test('capacity', '', (val, obj: any) => {
        const fields = getFieldsByAccessoriesType(obj.parent.accessoryTypeUniqueId);
        if (fields.includes(obj.path) && !val) {
          return new yup.ValidationError(
            t('general.field.validation.required'),
            null,
            obj.path,
          );
        }
        return true;
      }),
    notes: yup
      .string()
      .test(
        'editor-validation',
        t('general.field.validation.maxCharacters').replace('{0}', '5000'),
        (value) => countEditorCharacters(value) <= 5000,
      ),
  });

  return { schema };
};
export default useAccessoriesFieldsFormSchema;

import React, { useEffect, useState } from 'react';
import { IAuditLogItemResponse } from 'models/auditLog';
import { AuditLogEventName } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { Box, Typography } from '@mui/material';
import { getLegalEntity, getOrgLegalEntity } from 'requests/legalEntities';
import {
  ILegalEntityCustomField,
  ILegalEntityData,
} from 'components/organisms/LegalEntityDetails';
import { IOrgLegalEntityData } from 'components/organisms/OrgLegalEntityDetails';
import { ERouteLinks } from 'models/route';
import Grid from '@mui/material/Grid';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { useNavigate } from 'react-router-dom';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { useTranslations } from 'hooks/useTranslations';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useLang } from 'models/langContext';
import { EDictionaryPrefixTypesUniqueId } from 'helpers/dictionary';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { ECustomFieldType } from 'models/customField';
import {
  formatDate,
  formatPreviousAuditLogObjectDateFieldToDisplay,
  simpleFormatDate,
} from 'utils/date';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { EAuditLogEvents } from 'pages/AuditLogPage/AuditLogViewPage/index';

export enum ELEObjectType {
  LE_INDIVIDUAL = 'LE_INDIVIDUAL',
  LE_ORGANIZATION = 'LE_ORGANIZATION',
}

interface ILegalEntitiesEventDetails {
  auditLog: IAuditLogItemResponse;
}

const LegalEntitiesEventDetails = ({ auditLog }: ILegalEntitiesEventDetails) => {
  const [legalEntity, setLegalEntity] = useState<ILegalEntityData | null>();
  const [previousLegalEntity, setPreviousLegalEntity] = useState<ILegalEntityData | null>();
  const [orgLegalEntity, setOrgLegalEntity] = useState<IOrgLegalEntityData | null>();
  const [previousOrgLegalEntity, setPreviousOrgLegalEntity] =
    useState<IOrgLegalEntityData | null>();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { selectedLanguage, defaultLanguage } = useLang();
  const { hasPermissions } = usePermission();
  const { shortDateFormat, getDateFormat } = useGlobalProperty();

  const {
    loadDictionaries,
    gender,
    legalEntityPrefix,
    civilianPrefix,
    militaryPrefix,
    policePrefix,
    legalVisibility,
    organizationType,
  } = useLegalEntities();

  useEffect(() => {
    getDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dictionariesList =
    auditLog?.objectType === ELEObjectType.LE_INDIVIDUAL
      ? [
          EDictionaryTypes.Gender,
          EDictionaryTypes.LegalVisibility,
          EDictionaryTypes.legalEntityPrefix,
          EDictionaryTypes.civilianPrefix,
          EDictionaryTypes.militaryPrefix,
          EDictionaryTypes.policePrefix,
        ]
      : [EDictionaryTypes.LegalVisibility, EDictionaryTypes.OrganizationType];

  useEffect(() => {
    (async function init() {
      await loadDictionaries(dictionariesList);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const renderPreviousPrefixDetail = (
    prefixGroupUniqueId: string | undefined,
    prefixValueUniqueId: string | undefined,
  ) => {
    switch (prefixGroupUniqueId) {
      case EDictionaryPrefixTypesUniqueId.civilianPrefix:
        return (
          civilianPrefix?.find((item: IDictionary) => item.uniqueId === prefixValueUniqueId)
            ?.name || ''
        );
      case EDictionaryPrefixTypesUniqueId.militaryPrefix:
        return (
          militaryPrefix?.find((item: IDictionary) => item.uniqueId === prefixValueUniqueId)
            ?.name || ''
        );
      case EDictionaryPrefixTypesUniqueId.policePrefix:
        return (
          policePrefix?.find((item: IDictionary) => item.uniqueId === prefixValueUniqueId)
            ?.name || ''
        );
      default:
        return '';
    }
  };

  useEffect(() => {
    const fetchLegalEntity = async (): Promise<void> => {
      if (auditLog?.previousObjectValue) {
        const previousObjectValue = auditLog.previousObjectValue as any;
        const formattedDate = formatPreviousAuditLogObjectDateFieldToDisplay(
          previousObjectValue.dateOfBirth,
        );
        const leIndividualData: ILegalEntityData = {
          city: previousObjectValue.city || '',
          dateOfBirth: simpleFormatDate(formattedDate, shortDateFormat),
          firstName: previousObjectValue.firstName || '',
          hasAssignedUser: previousObjectValue.hasAssignedUser || false,
          lastName: previousObjectValue.lastName || '',
          uniqueId: '',
          genderUniqueId:
            gender?.find(
              (item: IDictionary) => item.uniqueId === previousObjectValue.genderUniqueId,
            )?.name || '',
          prefixGroupUniqueId:
            legalEntityPrefix?.find(
              (item: IDictionary) => item.uniqueId === previousObjectValue.prefixGroupUniqueId,
            )?.name || '',
          prefixValueUniqueId: renderPreviousPrefixDetail(
            previousObjectValue.prefixGroupUniqueId,
            previousObjectValue.prefixValueUniqueId,
          ),
          version: previousObjectValue.version || '',
          visibilityUniqueId:
            legalVisibility?.find(
              (item: IDictionary) => item.uniqueId === previousObjectValue.visibilityUniqueId,
            )?.name || '',
          customFields: previousObjectValue.customFields || [],
        };
        setPreviousLegalEntity(leIndividualData);
      }

      if (auditLog?.objectUniqueId && hasPermissions([EPermission.VIEW_ALL_LE])) {
        try {
          const response = await getLegalEntity(auditLog?.objectUniqueId);
          if (response) {
            const formattedDate = formatDate(response?.dateOfBirth, shortDateFormat);
            const updatedResponse = {
              ...response,
              dateOfBirth: formattedDate,
            };
            setLegalEntity(updatedResponse);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    const fetchOrgLegalEntity = async (): Promise<void> => {
      if (auditLog?.previousObjectValue) {
        const previousObjectValue = auditLog.previousObjectValue as any;
        const leOrgData: IOrgLegalEntityData = {
          city: previousObjectValue.city || '',
          customFields: previousObjectValue.customFields || [],
          departmentName: previousObjectValue.departmentName || '',
          hasAssignedUser: previousObjectValue.hasAssignedUser || '',
          organizationTypeUniqueId:
            organizationType?.find(
              (item: IDictionary) =>
                item.uniqueId === previousObjectValue.organizationTypeUniqueId,
            )?.name || '',
          uniqueId: '',
          version: previousObjectValue.version || '',
          visibilityUniqueId:
            legalVisibility?.find(
              (item: IDictionary) => item.uniqueId === previousObjectValue.visibilityUniqueId,
            )?.name || '',
          organizationName: previousObjectValue.organizationName || '',
        };
        setPreviousOrgLegalEntity(leOrgData);
      }

      if (auditLog?.objectUniqueId && hasPermissions([EPermission.VIEW_ALL_LE])) {
        try {
          const response = await getOrgLegalEntity(auditLog?.objectUniqueId);
          if (response) {
            setOrgLegalEntity(response);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (auditLog?.objectType === ELEObjectType.LE_ORGANIZATION) {
      fetchOrgLegalEntity();
    } else if (auditLog?.objectType === ELEObjectType.LE_INDIVIDUAL) {
      fetchLegalEntity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auditLog,
    gender,
    legalVisibility,
    legalEntityPrefix,
    civilianPrefix,
    militaryPrefix,
    policePrefix,
    organizationType,
  ]);

  const renderItemDetail = (dictionary: IDictionary[] | null, dictionaryItemId: string) => {
    const legal: ILegalEntityData = legalEntity as ILegalEntityData;
    const orgLegal: IOrgLegalEntityData = orgLegalEntity as IOrgLegalEntityData;
    if (legal) {
      return dictionary?.find(
        (item: IDictionary) =>
          item?.uniqueId === legal[dictionaryItemId as keyof ILegalEntityData],
      )?.name;
    }
    if (orgLegal) {
      return dictionary?.find(
        (item: IDictionary) =>
          item?.uniqueId === orgLegal[dictionaryItemId as keyof IOrgLegalEntityData],
      )?.name;
    }
    return '';
  };

  const renderPrefixDetail = (prefixGroupUniqueId: string | undefined) => {
    switch (prefixGroupUniqueId) {
      case EDictionaryPrefixTypesUniqueId.civilianPrefix:
        return renderItemDetail(civilianPrefix, 'prefixValueUniqueId');
      case EDictionaryPrefixTypesUniqueId.militaryPrefix:
        return renderItemDetail(militaryPrefix, 'prefixValueUniqueId');
      case EDictionaryPrefixTypesUniqueId.policePrefix:
        return renderItemDetail(policePrefix, 'prefixValueUniqueId');
      default:
        return '';
    }
  };

  const renderCustomFieldDataForAuditLogPreviousObject = (
    customField: ILegalEntityCustomField,
  ) => {
    if (customField.customFieldType === ECustomFieldType.BOOL) {
      if (customField.value === null) {
        return '-';
      }
      return customField.value
        ? t('customFields.value.yes.label')
        : t('customFields.value.no.label');
    }
    if (customField.customFieldType === ECustomFieldType.DATE) {
      const formattedDate = formatPreviousAuditLogObjectDateFieldToDisplay(customField.value);
      return simpleFormatDate(formattedDate, shortDateFormat);
    }
    return customField.value;
  };

  const renderCustomFieldData = (customField: ILegalEntityCustomField) => {
    if (customField.customFieldType === ECustomFieldType.BOOL) {
      if (customField.value === null) {
        return '-';
      }
      return customField.value
        ? t('customFields.value.yes.label')
        : t('customFields.value.no.label');
    }
    if (customField.customFieldType === ECustomFieldType.DATE) {
      return formatDate(customField.value, shortDateFormat);
    }
    return customField.value;
  };

  return (
    <>
      {previousLegalEntity && (
        <>
          <AuditLogEventName>
            <Typography variant="h5">
              {auditLog?.eventNameUniqueId !== EAuditLogEvents.ADD_LE_INDIVIDUAL
                ? `${t('addAncillary.legalEntityDetails.breadcrumb')} ${t(
                    'auditLogDetails.before.event.title',
                  )}`
                : `${t('addAncillary.legalEntityDetails.breadcrumb')} ${t(
                    'auditLogDetails.after.event.title',
                  )}`}
            </Typography>
          </AuditLogEventName>
          <DetailsTemplate
            title={`${previousLegalEntity.firstName} ${previousLegalEntity.lastName}`}
            pageType={EDetailsPageTypes.auditLogView}
            disableActions
          >
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.legalEntityType.label')}
              </Typography>
              <Box>
                <Chips
                  text={
                    auditLog?.objectType === ELEObjectType.LE_INDIVIDUAL
                      ? t('legalEntityDetails.legalEntityType.individual.chip.label')
                      : t('legalEntityDetails.legalEntityType.organisation.chip.label')
                  }
                  variant={EChipsVariants.primary}
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntities.individual.accordion.legalEntityTable.prefixGroup.column')}
              </Typography>
              <Typography variant="body2">
                <Chips
                  text={previousLegalEntity.prefixGroupUniqueId}
                  variant={EChipsVariants.primary}
                />
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntities.individual.accordion.legalEntityTable.prefixValue.column')}
              </Typography>
              <Typography variant="body2">
                {previousLegalEntity.prefixValueUniqueId}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.dateOfBirth.label')}
              </Typography>
              <Typography variant="body2">{previousLegalEntity.dateOfBirth}</Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.visibility.label')}
              </Typography>
              <Box>
                <Chips
                  text={previousLegalEntity.visibilityUniqueId}
                  variant={EChipsVariants.success}
                />
              </Box>
            </Grid>

            <Grid item xs={6} lg={3} container direction="column">
              <Grid>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('legalEntityDetails.gender.label')}
                </Typography>
              </Grid>
              <Typography variant="body2">{previousLegalEntity.genderUniqueId}</Typography>
            </Grid>
            {previousLegalEntity?.customFields?.map((customField) => (
              <Grid
                item
                xs={6}
                lg={3}
                container
                direction="column"
                key={customField.customFieldUniqueId}
              >
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {customField?.translations?.filter(
                    (translation) =>
                      selectedLanguage?.uniqueId === translation.languageUniqueId,
                  )?.[0]?.name ||
                    customField?.translations?.filter(
                      (translation) =>
                        defaultLanguage?.uniqueId === translation.languageUniqueId,
                    )?.[0]?.name}
                </Typography>
                <Typography variant="body2">
                  {renderCustomFieldDataForAuditLogPreviousObject(customField)}
                </Typography>
              </Grid>
            ))}
          </DetailsTemplate>
        </>
      )}

      {previousLegalEntity &&
        auditLog?.eventNameUniqueId !== EAuditLogEvents.DELETE_LE_INDIVIDUAL && (
          <AuditLogEventName sx={{ marginTop: '20px' }}>
            <Typography variant="h5">
              {`${t('addAncillary.legalEntityDetails.breadcrumb')} ${t(
                'auditLogDetails.current.data.event.title',
              )}`}
            </Typography>
          </AuditLogEventName>
        )}

      {legalEntity && (
        <DetailsTemplate
          title={`${legalEntity.firstName} ${legalEntity.lastName}`}
          pageType={EDetailsPageTypes.auditLogView}
          redirectToView={() =>
            navigate(
              `${ERouteLinks.ViewLegalEntity.replace(
                ':id',
                legalEntity.uniqueId,
              )}?type=individual`,
            )
          }
          disableActions={!legalEntity.uniqueId}
        >
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.legalEntityType.label')}
            </Typography>
            <Box>
              <Chips
                text={
                  auditLog?.objectType === ELEObjectType.LE_INDIVIDUAL
                    ? t('legalEntityDetails.legalEntityType.individual.chip.label')
                    : t('legalEntityDetails.legalEntityType.organisation.chip.label')
                }
                variant={EChipsVariants.primary}
              />
            </Box>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntities.individual.accordion.legalEntityTable.prefixGroup.column')}
            </Typography>
            <Typography variant="body2">
              <Chips
                text={renderItemDetail(legalEntityPrefix, 'prefixGroupUniqueId')}
                variant={EChipsVariants.primary}
              />
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntities.individual.accordion.legalEntityTable.prefixValue.column')}
            </Typography>
            <Typography variant="body2">
              {renderPrefixDetail(legalEntity.prefixGroupUniqueId)}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.dateOfBirth.label')}
            </Typography>
            <Typography variant="body2">{legalEntity.dateOfBirth}</Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.visibility.label')}
            </Typography>
            <Box>
              <Chips
                text={renderItemDetail(legalVisibility, 'visibilityUniqueId')}
                variant={EChipsVariants.success}
              />
            </Box>
          </Grid>

          <Grid item xs={6} lg={3} container direction="column">
            <Grid>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.gender.label')}
              </Typography>
            </Grid>
            <Typography variant="body2">
              {renderItemDetail(gender, 'genderUniqueId')}
            </Typography>
          </Grid>
          {legalEntity?.customFields?.map((customField) => (
            <Grid
              item
              xs={6}
              lg={3}
              container
              direction="column"
              key={customField.customFieldUniqueId}
            >
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {customField?.translations?.filter(
                  (translation) => selectedLanguage?.uniqueId === translation.languageUniqueId,
                )?.[0]?.name ||
                  customField?.translations?.filter(
                    (translation) =>
                      defaultLanguage?.uniqueId === translation.languageUniqueId,
                  )?.[0]?.name}
              </Typography>
              <Typography variant="body2">{renderCustomFieldData(customField)}</Typography>
            </Grid>
          ))}
        </DetailsTemplate>
      )}

      {previousOrgLegalEntity && (
        <>
          <AuditLogEventName>
            <Typography variant="h5">
              {auditLog?.eventNameUniqueId !== EAuditLogEvents.ADD_LE_ORGANIZATION
                ? `${t('addAncillary.legalEntityDetails.breadcrumb')} ${t(
                    'auditLogDetails.before.event.title',
                  )}`
                : `${t('addAncillary.legalEntityDetails.breadcrumb')} ${t(
                    'auditLogDetails.after.event.title',
                  )}`}
            </Typography>
          </AuditLogEventName>
          <DetailsTemplate
            title={`${previousOrgLegalEntity.organizationName}`}
            pageType={EDetailsPageTypes.auditLogView}
            disableActions
          >
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.legalEntityType.label')}
              </Typography>
              <Box>
                <Chips
                  text={
                    auditLog?.objectType === ELEObjectType.LE_INDIVIDUAL
                      ? t('legalEntityDetails.legalEntityType.individual.chip.label')
                      : t('legalEntityDetails.legalEntityType.organisation.chip.label')
                  }
                  variant={EChipsVariants.primary}
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.name.label')}
              </Typography>
              <Typography variant="body2">
                {previousOrgLegalEntity.organizationName}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Grid>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('legalEntityDetails.organizationType.label')}
                </Typography>
              </Grid>
              <Typography variant="body2">
                {previousOrgLegalEntity.organizationTypeUniqueId}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Grid>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('legalEntityDetails.departmentName.label')}
                </Typography>
              </Grid>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {previousOrgLegalEntity.departmentName}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteLegalEntity.visibility.label')}
              </Typography>
              <Box>
                <Chips
                  text={previousOrgLegalEntity.visibilityUniqueId}
                  variant={EChipsVariants.success}
                />
              </Box>
            </Grid>
            {previousOrgLegalEntity?.customFields?.map((customField) => (
              <Grid
                item
                xs={6}
                lg={3}
                container
                direction="column"
                key={customField.customFieldUniqueId}
              >
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {customField?.translations?.filter(
                    (translation) =>
                      selectedLanguage?.uniqueId === translation.languageUniqueId,
                  )?.[0]?.name ||
                    customField?.translations?.filter(
                      (translation) =>
                        defaultLanguage?.uniqueId === translation.languageUniqueId,
                    )?.[0]?.name}
                </Typography>
                <Typography variant="body2">{renderCustomFieldData(customField)}</Typography>
              </Grid>
            ))}
          </DetailsTemplate>
        </>
      )}

      {previousOrgLegalEntity &&
        auditLog?.eventNameUniqueId !== EAuditLogEvents.DELETE_LE_ORGANIZATION && (
          <AuditLogEventName sx={{ marginTop: '20px' }}>
            <Typography variant="h5">
              {`${t('addAncillary.legalEntityDetails.breadcrumb')} ${t(
                'auditLogDetails.current.data.event.title',
              )}`}
            </Typography>
          </AuditLogEventName>
        )}

      {orgLegalEntity && (
        <DetailsTemplate
          title={`${orgLegalEntity.organizationName}`}
          pageType={EDetailsPageTypes.auditLogView}
          redirectToView={() =>
            navigate(
              `${ERouteLinks.ViewLegalEntity.replace(
                ':id',
                orgLegalEntity.uniqueId,
              )}?type=organisation`,
            )
          }
          disableActions={!orgLegalEntity.uniqueId}
        >
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.legalEntityType.label')}
            </Typography>
            <Box>
              <Chips
                text={
                  auditLog?.objectType === ELEObjectType.LE_INDIVIDUAL
                    ? t('legalEntityDetails.legalEntityType.individual.chip.label')
                    : t('legalEntityDetails.legalEntityType.organisation.chip.label')
                }
                variant={EChipsVariants.primary}
              />
            </Box>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.name.label')}
            </Typography>
            <Typography variant="body2">{orgLegalEntity.organizationName}</Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Grid>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.organizationType.label')}
              </Typography>
            </Grid>
            <Typography variant="body2">
              {renderItemDetail(organizationType, 'organizationTypeUniqueId')}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Grid>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.departmentName.label')}
              </Typography>
            </Grid>
            <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
              {orgLegalEntity.departmentName}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('deleteLegalEntity.visibility.label')}
            </Typography>
            <Box>
              <Chips
                text={renderItemDetail(legalVisibility, 'visibilityUniqueId')}
                variant={EChipsVariants.success}
              />
            </Box>
          </Grid>
          {orgLegalEntity?.customFields?.map((customField) => (
            <Grid
              item
              xs={6}
              lg={3}
              container
              direction="column"
              key={customField.customFieldUniqueId}
            >
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {customField?.translations?.filter(
                  (translation) => selectedLanguage?.uniqueId === translation.languageUniqueId,
                )?.[0]?.name ||
                  customField?.translations?.filter(
                    (translation) =>
                      defaultLanguage?.uniqueId === translation.languageUniqueId,
                  )?.[0]?.name}
              </Typography>
              <Typography variant="body2">{renderCustomFieldData(customField)}</Typography>
            </Grid>
          ))}
        </DetailsTemplate>
      )}
    </>
  );
};

export default LegalEntitiesEventDetails;

import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { useForm } from 'react-hook-form';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { FormInput } from 'components/molecules/FormInput';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import {
  mapDictionaryToOptionProp,
  mapSpecificDictionaryToOptionProp,
} from 'helpers/dictionary';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { useLocation } from 'react-router-dom';
import { prepareFilters } from 'helpers/filters';
import { useTranslations } from 'hooks/useTranslations';
import { FormAutocomplete } from 'components/molecules/FormAutocomplete';
import { useLang } from 'models/langContext';
import { EDictionaryTypes } from 'models/dictionary';
import { EPerPages } from 'models/table';

export interface ISearchFormValues {
  make: string;
  model: string;
  manufacturer: string;
  caliber: string;
  productCode: string;
  action: string;
  country: string;
  type: string;
  isLocal: number;
  level: string;
  legalClassification: string;
  invokedByPageChange?: boolean;
}

export enum ESearchFormLabels {
  make = 'firearmsReferenceTable.accordion.make.label',
  model = 'firearmsReferenceTable.accordion.model.label',
  manufacturer = 'firearmsReferenceTable.accordion.manufacturer.label',
  caliber = 'firearmsReferenceTable.accordion.calibre.label',
  productCode = 'firearmsReferenceTable.accordion.alsoKnownAsProductCode.label',
  action = 'firearmsReferenceTable.accordion.firearmAction.label',
  country = 'firearmsReferenceTable.accordion.country.label',
  type = 'firearmsReferenceTable.accordion.type.label',
  isLocal = 'firearmsReferenceTable.accordion.dataSource.label',
  level = 'firearmsReferenceTable.accordion.level.label',
  legalClassification = 'firearmsReferenceTable.accordion.legalClassification.label',
}

export const initialForm: ISearchFormValues = {
  make: '',
  model: '',
  manufacturer: '',
  caliber: '',
  productCode: '',
  action: '',
  country: '',
  type: '',
  isLocal: 0,
  level: '',
  legalClassification: '',
};

interface IFRTSearchForm {
  onSubmit: (arg: any) => void;
  initialFormValues?: ISearchFormValues;
  showSaveMyFilter?: boolean;
  isSearchButtonDisabled?: boolean;
  isTransactionContext?: boolean;
  isSearchForFRTContext?: boolean;
  currentPage?: number;
  perPage?: EPerPages;
}

const FRTSearchForm = ({
  onSubmit,
  initialFormValues = initialForm,
  showSaveMyFilter = true,
  isSearchButtonDisabled = false,
  isTransactionContext = false,
  isSearchForFRTContext = false,
  perPage,
  currentPage,
}: IFRTSearchForm) => {
  const [values, setValues] = useState<ISearchFormValues>(initialFormValues);
  const { state: routerState }: any = useLocation();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const [isInitialFormLoad, setIsInitialFormLoad] = useState(true);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ISearchFormValues>({
    defaultValues: initialFormValues,
  });

  const dataSourceOptions = [
    {
      uniqueId: 0,
      type: 'data_source',
      name: t('frtTable.dataSource.frt.label'),
      isActive: true,
    },
    {
      uniqueId: 1,
      type: 'data_source',
      name: t('frtTable.dataSource.local.label'),
      isActive: true,
    },
  ];

  useEffect(() => {
    if (!isInitialFormLoad) {
      handleSubmit((val) => onSubmit({ ...val, invokedByPageChange: true }))();
    } else {
      setIsInitialFormLoad(false);
    }
  }, [perPage, currentPage]);

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchFormValues);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const {
    make,
    model,
    caliber,
    manufacturer,
    firearmAction,
    firearmType,
    level,
    manufacturerCountry,
    loadDictionaries,
  } = useLegalEntities();

  useEffect(() => {
    (async function initDictionaries() {
      await Promise.all([
        loadDictionaries([
          EDictionaryTypes.Make,
          EDictionaryTypes.Model,
          EDictionaryTypes.Manufacturer,
          EDictionaryTypes.ManufacturerCountry,
          EDictionaryTypes.FirearmAction,
          EDictionaryTypes.FirearmType,
          EDictionaryTypes.Legality,
          EDictionaryTypes.Level,
          EDictionaryTypes.Caliber,
          EDictionaryTypes.LegalClassification,
        ]),
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (routerState?.presetIndividual) {
      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    setValues(initialFormValues);
    reset(initialFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      const formValues = { ...getValues() };
      Object.keys(formValues).map((key) => {
        // @ts-ignore
        if (typeof values[key] === 'string') {
          // @ts-ignore
          values[key] = '';
          // @ts-ignore
        } else if (typeof values[key] === 'object') {
          // @ts-ignore
          values[key] = null;
        }
        return false;
      });
      reset(values);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)} id="firearms_reference_table_search_form">
        <Grid container spacing={2} columns={10}>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormAutocomplete
              optionsLimiter={EDictionaryTypes.Make}
              name="make"
              label={t(ESearchFormLabels.make)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormAutocomplete
              name="model"
              optionsLimiter={EDictionaryTypes.Model}
              label={t(ESearchFormLabels.model)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormAutocomplete
              optionsLimiter={EDictionaryTypes.Manufacturer}
              name="manufacturer"
              label={t(ESearchFormLabels.manufacturer)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormSelect
              options={mapSpecificDictionaryToOptionProp(manufacturerCountry, true)}
              name="country"
              setValue={setValue}
              label={t(ESearchFormLabels.country)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormInput
              name="productCode"
              label={t(ESearchFormLabels.productCode)}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormSelect
              options={mapSpecificDictionaryToOptionProp(firearmAction)}
              name="action"
              setValue={setValue}
              label={t(ESearchFormLabels.action)}
              canBeEmpty
              control={control}
              errors={errors}
            />
          </Grid>

          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormSelect
              options={mapSpecificDictionaryToOptionProp(firearmType)}
              name="type"
              label={t(ESearchFormLabels.type)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormAutocomplete
              options={mapSpecificDictionaryToOptionProp(caliber)}
              name="caliber"
              label={t(ESearchFormLabels.caliber)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(dataSourceOptions)}
              name="isLocal"
              label={t(ESearchFormLabels.isLocal)}
              canBeEmpty={false}
              control={control}
              setValue={setValue}
              errors={errors}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
            p={0}
            columns={10}
          >
            <Grid item xs={12} lg={2} pl={2}>
              <Button
                variant={EButtonVariants.contained}
                size={EButtonSizes.small}
                id="firearms_reference_table-search"
                label={t('firearmsReferenceTable.accordion.search.button')}
                type="submit"
                fullWidth
                disabled={isSearchButtonDisabled}
                sx={{ mt: 3.5, marginBottom: '22px' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
      <SelectedFilters
        name="frt"
        values={values}
        showSaveFilters={showSaveMyFilter}
        labels={ESearchFormLabels}
        handleDelete={handleResetField}
        noPreset
        saveQueryParams={isTransactionContext ? ['type'] : undefined}
        dictionaries={{
          make: prepareFilters(make, 'uniqueId', 'name'),
          model: prepareFilters(model, 'uniqueId', 'name'),
          manufacturer: prepareFilters(manufacturer, 'uniqueId', 'name'),
          caliber: prepareFilters(caliber, 'uniqueId', 'name'),
          action: prepareFilters(firearmAction, 'uniqueId', 'name'),
          country: prepareFilters(manufacturerCountry, 'uniqueId', 'name'),
          type: prepareFilters(firearmType, 'uniqueId', 'name'),
          level: prepareFilters(level, 'uniqueId', 'name'),
          isLocal: prepareFilters(dataSourceOptions, 'uniqueId', 'name'),
        }}
        noRemovable={['isLocal']}
        saveRouterStateAfterClearAll={isSearchForFRTContext}
      />
    </SearchWrapper>
  );
};

export { FRTSearchForm };

import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { useForm } from 'react-hook-form';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { Box, Typography } from '@mui/material';
import { ERouteLinks } from 'models/route';
import { ESearchFilterKeys, ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { useFilterPresets } from 'hooks/useFilterPresets';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { prepareFilters } from 'helpers/filters';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { useTranslations } from 'hooks/useTranslations';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { useGlobalProperty } from 'models/globalPropertyContext';
import {
  IFormElementOptionProp,
  initialAncillariesSearchFormValues,
  ISearchAncillariesFormValues,
} from 'models/form';
import { useLang } from 'models/langContext';
import { EDictionaryTypes } from 'models/dictionary';
import { getFieldsByAmmunitionType, isEmptyObject } from 'pages/AncillariesPage/helpers';
import { formatDateTimeToIso, getDateFromISOOrNull } from 'utils/date';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { ISearchFormCustomFieldValue } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import FormCustomField from 'components/organisms/FormCustomField';
import {
  EAncillaryFilterTypes,
  EAncillaryState,
  EArtAmmunitionType,
  EArtAncillaryExpandType,
  EArtAncillaryType,
  EArtAncillaryTypeNameLowerCase,
} from 'constants/ArtAccessoryType';
import { getSearchFilter } from 'requests/searchFilter';
import { ISearchParams } from 'pages/UsersPage';
import { isEmpty } from 'utils/search';
import { IAncillary } from 'models/ancillaries';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useAmmunitionSearchFormSchemaValidation } from 'pages/AncillariesPage/AmmunitionPage/AmmunitionSearchForm/schemaValidation';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { EPerPages } from 'models/table';
import {
  StyledAdvancedSearch,
  StyledFormSelect,
  StyledPresetSelect,
  StyledSubtitle,
} from './styles';

export enum ESearchFormLabels {
  ancillaryTypeUniqueId = 'ammunitionTable.accordion.ammunitionType.label',
  fireCycleId = 'ammunitionTable.accordion.fireCycleId.label',
  lotNumber = 'ammunitionTable.accordion.lotNumber.label',
  batchNumber = 'ammunitionTable.accordion.batchNumber.label',
  exhibitNumber = 'ammunitionTable.accordion.exhibitNumber.label',
  manufacturerUniqueId = 'ammunitionTable.accordion.ammunitionManufacturer.label',
  productName = 'ammunitionTable.accordion.productName.label',
  factoryUniqueId = 'ammunitionTable.accordion.factory.label',
  manufactureDateFrom = 'ammunitionTable.accordion.manufactureDateFrom.label',
  manufactureDateTo = 'ammunitionTable.accordion.manufactureDateTo.label',
  expiryDateFrom = 'ammunitionTable.accordion.expiryDateFrom.label',
  expiryDateTo = 'ammunitionTable.accordion.expiryDateTo.label',
  calibreUniqueId = 'ammunitionTable.accordion.calibre.label',
  functionalTypeUniqueId = 'ammunitionTable.accordion.functionalType.label',
  cartridgeCompositionUniqueId = 'ammunitionTable.accordion.catridgeComposition.label',
  colourOfPrimerSealantUniqueId = 'ammunitionTable.accordion.colourOfPrimerSealant.label',
  projectileShapeUniqueId = 'ammunitionTable.accordion.projectileShape.label',
  shotSizeUniqueId = 'ammunitionTable.accordion.shotSize.label',
  munitionTypeUniqueId = 'ammunitionTable.accordion.munitionType.label',
  munitionVelocityUniqueId = 'ammunitionTable.accordion.munitionVelocity.label',
  headstampYearMarking = 'ammunitionTable.accordion.headstampYearMarking.label',
  alphanumericalMarkins = 'ammunitionTable.accordion.alphanumericalMarkins.label',
  stateUniqueId = 'ammunitionTable.accordion.ammunitionState.label',
  legalityUniqueId = 'ammunitionTable.accordion.ammunitionLegality.label',
  owner = 'ammunitionTable.accordion.owner.label',
  keeper = 'ammunitionTable.accordion.keeper.label',
  ownerUniqueId = 'ammunitionTable.accordion.owner.label',
  keeperUniqueId = 'ammunitionTable.accordion.keeper.label',
}

export enum ESelectLE {
  keeper = 'keeper',
  owner = 'owner',
}

interface ISearchForm {
  onSubmit: (arg: any) => void;
  ownerOptions: IFormElementOptionProp[];
  setOwnerOptions: (opt: IFormElementOptionProp[]) => void;
  keeperOptions: IFormElementOptionProp[];
  setKeeperOptions: (opt: IFormElementOptionProp[]) => void;
  showAdvanced?: boolean;
  ammunitionUniqueIds?: string[];
  isFromMap?: boolean;
  returnPath: string;
  getNewQueryPath?: (args: any) => string;
  setShowAdvanced?: (val: boolean) => void;
  initialFormValues?: ISearchAncillariesFormValues;
  customFields?: ICustomFieldByObjectType[];
  searchAncillaryFromLE?: boolean;
  isSearchButtonDisabled?: boolean;
  addAncillaryToGroupContext?: boolean;
  selectedAncillaries?: IAncillary[];
  currentPage?: number;
  perPage?: EPerPages;
}

const customFieldWrapper = (children: React.ReactNode) => (
  <Grid item lg={2.5} md={3} sm={5} xs={10}>
    {children}
  </Grid>
);

const AmmunitionSearchForm = ({
  ownerOptions,
  keeperOptions,
  setOwnerOptions,
  setKeeperOptions,
  showAdvanced = true,
  setShowAdvanced = () => {},
  ammunitionUniqueIds,
  isFromMap = false,
  returnPath,
  getNewQueryPath,
  onSubmit,
  initialFormValues = initialAncillariesSearchFormValues,
  customFields = [],
  isSearchButtonDisabled = false,
  searchAncillaryFromLE = false,
  addAncillaryToGroupContext = false,
  selectedAncillaries,
  perPage,
  currentPage,
}: ISearchForm) => {
  const [values, setValues] = useState<ISearchAncillariesFormValues>({
    ...initialFormValues,
    ammunitionUniqueIds,
  });
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const { state: routerState }: any = useLocation();
  const { t } = useTranslations();
  const { shortDateFormat } = useGlobalProperty();
  const navigate = useNavigate();
  const { schema } = useAmmunitionSearchFormSchemaValidation();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<ISearchAncillariesFormValues>({
    resolver: yupResolver(schema),
    defaultValues: initialFormValues,
  });
  const { hasPermissions } = usePermission();
  const { isDesktop } = useThemeBreakpoint();
  const { selectedLanguage } = useLang();

  const {
    loadDictionaries,
    legality,
    state,
    artAmmunitionType,
    artAmmunitionManufacturer,
    artAmmunitionFactory,
    artAmmunitionCalibreGauge,
    artAmmunitionFunctionalType,
    artAmmunitionCartridgeComposition,
    artAmmunitionColourOfPrimerSealant,
    artAmmunitionProjectileShape,
    artAmmunitionShotSize,
    artAmmunitionMunitionType,
    artAmmunitionMunitionVelocity,
  } = useAncillaries();

  const { getFilterPresets, presets, getPresetValues, preparePresetsList } =
    useFilterPresets();

  const [searchParams] = useSearchParams();
  const presetId =
    searchParams.get('presetId') ||
    searchParams.get('preset') ||
    routerState?.presetAncillaries;
  const expandParam = searchParams.get('expand');
  const watchPreset = watch('preset');
  const watchAmmunitionTypeUniqueId = watch('ancillaryTypeUniqueId');
  const [showSaveFilter, setShowSaveFilter] = useState(false);
  const [isInitialFormLoad, setIsInitialFormLoad] = useState(true);

  const dateKeys = [
    'manufactureDateFrom',
    'manufactureDateTo',
    'expiryDateFrom',
    'expiryDateTo',
    ...customFields
      .filter((cf) => cf.customFieldType === ECustomFieldType.DATE)
      .map((cf) => [`from_${cf.name}`, `to_${cf.name}`]),
  ].flat();

  useEffect(() => {
    if (!isInitialFormLoad) {
      handleSubmit((val) => onSubmit({ ...val, invokedByPageChange: true }))();
    } else {
      setIsInitialFormLoad(false);
    }
  }, [perPage, currentPage]);

  const setPresetFilters = async (
    selectedPreset: string,
    isFromEmailLink: boolean,
    isClickedManually: boolean = false,
    isInitialLoad: boolean = false,
  ) => {
    const handleValues = async () => {
      if (isFromEmailLink) {
        const filter = await getSearchFilter(selectedPreset);
        if (filter) {
          const queryValues: ISearchParams = {};
          filter.values.forEach((it) => {
            if (dateKeys.includes(it.key)) {
              queryValues[it.key] = getDateFromISOOrNull(it.value) || '';
            } else {
              queryValues[it.key] = it.value;
            }
          });
          return queryValues;
        }
      }
      return getPresetValues(selectedPreset);
    };

    const initialValues = isInitialLoad
      ? { ...initialFormValues, ...routerState?.formValues }
      : initialFormValues;

    const presetValues: any = {
      ...(isClickedManually ? initialAncillariesSearchFormValues : initialValues),
      preset: selectedPreset,
      ...(await handleValues()),
    };

    if (isClickedManually) {
      customFields
        .filter((it) => it.searchCriteria)
        .forEach((it) => {
          if (it.customFieldType === ECustomFieldType.DATE) {
            presetValues[`from_${it.name}`] = '';
            presetValues[`to_${it.name}`] = '';
          } else {
            presetValues[it.name] = '';
          }
        });
    }

    if (presetValues.ownerUniqueId && presetValues.ownerName) {
      setOwnerOptions([
        {
          label: presetValues.ownerName,
          value: presetValues.ownerUniqueId,
        },
      ]);
    }

    if (presetValues.keeperUniqueId && presetValues.keeperName) {
      setKeeperOptions([
        {
          label: presetValues.keeperName,
          value: presetValues.keeperUniqueId,
        },
      ]);
    }

    if (presetValues.ancillaryTypeUniqueId) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      ammunitionTypeChange(presetValues.ancillaryTypeUniqueId);
    }

    setValues(presetValues);
    if (selectedPreset !== '') {
      setShowAdvanced(true);
    }
    reset(presetValues);

    if (isClickedManually) {
      await handleSubmit(onSubmit)();
    }
  };

  const setLegalEntity = () => {
    const formValues = {
      ...initialFormValues,
      ...routerState?.formValues,
    };

    if (routerState?.type && routerState?.legalEntity) {
      const legalName = routerState?.legalEntity?.['organization.organizationName']
        ? routerState?.legalEntity?.['organization.organizationName']
        : `${routerState?.legalEntity?.['individual.firstName']} ${routerState?.legalEntity?.['individual.lastName']}`;
      const option = [
        {
          label: legalName,
          value: routerState.legalEntity.id,
        },
      ];
      if (routerState.type === ESelectLE.keeper) {
        setKeeperOptions(option);
        formValues.keeperName = legalName;
        if (routerState?.options?.ownerOptions) {
          setOwnerOptions(routerState.options.ownerOptions);
        }
      } else {
        setOwnerOptions(option);
        formValues.ownerName = legalName;
        if (routerState?.options?.keeperOptions) {
          setKeeperOptions(routerState.options.keeperOptions);
        }
      }
      formValues[routerState?.type === ESelectLE.owner ? 'ownerUniqueId' : 'keeperUniqueId'] =
        routerState.legalEntity.id;
    }

    setValues(formValues);
    setShowAdvanced(true);
    reset(formValues);
  };

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchAncillariesFormValues);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([
        EDictionaryTypes.Legality,
        EDictionaryTypes.State,
        EDictionaryTypes.ArtAmmunitionType,
        EDictionaryTypes.ArtAmmunitionManufacturer,
        EDictionaryTypes.ArtAmmunitionFactory,
        EDictionaryTypes.ArtAmmunitionCalibreGauge,
        EDictionaryTypes.ArtAmmunitionCartridgeComposition,
        EDictionaryTypes.ArtAmmunitionColourOfPrimerSealant,
        EDictionaryTypes.ArtAmmunitionProjectileShape,
        EDictionaryTypes.ArtAmmunitionFunctionalType,
        EDictionaryTypes.ArtAmmunitionShotSize,
        EDictionaryTypes.ArtAmmunitionMunitionType,
        EDictionaryTypes.ArtAmmunitionMunitionVelocity,
        EDictionaryTypes.ManufacturerCountry,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (
      routerState?.formValues &&
      routerState?.module === EArtAncillaryTypeNameLowerCase.AMMUNITION
    ) {
      const formValues = {
        ...routerState.formValues,
        legalityUniqueId: routerState?.legalityUniqueId,
        keeperUniqueId: routerState?.options?.keeperOptions[0]?.value || '',
        ownerUniqueId: routerState?.options?.ownerOptions[0]?.value || '',
      };
      setValues(formValues);
      reset(formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function init() {
      await getFilterPresets(ESearchFilterTypes.ancillaries, EArtAncillaryType.AMMUNITION);

      if (routerState?.options?.keeperOptions.length) {
        setKeeperOptions(routerState.options.keeperOptions);
      }

      if (routerState?.options?.ownerOptions.length) {
        setOwnerOptions(routerState.options.ownerOptions);
      }

      if (presetId && expandParam === EArtAncillaryExpandType.AMMUNITION) {
        await setPresetFilters(presetId, true);
        await handleSubmit(onSubmit)();
      }

      if (
        routerState?.presetAncillaries &&
        routerState?.ancillaryFilterType === EAncillaryFilterTypes.AMMUNITION
      ) {
        setValue('preset', routerState.presetAncillaries);
      }

      if (!isEmptyObject(routerState?.formValues)) {
        setShowAdvanced(true);
      }
      if (routerState?.legalEntity) {
        setLegalEntity();
      }
      if (
        routerState?.module === EArtAncillaryTypeNameLowerCase.ACCESSORY ||
        routerState?.module === EArtAncillaryTypeNameLowerCase.COMPONENTS
      ) {
        reset(values);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareData = (data: ISearchAncillariesFormValues): ISearchAncillariesFormValues => {
    const customFieldsPayload: ISearchFormCustomFieldValue[] = [];

    Object.keys(data).map((key) => {
      const isDateCF = key.startsWith('from_') || key.startsWith('to_');

      if (!isDateCF) {
        const customField = customFields.find((item) => item.name === key);
        if (customField && data[key]) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (customField.customFieldType === ECustomFieldType.TEXT) {
            if (data[key] === undefined || data[key] === null) {
              customFieldItem.text = undefined;
            } else if (data[key].trim() === '') {
              customFieldItem.text = undefined;
            } else {
              customFieldItem.text = data[key];
            }
          } else if (customField.customFieldType === ECustomFieldType.BOOL) {
            customFieldItem.bool = data[key] === '1';
          }

          customFieldsPayload.push(customFieldItem);
          delete data[key];
        }
      } else {
        const customFieldKey = key.startsWith('from_')
          ? key.replace('from_', '')
          : key.replace('to_', '');

        const customField = customFields.find((item) => item.name === customFieldKey);

        if (customField && (data[`from_${customFieldKey}`] || data[`to_${customFieldKey}`])) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (data[`from_${customFieldKey}`]) {
            customFieldItem.dateFrom =
              data[`from_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`from_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          if (data[`to_${customFieldKey}`]) {
            customFieldItem.dateTo =
              data[`to_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`to_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          customFieldsPayload.push(customFieldItem);
          if (data[`from_${customFieldKey}`]) {
            delete data[`from_${customFieldKey}`];
          }
          if (data[`to_${customFieldKey}`]) {
            delete data[`to_${customFieldKey}`];
          }
        }
      }
      return false;
    });

    if (customFieldsPayload?.length) {
      data.customFields = customFieldsPayload;
    } else {
      delete data.customFields;
    }

    if (ownerOptions.length > 0) {
      data.ownerName = ownerOptions[0]?.label || '';
    }
    if (keeperOptions.length > 0) {
      data.keeperName = keeperOptions[0]?.label || '';
    }
    return data;
  };

  const submitForm = (data: ISearchAncillariesFormValues) => {
    onSubmit({ ...prepareData(data), formSubmitted: true });
  };

  const ammunitionTypeChange = (value: any) => {
    const currentValues = getValues();
    const formValues = {
      ...initialAncillariesSearchFormValues,
      ancillaryTypeUniqueId: value,
      preset: currentValues.preset,
      ownerUniqueId: currentValues.ownerUniqueId,
      keeperUniqueId: currentValues.keeperUniqueId,
    };

    setValues(formValues);

    if (value) {
      const fields = getFieldsByAmmunitionType(value);
      setAccessFields(fields);
    } else {
      setAccessFields([]);
    }
  };

  useEffect(() => {
    if (
      Object.values(EArtAmmunitionType).includes(
        watchAmmunitionTypeUniqueId as EArtAmmunitionType,
      )
    ) {
      const fields = getFieldsByAmmunitionType(watchAmmunitionTypeUniqueId);
      setAccessFields(fields);
    }
    if (watchAmmunitionTypeUniqueId === '') {
      setAccessFields([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAmmunitionTypeUniqueId]);

  useEffect(() => {
    if (initialFormValues?.ancillaryTypeUniqueId) {
      const fields = getFieldsByAmmunitionType(initialFormValues.ancillaryTypeUniqueId);
      setAccessFields(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isEmpty(values.ancillaryTypeUniqueId) &&
      isEmpty(values.fireCycleId) &&
      isEmpty(values.lotNumber) &&
      isEmpty(values.batchNumber) &&
      isEmpty(values.exhibitNumber) &&
      isEmpty(values.manufacturerUniqueId) &&
      isEmpty(values.productName) &&
      isEmpty(values.factoryUniqueId) &&
      isEmpty(values.manufactureDateFrom) &&
      isEmpty(values.manufactureDateTo) &&
      isEmpty(values.expiryDateTo) &&
      isEmpty(values.expiryDateFrom) &&
      isEmpty(values.calibreUniqueId) &&
      isEmpty(values.functionalTypeUniqueId) &&
      isEmpty(values.cartridgeCompositionUniqueId) &&
      isEmpty(values.colourOfPrimerSealantUniqueId) &&
      isEmpty(values.projectileShapeUniqueId) &&
      isEmpty(values.shotSizeUniqueId) &&
      isEmpty(values.munitionTypeUniqueId) &&
      isEmpty(values.munitionVelocityUniqueId) &&
      isEmpty(values.headstampYearMarking) &&
      isEmpty(values.alphanumericalMarkins) &&
      isEmpty(values.stateUniqueId) &&
      isEmpty(values.legalityUniqueId) &&
      isEmpty(values.shotSizeUniqueId) &&
      isEmpty(values.ownerUniqueId) &&
      isEmpty(values.keeperUniqueId)
    ) {
      setShowSaveFilter(false);
    } else {
      setShowSaveFilter(true);
    }
  }, [values]);

  const resetValues = () => {
    const formValues = { ...getValues() };
    Object.keys(formValues).map((key: string) => {
      if (typeof values[key] === 'string') {
        values[key] = '';
      } else if (typeof values[key] === 'object') {
        values[key] = null;
      } else {
        values[key] = null;
      }
      return false;
    });
    reset(values);
  };

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    const currentValues = getValues();
    if (name) {
      if (name === 'ancillaryTypeUniqueId') {
        setValue('ancillaryTypeUniqueId', '');
        resetValues();
        ammunitionTypeChange(null);
        onSubmit(values);
        return;
      }
      if (name === 'ownerUniqueId') {
        setOwnerOptions([]);
        currentValues.ownerUniqueId = '';
      }
      if (name === 'keeperUniqueId') {
        setKeeperOptions([]);
        currentValues.keeperUniqueId = '';
      }
      reset({ ...currentValues, [name]: '' });
    } else {
      resetValues();
    }
    if (refreshResults) {
      onSubmit(values);
    }
  };

  useEffect(() => {
    (async function init() {
      if (watchPreset) {
        await setPresetFilters(watchPreset, false, false, true);
      } else {
        await setPresetFilters('', false, false, true);
      }
      await handleSubmit(onSubmit)();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialAncillariesSearchFormValues.ancillaryTypeUniqueId) {
      ammunitionTypeChange(initialAncillariesSearchFormValues.ancillaryTypeUniqueId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAncillariesSearchFormValues]);

  const checkDisabled = (fieldName: string) => !accessFields.includes(fieldName);

  const clearAfterComponentTypeChange = () => {
    const formValues = { ...getValues() };
    Object.keys(formValues).map((key: string) => {
      if (typeof values[key] === 'string') {
        values[key] = '';
      } else if (typeof values[key] === 'object') {
        values[key] = null;
      }
      return false;
    });
    values.ancillaryTypeUniqueId = formValues.ancillaryTypeUniqueId;
    reset(values);
    onSubmit(values);
  };

  const getStateOptions = () => {
    if (!hasPermissions([EPermission.DISABLE_ANCILLARY])) {
      return mapDictionaryToOptionProp(state).filter(
        (item) => item.value !== EAncillaryState.DISABLED,
      );
    }
    return mapDictionaryToOptionProp(state);
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(submitForm)}>
        {!searchAncillaryFromLE && (
          <Grid
            container
            justifyContent="space-between"
            sx={{ mb: { xs: 2, md: 0 } }}
            spacing={1}
          >
            <Grid item md={6} sm={12} xs={12}>
              <StyledPresetSelect
                options={
                  presets
                    ? preparePresetsList(
                        presets,
                        'ancillaries.accordion.selectFilterPreset.label',
                      )
                    : preparePresetsList([], 'ancillaries.accordion.selectFilterPreset.label')
                }
                name="preset"
                control={control}
                errors={errors}
                setValue={(name: string, value: string) => {
                  setValue('preset', value);
                  handleResetField();
                }}
                onSelect={(val) => {
                  if (!val) {
                    handleResetField();
                  } else {
                    setPresetFilters(val, false, true);
                  }
                }}
                withValidation={false}
                withLabel={false}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant={EButtonVariants.outlined}
                  label={t('ancillaries.accordion.advancedFilters.button')}
                  onClick={() => setShowAdvanced(!showAdvanced)}
                  fullWidth={!isDesktop}
                  size={EButtonSizes.small}
                />
              </Box>
            </Grid>
          </Grid>
        )}

        {showAdvanced && (
          <StyledAdvancedSearch container spacing={2} columns={10}>
            <Grid item xs={12}>
              <StyledSubtitle item xs={3}>
                <Typography variant="subtitle2">
                  {t('ancillaries.accordion.subtilte.label')}
                </Typography>
              </StyledSubtitle>
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAmmunitionType)}
                name="ancillaryTypeUniqueId"
                label={t(ESearchFormLabels.ancillaryTypeUniqueId)}
                setValue={(name: string, value: string) => {
                  setValue('ancillaryTypeUniqueId', value);
                  if (!value) {
                    ammunitionTypeChange(null);
                    handleResetField();
                  }
                }}
                canBeEmpty
                control={control}
                errors={errors}
                onSelect={clearAfterComponentTypeChange}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="fireCycleId"
                label={t(ESearchFormLabels.fireCycleId)}
                control={control}
                errors={errors}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="lotNumber"
                label={t(ESearchFormLabels.lotNumber)}
                control={control}
                errors={errors}
                disabled={checkDisabled('lotNumber')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="batchNumber"
                label={t(ESearchFormLabels.batchNumber)}
                control={control}
                errors={errors}
                disabled={checkDisabled('batchNumber')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="exhibitNumber"
                label={t(ESearchFormLabels.exhibitNumber)}
                control={control}
                errors={errors}
                disabled={checkDisabled('exhibitNumber')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                name="manufacturerUniqueId"
                options={mapDictionaryToOptionProp(artAmmunitionManufacturer)}
                label={t(ESearchFormLabels.manufacturerUniqueId)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('manufacturerUniqueId')}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="productName"
                label={t(ESearchFormLabels.productName)}
                control={control}
                errors={errors}
                disabled={checkDisabled('productName')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAmmunitionFactory)}
                name="factoryUniqueId"
                label={t(ESearchFormLabels.factoryUniqueId)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('factoryUniqueId')}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormDatePicker
                name="manufactureDateFrom"
                dateFormat={shortDateFormat}
                label={t(ESearchFormLabels.manufactureDateFrom)}
                disabled={checkDisabled('manufactureDateFrom')}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormDatePicker
                name="manufactureDateTo"
                dateFormat={shortDateFormat}
                label={t(ESearchFormLabels.manufactureDateTo)}
                disabled={checkDisabled('manufactureDateTo')}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormDatePicker
                name="expiryDateFrom"
                dateFormat={shortDateFormat}
                label={t(ESearchFormLabels.expiryDateFrom)}
                disabled={checkDisabled('expiryDateFrom')}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormDatePicker
                name="expiryDateTo"
                dateFormat={shortDateFormat}
                label={t(ESearchFormLabels.expiryDateTo)}
                disabled={checkDisabled('expiryDateTo')}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAmmunitionCalibreGauge)}
                name="calibreUniqueId"
                label={t(ESearchFormLabels.calibreUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('calibreUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAmmunitionFunctionalType)}
                name="functionalTypeUniqueId"
                label={t(ESearchFormLabels.functionalTypeUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('functionalTypeUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAmmunitionCartridgeComposition)}
                name="cartridgeCompositionUniqueId"
                label={t(ESearchFormLabels.cartridgeCompositionUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('cartridgeCompositionUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAmmunitionColourOfPrimerSealant)}
                name="colourOfPrimerSealantUniqueId"
                label={t(ESearchFormLabels.colourOfPrimerSealantUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('colourOfPrimerSealantUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAmmunitionProjectileShape)}
                name="projectileShapeUniqueId"
                label={t(ESearchFormLabels.projectileShapeUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('projectileShapeUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAmmunitionShotSize)}
                name="shotSizeUniqueId"
                label={t(ESearchFormLabels.shotSizeUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('shotSizeUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAmmunitionMunitionType)}
                name="munitionTypeUniqueId"
                label={t(ESearchFormLabels.munitionTypeUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('munitionTypeUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAmmunitionMunitionVelocity)}
                name="munitionVelocityUniqueId"
                label={t(ESearchFormLabels.munitionVelocityUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('munitionVelocityUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="headstampYearMarking"
                label={t(ESearchFormLabels.headstampYearMarking)}
                control={control}
                errors={errors}
                disabled={checkDisabled('headstampYearMarking')}
                type={EFormInputTypes.number}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="alphanumericalMarkins"
                label={t(ESearchFormLabels.alphanumericalMarkins)}
                control={control}
                errors={errors}
                disabled={checkDisabled('alphanumericalMarkins')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={getStateOptions()}
                name="stateUniqueId"
                label={t(ESearchFormLabels.stateUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('stateUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(legality)}
                name="legalityUniqueId"
                label={t(ESearchFormLabels.legalityUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('legalityUniqueId')}
                setValue={setValue}
              />
            </Grid>

            {customFields.map((customField) => (
              <FormCustomField
                data={customField}
                setValue={setValue}
                control={control}
                errors={errors}
                key={customField.uniqueId}
                wrapper={customFieldWrapper}
                isSearch={true}
              />
            ))}

            {new Array(4 - customFields.length >= 0 ? 4 - customFields.length : 0)
              .fill('')
              .map((index) => (
                <Grid item lg={2} md={5} sm={5} xs={10} key={index} />
              ))}

            {hasPermissions([EPermission.VIEW_LE]) && (
              <Grid item xs={12} container columns={10} columnSpacing={2}>
                <Grid item xs={10} sm={5} lg={7}>
                  <StyledFormSelect
                    name="ownerUniqueId"
                    label={t(ESearchFormLabels.owner)}
                    control={control}
                    errors={errors}
                    options={ownerOptions}
                    disabled={checkDisabled('ownerUniqueId')}
                    setValue={setValue}
                  />
                </Grid>

                <Grid item xs={10} sm={5} lg={3}>
                  <Button
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    label={t('ancillaries.accordion.selectLegalEntity.button')}
                    fullWidth
                    sx={{
                      mt: {
                        sm: 3.5,
                      },
                    }}
                    onClick={() =>
                      navigate(ERouteLinks.SelectLegalEntity, {
                        state: {
                          legalityUniqueId: routerState?.legalityUniqueId,
                          type: ESelectLE.owner,
                          form: getValues(),
                          isFromMap: isFromMap || routerState?.isFromMap,
                          module: EArtAncillaryTypeNameLowerCase.AMMUNITION,
                          options: { keeperOptions, ownerOptions },
                          returnPath,
                          context: 'searchAncillaries',
                          ...(addAncillaryToGroupContext
                            ? {
                                ancillaryOption: EArtAncillaryType.AMMUNITION,
                                selectedAncillaries,
                              }
                            : undefined),
                          ammunitionUniqueIds,
                          breadcrumbs: [
                            {
                              label: t('module.ancillaries.name'),
                              route: ERouteLinks.Ancillaries,
                            },
                            t('ancillaries.search.selectLegal.label'),
                          ],
                        },
                      })
                    }
                  />
                </Grid>
              </Grid>
            )}
            {!addAncillaryToGroupContext && hasPermissions([EPermission.VIEW_LE]) && (
              <Grid item xs={12} container columns={10} columnSpacing={2}>
                <Grid item xs={10} sm={5} lg={7}>
                  <StyledFormSelect
                    name="keeperUniqueId"
                    label={t(ESearchFormLabels.keeper)}
                    control={control}
                    errors={errors}
                    options={keeperOptions}
                    disabled={true}
                    setValue={setValue}
                  />
                </Grid>
                <Grid item xs={10} sm={5} lg={3}>
                  <Button
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    label={t('ancillaries.accordion.selectLegalEntity.button')}
                    fullWidth
                    sx={{
                      mt: {
                        sm: 3.5,
                      },
                    }}
                    onClick={() =>
                      navigate(ERouteLinks.SelectLegalEntity, {
                        state: {
                          type: ESelectLE.keeper,
                          form: getValues(),
                          isFromMap: isFromMap || routerState?.isFromMap,
                          module: EArtAncillaryTypeNameLowerCase.AMMUNITION,
                          options: { keeperOptions, ownerOptions },
                          returnPath,
                          context: 'searchAncillaries',
                          ammunitionUniqueIds,
                          breadcrumbs: [
                            {
                              label: t('module.ancillaries.name'),
                              route: ERouteLinks.Ancillaries,
                            },
                            t('ancillaries.search.selectLegal.label'),
                          ],
                        },
                      })
                    }
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              container
              columns={10}
              columnSpacing={2}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Grid item xs={10} sm={5} lg={2}>
                <Button
                  variant={EButtonVariants.contained}
                  size={EButtonSizes.small}
                  disabled={isSearchButtonDisabled}
                  label={t('ancillaries.accordion.search.button')}
                  type="submit"
                  id="ancillaries_ammunition_search-button"
                  fullWidth
                  sx={{
                    mt: {
                      sm: 3.5,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </StyledAdvancedSearch>
        )}
      </form>
      {showAdvanced && (
        <SelectedFilters
          name="ammunition"
          values={values}
          excludes={[
            'preset',
            'uniqueIds',
            'ownerName',
            'keeperName',
            'perPage',
            'currentPage',
          ]}
          customFields={customFields}
          labels={ESearchFormLabels}
          additionalSaveFilterValues={{
            owner: ownerOptions[0],
            keeper: keeperOptions[0],
            ammunitionUniqueIds,
          }}
          handleDelete={handleResetField}
          type={ESearchFilterKeys.ammunition}
          saveRoute={ERouteLinks.SaveAncillariesPreset.replace(
            ':type',
            ESearchFilterKeys.ammunition,
          )}
          updatedQueryPath={
            getNewQueryPath ? getNewQueryPath(prepareData(getValues())) : undefined
          }
          noRemovable={['uniqueIds']}
          showSaveFilters={addAncillaryToGroupContext ? false : showSaveFilter}
          additionalPath={
            isFromMap || (routerState && routerState.isFromMap) ? '?fromMap=true' : ''
          }
          dictionaries={{
            ancillaryTypeUniqueId: prepareFilters(artAmmunitionType, 'uniqueId', 'name'),
            manufacturerUniqueId: prepareFilters(
              artAmmunitionManufacturer,
              'uniqueId',
              'name',
            ),
            factoryUniqueId: prepareFilters(artAmmunitionFactory, 'uniqueId', 'name'),
            calibreUniqueId: prepareFilters(artAmmunitionCalibreGauge, 'uniqueId', 'name'),
            cartridgeCompositionUniqueId: prepareFilters(
              artAmmunitionCartridgeComposition,
              'uniqueId',
              'name',
            ),
            colourOfPrimerSealantUniqueId: prepareFilters(
              artAmmunitionColourOfPrimerSealant,
              'uniqueId',
              'name',
            ),
            projectileShapeUniqueId: prepareFilters(
              artAmmunitionProjectileShape,
              'uniqueId',
              'name',
            ),
            functionalTypeUniqueId: prepareFilters(
              artAmmunitionFunctionalType,
              'uniqueId',
              'name',
            ),
            munitionTypeUniqueId: prepareFilters(
              artAmmunitionMunitionType,
              'uniqueId',
              'name',
            ),
            shotSizeUniqueId: prepareFilters(artAmmunitionShotSize, 'uniqueId', 'name'),
            munitionVelocityUniqueId: prepareFilters(
              artAmmunitionMunitionVelocity,
              'uniqueId',
              'name',
            ),
            legalityUniqueId: prepareFilters(legality, 'uniqueId', 'name'),
            stateUniqueId: prepareFilters(state, 'uniqueId', 'name'),
            ownerUniqueId: prepareFilters(ownerOptions, 'value', 'label'),
            keeperUniqueId: prepareFilters(keeperOptions, 'value', 'label'),
          }}
          dictionariesToSave={['ownerUniqueId', 'keeperUniqueId']}
          saveRouterStateAfterClearAll={addAncillaryToGroupContext}
          saveQueryParams={addAncillaryToGroupContext ? ['type'] : undefined}
        />
      )}
    </SearchWrapper>
  );
};

export { AmmunitionSearchForm };

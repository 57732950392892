import React, { useEffect, useState } from 'react';
import { Table } from 'components/molecules/Table';
import { useDictionaries } from 'pages/DictionariesPage/hooks';
import { StyledPageTitle } from 'theme/styles';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { Alert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { Pagination } from 'components/molecules/Pagination';
import { EPerPages, ISortOptions } from 'models/table';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { CardList } from 'components/molecules/CardList';
import { DictionaryTableCard } from 'pages/DictionariesPage/DictionaryTableCard';
import { useTranslations } from 'hooks/useTranslations';
import { Box } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import { objectToQueryString } from 'helpers/searchQuery';
import { dictionariesTableColumns, mapDictionariesToDataSource } from './helpers';

const DictionariesPage = () => {
  const { dictionaryAlert, nextPageAlert } = useAlert();
  const { dictionaries, getDictionariesData, paginator } = useDictionaries();
  const { t } = useTranslations();
  const [searchParams] = useSearchParams();
  const { pathname }: any = useLocation();

  const perPageFromQuery = Number.parseInt(searchParams.get('perPage') || '25', 10);
  const currentPageFromQuery = Number.parseInt(searchParams.get('currentPage') || '1', 10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromQuery > 0 ? currentPageFromQuery : 1,
  );
  const [perPage, setPerPage] = useState<EPerPages>(
    [25, 50, 100].includes(perPageFromQuery)
      ? (perPageFromQuery as EPerPages)
      : EPerPages.perPage25,
  );
  const [sort, setSort] = useState<ISortOptions | null>(null);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const { isDesktop } = useThemeBreakpoint();

  useEffect(() => {
    (async function getDictionariesDataInit() {
      setIsPaginationLoading(true);
      const query = objectToQueryString({
        currentPage,
        perPage,
      });
      if (query.length) {
        window.history.replaceState({}, '', `${pathname}?${query}`);
      }
      await getDictionariesData(currentPage, perPage, sort);
      setIsPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, sort]);

  return (
    <Box>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          t('dictionaries.header'),
        ]}
      />
      <StyledPageTitle variant="h4" marginBottom={3}>
        {t('dictionaries.header')}
      </StyledPageTitle>

      {dictionaryAlert && (
        <Alert text={dictionaryAlert.text} variant={dictionaryAlert.variant} />
      )}
      {nextPageAlert && <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />}

      {isDesktop ? (
        <Table
          columns={dictionariesTableColumns({
            t,
          })}
          dataSource={mapDictionariesToDataSource(dictionaries)}
          onSort={setSort}
        />
      ) : (
        <CardList
          items={mapDictionariesToDataSource(dictionaries)}
          render={(data, index) => <DictionaryTableCard data={data} key={index} />}
        />
      )}

      {paginator && (
        <Pagination
          count={paginator?.totalElements}
          onChangePage={(page) => setCurrentPage(page)}
          current={currentPage}
          perPage={perPage}
          onChangePerPage={(value) => setPerPage(value)}
          isLoading={isPaginationLoading}
        />
      )}
    </Box>
  );
};

export default DictionariesPage;

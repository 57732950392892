import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { useForm } from 'react-hook-form';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { FormInput } from 'components/molecules/FormInput';
import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { useLang } from 'models/langContext';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import {
  filterHostTypeByAccessoryType,
  getFieldsByAccessoriesType,
} from 'pages/AncillariesPage/helpers';
import { EArtAccessoryType } from 'constants/ArtAccessoryType';
import { IARTAccessoriesFormValues } from 'models/ancillaries';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loader } from 'components/atoms/Loader';
import { FormWyswigEditor } from 'components/molecules/FormWyswigEditor';
import { useScroll } from 'hooks/useScroll';
import { IFormElementOptionProp } from 'models/form';
import useAccessoriesFieldsFormSchema from './schemaValidation';

export enum ESearchFormLabels {
  accessoryTypeUniqueId = 'ancillariesReferenceTable.accordion.accessoryType.label',
  manufacturerUniqueId = 'ancillariesReferenceTable.accordion.accessoryManufacturer.label',
  productName = 'ancillariesReferenceTable.accordion.productName.label',
  hostTypeUniqueId = 'ancillariesReferenceTable.accordion.hostType.label',
  calibreUniqueId = 'ancillariesReferenceTable.accordion.calibre.label',
  magnificationUniqueId = 'ancillariesReferenceTable.accordion.magnification.label',
  utilityUniqueId = 'ancillariesReferenceTable.accordion.utility.label',
  rectileUniqueId = 'ancillariesReferenceTable.accordion.reticle.label',
  lightColourUniqueId = 'ancillariesReferenceTable.accordion.lightColour.label',
  compositionUniqueId = 'ancillariesReferenceTable.accordion.composition.label',
  capacity = 'ancillariesReferenceTable.accordion.capacity.label',
  proofHouseUniqueId = 'ancillariesReferenceTable.accordion.proofMark.label',
  notes = 'ancillariesReferenceTable.accordion.notes.label',
}

const initialFormValues: IARTAccessoriesFormValues = {
  accessoryTypeUniqueId: '',
  manufacturerUniqueId: '',
  productName: '',
  hostTypeUniqueId: '',
  calibreUniqueId: '',
  magnificationUniqueId: '',
  utilityUniqueId: '',
  rectileUniqueId: '',
  lightColourUniqueId: '',
  compositionUniqueId: '',
  capacity: '',
  proofHouseUniqueId: '',
  notes: '',
};

export interface IAccessoriesForm {
  onSubmit: (arg: IARTAccessoriesFormValues) => void;
  onCancel: () => void;
  initialData?: IARTAccessoriesFormValues;
  isEdit?: boolean;
}

const ARTAccessoriesForm = ({
  onSubmit,
  onCancel,
  initialData = {},
  isEdit = false,
}: IAccessoriesForm) => {
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const [accessoryManufacturer, setAccessoryManufacturer] = useState<IDictionary[] | null>(
    null,
  );
  const [accessoryHostType, setAccessoryHostType] = useState<IDictionary[] | null>(null);
  const [isLoadingDictionaries, setIsLoadingDictionaries] = useState<boolean>(true);
  const { t } = useTranslations();
  const { elRef, scrollToError } = useScroll();
  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();
  const { schema } = useAccessoriesFieldsFormSchema();
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IARTAccessoriesFormValues>({
    defaultValues: isEdit ? initialData : initialFormValues,
    resolver: yupResolver(schema),
  });

  const { selectedLanguage } = useLang();
  const {
    loadDictionaries,
    artAccessoryCalibre,
    artAccessoryComposition,
    artAccessoryHostType,
    artAccessoryLightColour,
    artAccessoryMagnification,
    artAccessoryReticle,
    artAccessoryType,
    artAccessoryUtility,
    artAccessoryProofHouse,
    artAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
  } = useAncillaries();

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([
        EDictionaryTypes.ArtAccessoryType,
        EDictionaryTypes.ArtAccessoryCalibre,
        EDictionaryTypes.ArtAccessoryHostType,
        EDictionaryTypes.ArtaccessoryComposition,
        EDictionaryTypes.ArtAccessoryMagnification,
        EDictionaryTypes.ArtAccessoryUtility,
        EDictionaryTypes.ArtAccessoryReticle,
        EDictionaryTypes.ArtAccessoryLightColour,
        EDictionaryTypes.ArtAccessoryProofHouse,
        EDictionaryTypes.ArtAccessoryManufacturerFlashEliminator,
        EDictionaryTypes.ArtAccessoryManufacturerLaserLightModule,
        EDictionaryTypes.ArtAccessoryManufacturerMagazine,
        EDictionaryTypes.ArtAccessoryManufacturerOpticalSight,
        EDictionaryTypes.ArtAccessoryManufacturerSuppressor,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const submitForm = async (data: IARTAccessoriesFormValues) => {
    setSubmitLoading(true);
    await onSubmit(data);
    setSubmitLoading(false);
  };

  const handleCancel = () => {
    if (routerState?.prevPage) {
      navigate(routerState.prevPage);
    } else {
      onCancel();
    }
  };

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  const setAccessoryManufacturerDictionaryData = (accessoriesTypeId: EArtAccessoryType) => {
    switch (accessoriesTypeId) {
      case EArtAccessoryType.MAGAZINES:
        setAccessoryManufacturer(artAccessoryManufacturerMagazine);
        break;
      case EArtAccessoryType.LASER_LIGHT_MODULES:
        setAccessoryManufacturer(artAccessoryManufacturerLaserLightModule);
        break;
      case EArtAccessoryType.OPTICAL_SIGHTS:
        setAccessoryManufacturer(artAccessoryManufacturerOpticalSight);
        break;
      case EArtAccessoryType.FLASH_ELIMINATORS:
        setAccessoryManufacturer(artAccessoryManufacturerFlashEliminator);
        break;
      case EArtAccessoryType.SUPPRESSORS:
        setAccessoryManufacturer(artAccessoryManufacturerSuppressor);
        break;
      default:
        setAccessoryManufacturer(null);
        break;
    }
  };

  const accessoryTypeChange = (value: any) => {
    const notes = getValues('notes');
    if (isEdit) {
      reset({ ...initialData, accessoryTypeUniqueId: value, notes });
    } else {
      reset({ ...initialFormValues, accessoryTypeUniqueId: value, notes });
    }
    if (value) {
      const fields = getFieldsByAccessoriesType(value);
      setAccessFields(fields);
      setAccessoryManufacturerDictionaryData(value);
      setAccessoryHostType(
        filterHostTypeByAccessoryType(value as EArtAccessoryType, artAccessoryHostType),
      );
    } else {
      setAccessFields([]);
      setAccessoryManufacturer(null);
      setAccessoryHostType(null);
    }
  };

  useEffect(() => {
    if (
      isEdit &&
      initialData.accessoryTypeUniqueId &&
      artAccessoryManufacturerFlashEliminator?.length &&
      artAccessoryManufacturerLaserLightModule?.length &&
      artAccessoryManufacturerMagazine?.length &&
      artAccessoryManufacturerSuppressor?.length &&
      artAccessoryManufacturerOpticalSight?.length
    ) {
      accessoryTypeChange(initialData.accessoryTypeUniqueId);
      reset(initialData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEdit,
    initialData,
    artAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    artAccessoryManufacturerSuppressor,
    artAccessoryManufacturerOpticalSight,
  ]);

  useEffect(() => {
    if (!isEdit) {
      reset(initialFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accessoryManufacturer?.length || !isEdit) {
      setIsLoadingDictionaries(false);
    }
  }, [accessoryManufacturer, isEdit]);

  const checkDisabled = (fieldName: string) => !accessFields.includes(fieldName);

  const sortMagnification = (elements: IFormElementOptionProp[]) => {
    try {
      return elements.sort(
        (a, b) =>
          parseInt(a.label.replace('x', ''), 10) - parseInt(b.label.replace('x', ''), 10),
      );
    } catch (e: any) {
      return elements;
    }
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={2} columns={10} ref={elRef}>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <SearchWrapper>
            <Typography variant="h5">
              {t('ancillariesDetails.accordion.title.label')}
            </Typography>
          </SearchWrapper>
        </Grid>
        {!isLoadingDictionaries ? (
          <>
            <Grid item lg={2.5} md={3} sm={5} xs={12}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryType)}
                name="accessoryTypeUniqueId"
                label={t(ESearchFormLabels.accessoryTypeUniqueId)}
                setValue={setValue}
                setValueAdditionalBehavior={accessoryTypeChange}
                canBeEmpty
                control={control}
                errors={errors}
                onSelect={accessoryTypeChange}
                disabled={isEdit}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                name="manufacturerUniqueId"
                options={mapDictionaryToOptionProp(accessoryManufacturer)}
                label={t(ESearchFormLabels.manufacturerUniqueId)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('manufacturerUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="productName"
                label={t(ESearchFormLabels.productName)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('productName')}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryCalibre)}
                name="calibreUniqueId"
                label={t(ESearchFormLabels.calibreUniqueId)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('calibreUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(accessoryHostType)}
                name="hostTypeUniqueId"
                label={t(ESearchFormLabels.hostTypeUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('hostTypeUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryComposition)}
                name="compositionUniqueId"
                label={t(ESearchFormLabels.compositionUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('compositionUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={sortMagnification(
                  mapDictionaryToOptionProp(artAccessoryMagnification),
                )}
                name="magnificationUniqueId"
                label={t(ESearchFormLabels.magnificationUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('magnificationUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryUtility)}
                name="utilityUniqueId"
                label={t(ESearchFormLabels.utilityUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('utilityUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="capacity"
                label={t(ESearchFormLabels.capacity)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('capacity')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryReticle)}
                name="rectileUniqueId"
                label={t(ESearchFormLabels.rectileUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('rectileUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryLightColour)}
                name="lightColourUniqueId"
                label={t(ESearchFormLabels.lightColourUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('lightColourUniqueId')}
                required={true}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryProofHouse)}
                name="proofHouseUniqueId"
                label={t(ESearchFormLabels.proofHouseUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('proofHouseUniqueId')}
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <FormWyswigEditor
                name="notes"
                label={t(ESearchFormLabels.notes)}
                setValue={setValue}
                control={control}
                errors={errors}
                disabled={checkDisabled('notes')}
                setError={setError}
                clearErrors={clearErrors}
                charsLimit={5000}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              columns={10}
              columnSpacing={2}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Grid item xs={12} container columnSpacing={3} rowGap={2} mt={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <Button
                    type="submit"
                    fullWidth
                    label={t('general.save.button')}
                    id="save-button"
                    variant={EButtonVariants.contained}
                    isLoading={isSubmitLoading}
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <Button
                    fullWidth
                    label={t('general.cancel.button')}
                    id="cancel-button"
                    variant={EButtonVariants.outlined}
                    onClick={handleCancel}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} justifyContent="center">
            <Loader isLoading={isLoadingDictionaries} />
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default ARTAccessoriesForm;

import React, { useEffect, useState } from 'react';
import { IARTComponentsFormValues } from 'models/ancillaries';
import { useTranslations } from 'hooks/useTranslations';
import { useForm } from 'react-hook-form';
import { useLang } from 'models/langContext';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { EArtAncillaryType, EArtComponentType } from 'constants/ArtAccessoryType';
import { getFieldsByComponentType } from 'pages/AncillariesPage/helpers';
import Grid from '@mui/material/Grid';
import { StyledSubtitle } from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceAccesoriessSearchForm/styles';
import { Typography } from '@mui/material';
import { FormSelect } from 'components/molecules/FormSelect';
import {
  mapDictionaryToOptionProp,
  mapSpecificDictionaryToOptionProp,
} from 'helpers/dictionary';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { StyledAdvancedSearch } from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceComponentsSearchForm/styles';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { prepareFilters } from 'helpers/filters';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { FormInput } from 'components/molecules/FormInput';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useLocation } from 'react-router-dom';
import { objectToQueryString } from 'helpers/searchQuery';
import { EFirearmType } from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTComponentsForm/helpers';
import { FormAutocomplete } from 'components/molecules/FormAutocomplete';
import { EPerPages } from 'models/table';

export enum ESearchFormLabels {
  componentTypeUniqueId = 'ancillariesReferenceTable.accordion.component.componentType.label',
  manufacturerUniqueId = 'ancillariesReferenceTable.accordion.component.componentManufacturer.label',
  productName = 'ancillariesReferenceTable.accordion.productName.label',
  calibreUniqueId = 'ancillariesReferenceTable.accordion.component.calibre.label',
  length = 'ancillariesReferenceTable.accordion.component.length.label',
  compositionUniqueId = 'ancillariesReferenceTable.accordion.component.composition.label',
  capacityUniqueId = 'ancillariesReferenceTable.accordion.component.capacity.label',
  proofHouseUniqueId = 'ancillariesReferenceTable.accordion.component.proofhouse.label',
  frtType = 'ancillariesReferenceTable.accordion.component.firearmType.label',
  frtMake = 'ancillariesReferenceTable.accordion.component.make.label',
  frtModel = 'ancillariesReferenceTable.accordion.component.model.label',
  frtManufacturer = 'ancillariesReferenceTable.accordion.component.firearmManufacturer.label',
}

export const initialFormValues: IARTComponentsFormValues = {
  componentTypeUniqueId: '',
  manufacturerUniqueId: '',
  productName: '',
  calibreUniqueId: '',
  length: '',
  compositionUniqueId: '',
  capacityUniqueId: '',
  proofHouseUniqueId: '',
  frtId: '',
  frtMake: '',
  frtModel: '',
  frtManufacturer: '',
  frtType: '',
  notes: '',
};

interface IAncillariesReferenceComponentsSearchForm {
  onSubmit: (arg: any) => void;
  initialValues: IARTComponentsFormValues;
  isTransactionPage?: boolean;
  type?: string | null;
  setIsQueryAncillariesReferenceLoading: (arg: any) => void;
  isSearchButtonDisabled?: boolean;
  currentPage?: number;
  perPage?: EPerPages;
}

const AncillariesReferenceComponentsSearchForm = ({
  onSubmit,
  initialValues,
  isTransactionPage,
  type = null,
  isSearchButtonDisabled = false,
  setIsQueryAncillariesReferenceLoading,
  perPage,
  currentPage,
}: IAncillariesReferenceComponentsSearchForm) => {
  const { pathname }: any = useLocation();
  const { t } = useTranslations();
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const [values, setValues] = useState<IARTComponentsFormValues>(initialValues);
  const [isLoadingDictionaries, setIsLoadingDictionaries] = useState<boolean>(false);
  const [isInitialFormLoad, setIsInitialFormLoad] = useState(true);
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IARTComponentsFormValues>({
    defaultValues: initialValues,
  });
  const componentType = watch('componentTypeUniqueId');

  const { selectedLanguage } = useLang();

  const {
    loadDictionaries,
    artComponentType,
    artComponentManufacturer,
    artComponentCalibre,
    artComponentCapacity,
    artComponentProofHouse,
    artAccessoryComposition,
  } = useAncillaries();

  const {
    make,
    model,
    manufacturer,
    loadDictionaries: loadDictionariesFRT,
  } = useLegalEntities();

  useEffect(() => {
    (async () => {
      setIsLoadingDictionaries(true);
      await loadDictionaries([
        EDictionaryTypes.ArtComponentType,
        EDictionaryTypes.ArtComponentManufacturer,
        EDictionaryTypes.ArtComponentCalibre,
        EDictionaryTypes.ArtComponentCapacity,
        EDictionaryTypes.ArtComponentProofHouse,
        EDictionaryTypes.ArtaccessoryComposition,
      ]);
      await loadDictionariesFRT([
        EDictionaryTypes.Make,
        EDictionaryTypes.Model,
        EDictionaryTypes.Manufacturer,
        EDictionaryTypes.FirearmType,
      ]);
      setIsLoadingDictionaries(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as IARTComponentsFormValues);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (!isLoadingDictionaries) {
      setValues(initialValues);
      reset(initialValues);
      handleSubmit(onSubmit)();
    }
  }, [initialValues, isLoadingDictionaries]);

  const componentsTypeChange = (value: EArtComponentType | string) => {
    reset({ ...initialValues, componentTypeUniqueId: value });
    if (value) {
      const fields = getFieldsByComponentType(value as EArtComponentType);
      setAccessFields(fields);
    } else {
      setAccessFields([]);
    }
  };

  useEffect(() => {
    if (initialValues.componentTypeUniqueId) {
      componentsTypeChange(initialValues.componentTypeUniqueId);
    }
  }, [initialValues]);
  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name && name !== 'componentTypeUniqueId') {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialValues);
      componentsTypeChange('');
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  const checkDisabled = (fieldName: string) => !accessFields.includes(fieldName);

  const submitForm = (data: IARTComponentsFormValues) => {
    if (data.invokedByPageChange !== true) {
      setIsQueryAncillariesReferenceLoading(true);
    }
    data.invokedByPageChange = undefined;

    if (isTransactionPage) {
      const query = objectToQueryString(data);
      if (query.length) {
        window.history.replaceState(
          {},
          '',
          `${pathname}?type=${type}&expand=ancillaries&ancillaryOptionName=${EArtAncillaryType.COMPONENTS}&${query}`,
        );
      } else {
        window.history.replaceState(
          {},
          '',
          `${pathname}?type=${type}&expand=ancillaries&ancillaryOptionName=${EArtAncillaryType.COMPONENTS}`,
        );
      }
    } else {
      const query = objectToQueryString({
        ...data,
        perPage,
        currentPage,
      });
      if (query.length) {
        window.history.replaceState(
          {},
          '',
          `${pathname}?expand=ancillaries-reference-table&ancillaryOptionName=${EArtAncillaryType.COMPONENTS}&${query}`,
        );
      } else {
        window.history.replaceState(
          {},
          '',
          `${pathname}?expand=ancillaries-reference-table&ancillaryOptionName=${EArtAncillaryType.COMPONENTS}`,
        );
      }
    }

    onSubmit(data);
  };

  useEffect(() => {
    if (!isInitialFormLoad) {
      handleSubmit((val) => submitForm({ ...val, invokedByPageChange: true }))();
    } else {
      setIsInitialFormLoad(false);
    }
  }, [perPage, currentPage]);

  const getFirearmTypesForComponent = (): EFirearmType[] => {
    switch (componentType) {
      case EArtComponentType.UPPER_RECEIVER:
        return [
          EFirearmType.AIRSOFT,
          EFirearmType.COMBINATION_GUN,
          EFirearmType.MACHINE_GUN,
          EFirearmType.OTHER,
          EFirearmType.RIFLE,
          EFirearmType.SHOTGUN,
          EFirearmType.SUBMACHINE_GUN,
          EFirearmType.GRENADE_LAUNCHER,
          EFirearmType.RECOILLESS_RIFLE,
        ];
      case EArtComponentType.LOWER_RECEIVER:
        return [
          EFirearmType.AIRSOFT,
          EFirearmType.COMBINATION_GUN,
          EFirearmType.MACHINE_GUN,
          EFirearmType.OTHER,
          EFirearmType.RIFLE,
          EFirearmType.SHOTGUN,
          EFirearmType.SUBMACHINE_GUN,
          EFirearmType.ROCKET_LAUNCHER,
          EFirearmType.MANPAD,
          EFirearmType.ANTI_TANK_MISSILE,
          EFirearmType.GRENADE_LAUNCHER,
          EFirearmType.RECOILLESS_RIFLE,
        ];
      case EArtComponentType.FRAME:
        return [
          EFirearmType.AIRSOFT,
          EFirearmType.COMBINATION_GUN,
          EFirearmType.MACHINE_GUN,
          EFirearmType.OTHER,
          EFirearmType.RIFLE,
          EFirearmType.SHOTGUN,
          EFirearmType.SUBMACHINE_GUN,
          EFirearmType.HANDGUN,
          EFirearmType.CANNON,
          EFirearmType.GRENADE_LAUNCHER,
          EFirearmType.RECOILLESS_RIFLE,
          EFirearmType.ROCKET_LAUNCHER,
          EFirearmType.MANPAD,
          EFirearmType.ANTI_TANK_MISSILE,
        ];
      case EArtComponentType.BARREL:
        return [
          EFirearmType.AIRSOFT,
          EFirearmType.CANNON,
          EFirearmType.COMBINATION_GUN,
          EFirearmType.GRENADE_LAUNCHER,
          EFirearmType.MACHINE_GUN,
          EFirearmType.OTHER,
          EFirearmType.RIFLE,
          EFirearmType.SHOTGUN,
          EFirearmType.SUBMACHINE_GUN,
          EFirearmType.RECOILLESS_RIFLE,
          EFirearmType.ROCKET_LAUNCHER,
          EFirearmType.MANPAD,
          EFirearmType.ANTI_TANK_MISSILE,
          EFirearmType.HANDGUN,
        ];
      case EArtComponentType.BOLT:
        return [
          EFirearmType.AIRSOFT,
          EFirearmType.COMBINATION_GUN,
          EFirearmType.MACHINE_GUN,
          EFirearmType.OTHER,
          EFirearmType.RIFLE,
          EFirearmType.SHOTGUN,
          EFirearmType.SUBMACHINE_GUN,
          EFirearmType.HANDGUN,
          EFirearmType.CANNON,
        ];
      case EArtComponentType.SLIDE:
        return [EFirearmType.AIRSOFT, EFirearmType.OTHER, EFirearmType.HANDGUN];
      case EArtComponentType.CYLINDER:
        return [
          EFirearmType.GRENADE_LAUNCHER,
          EFirearmType.OTHER,
          EFirearmType.HANDGUN,
          EFirearmType.AIRSOFT,
        ];
      default:
        return Object.values(EFirearmType);
    }
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(submitForm)}>
        <StyledAdvancedSearch container spacing={2} columns={10}>
          <Grid item xs={12}>
            <StyledSubtitle item xs={3}>
              <Typography variant="subtitle2">
                {t('ancillaries.accordion.subtitle.label')}
              </Typography>
            </StyledSubtitle>
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(artComponentType)}
              name="componentTypeUniqueId"
              label={t(ESearchFormLabels.componentTypeUniqueId)}
              setValue={(name: string, value: string) => {
                // @ts-ignore
                setValue(name, value);
                componentsTypeChange(value as EArtComponentType | '');
              }}
              canBeEmpty
              control={control}
              errors={errors}
              onSelect={componentsTypeChange}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(artComponentManufacturer)}
              name="manufacturerUniqueId"
              label={t(ESearchFormLabels.manufacturerUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('manufacturerUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormInput
              name="productName"
              label={t(ESearchFormLabels.productName)}
              control={control}
              errors={errors}
              disabled={checkDisabled('productName')}
              setValue={setValue}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(artComponentCalibre)}
              name="calibreUniqueId"
              label={t(ESearchFormLabels.calibreUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('calibreUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormInput
              name="length"
              label={t(ESearchFormLabels.length)}
              control={control}
              errors={errors}
              disabled={checkDisabled('length')}
              setValue={setValue}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAccessoryComposition)}
              name="compositionUniqueId"
              label={t(ESearchFormLabels.compositionUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('compositionUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(artComponentCapacity)}
              name="capacityUniqueId"
              label={t(ESearchFormLabels.capacityUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('capacityUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(artComponentProofHouse)}
              name="proofHouseUniqueId"
              label={t(ESearchFormLabels.proofHouseUniqueId)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
              disabled={checkDisabled('proofHouseUniqueId')}
            />
          </Grid>

          <Grid item xs={12}>
            <StyledSubtitle item xs={3}>
              <Typography variant="subtitle2">Firearm filters</Typography>
            </StyledSubtitle>
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormSelect
              options={mapSpecificDictionaryToOptionProp(getFirearmTypesForComponent())}
              name="frtType"
              label={t(ESearchFormLabels.frtType)}
              setValue={setValue}
              canBeEmpty
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormAutocomplete
              name="frtMake"
              options={mapSpecificDictionaryToOptionProp(make)}
              label={t(ESearchFormLabels.frtMake)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormAutocomplete
              name="frtModel"
              options={mapSpecificDictionaryToOptionProp(model)}
              label={t(ESearchFormLabels.frtModel)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormAutocomplete
              name="frtManufacturer"
              options={mapSpecificDictionaryToOptionProp(manufacturer)}
              label={t(ESearchFormLabels.frtManufacturer)}
              control={control}
              errors={errors}
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            columns={10}
            columnSpacing={2}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Grid item xs={10} sm={10} md={5} lg={2}>
              <Button
                variant={EButtonVariants.contained}
                size={EButtonSizes.small}
                label={t('ancillaries.accordion.search.button')}
                disabled={isSearchButtonDisabled}
                type="submit"
                id="ancillary-reference-table-component-search"
                fullWidth
                sx={{
                  mt: {
                    sm: 3.5,
                  },
                }}
              />
            </Grid>
          </Grid>
        </StyledAdvancedSearch>
      </form>
      <SelectedFilters
        name="ancillaries_reference_components"
        values={values}
        labels={ESearchFormLabels}
        handleDelete={handleResetField}
        type={ESearchFilterKeys.firearm}
        showSaveFilters={false}
        saveQueryParams={['type', 'ancillaryOptionName']}
        dictionaries={{
          componentTypeUniqueId: prepareFilters(artComponentType, 'uniqueId', 'name'),
          manufacturerUniqueId: prepareFilters(artComponentManufacturer, 'uniqueId', 'name'),
          calibreUniqueId: prepareFilters(artComponentCalibre, 'uniqueId', 'name'),
          compositionUniqueId: prepareFilters(artAccessoryComposition, 'uniqueId', 'name'),
          capacityUniqueId: prepareFilters(artComponentCapacity, 'uniqueId', 'name'),
          proofHouseUniqueId: prepareFilters(artComponentProofHouse, 'uniqueId', 'name'),
        }}
        saveRouterStateAfterClearAll={false}
      />
    </SearchWrapper>
  );
};

export default AncillariesReferenceComponentsSearchForm;

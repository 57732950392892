import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { useForm } from 'react-hook-form';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { FormInput } from 'components/molecules/FormInput';
import { Typography } from '@mui/material';
import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { useLocation } from 'react-router-dom';
import { prepareFilters } from 'helpers/filters';
import { useTranslations } from 'hooks/useTranslations';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { useLang } from 'models/langContext';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import {
  filterHostTypeByAccessoryType,
  getFieldsByAccessoriesType,
} from 'pages/AncillariesPage/helpers';
import { EArtAccessoryType, EArtAncillaryType } from 'constants/ArtAccessoryType';
import { objectToQueryString } from 'helpers/searchQuery';
import { EPerPages } from 'models/table';
import { StyledAdvancedSearch, StyledSubtitle } from './styles';

export interface ISearchFormValues {
  accessoryTypeUniqueId: string;
  manufacturerUniqueId: string;
  productName: string;
  hostTypeUniqueId: string;
  calibreUniqueId: string;
  magnificationUniqueId: string;
  utilityUniqueId: string;
  rectileUniqueId: string;
  lightColourUniqueId: string;
  compositionUniqueId: string;
  capacity: string;
  proofHouseUniqueId: string;

  [key: string]: any;
}

export enum ESearchFormLabels {
  accessoryTypeUniqueId = 'ancillariesReferenceTable.accordion.accessoryType.label',
  manufacturerUniqueId = 'ancillariesReferenceTable.accordion.accessoryManufacturer.label',
  productName = 'ancillariesReferenceTable.accordion.productName.label',
  hostTypeUniqueId = 'ancillariesReferenceTable.accordion.hostType.label',
  calibreUniqueId = 'ancillariesReferenceTable.accordion.calibre.label',
  magnificationUniqueId = 'ancillariesReferenceTable.accordion.magnification.label',
  utilityUniqueId = 'ancillariesReferenceTable.accordion.utility.label',
  rectileUniqueId = 'ancillariesReferenceTable.accordion.reticle.label',
  lightColourUniqueId = 'ancillariesReferenceTable.accordion.lightColour.label',
  compositionUniqueId = 'ancillariesReferenceTable.accordion.composition.label',
  capacity = 'ancillariesReferenceTable.accordion.capacity.label',
  proofHouseUniqueId = 'ancillariesReferenceTable.accordion.proofMark.label',
}

export const initialFormValues: ISearchFormValues = {
  accessoryTypeUniqueId: '',
  manufacturerUniqueId: '',
  productName: '',
  hostTypeUniqueId: '',
  calibreUniqueId: '',
  magnificationUniqueId: '',
  utilityUniqueId: '',
  rectileUniqueId: '',
  lightColourUniqueId: '',
  compositionUniqueId: '',
  capacity: '',
  proofHouseUniqueId: '',
};

interface ISearchForm {
  onSubmit: (arg: any) => void;
  initialValues: ISearchFormValues;
  isTransactionPage?: boolean;
  type?: string | null;
  setIsQueryAncillariesReferenceLoading: (arg: any) => void;
  isSearchButtonDisabled?: boolean;
  currentPage?: number;
  perPage?: EPerPages;
}

const AncillariesReferenceAccesoriessSearchForm = ({
  onSubmit,
  initialValues,
  isTransactionPage,
  type = null,
  isSearchButtonDisabled = false,
  setIsQueryAncillariesReferenceLoading,
  perPage,
  currentPage,
}: ISearchForm) => {
  const [values, setValues] = useState<ISearchFormValues>(initialValues);
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const [accessoryManufacturer, setAccessoryManufacturer] = useState<IDictionary[] | null>(
    null,
  );
  const [accessoryHostType, setAccessoryHostType] = useState<IDictionary[] | null>(null);
  const [isLoadingDictionaries, setIsLoadingDictionaries] = useState<boolean>(false);
  const [isInitialFormLoad, setIsInitialFormLoad] = useState(true);
  const { state: routerState, pathname }: any = useLocation();
  const { t } = useTranslations();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<ISearchFormValues>({
    defaultValues: initialValues,
  });

  const { selectedLanguage } = useLang();

  const {
    loadDictionaries,
    artAccessoryCalibre,
    artAccessoryComposition,
    artAccessoryHostType,
    artAccessoryLightColour,
    artAccessoryMagnification,
    artAccessoryReticle,
    artAccessoryType,
    artAccessoryUtility,
    artAccessoryProofHouse,
    artAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
  } = useAncillaries();

  const submitForm = (data: ISearchFormValues) => {
    if (data.invokedByPageChange !== true) {
      setIsQueryAncillariesReferenceLoading(true);
    }
    data.invokedByPageChange = undefined;

    if (isTransactionPage) {
      const query = objectToQueryString(data);
      if (query.length) {
        window.history.replaceState(
          {},
          '',
          `${pathname}?type=${type}&expand=ancillaries&ancillaryOptionName=${EArtAncillaryType.ACCESSORIES}&${query}`,
        );
      } else {
        window.history.replaceState(
          {},
          '',
          `${pathname}?type=${type}&expand=ancillaries&ancillaryOptionName=${EArtAncillaryType.ACCESSORIES}`,
        );
      }
    } else {
      const query = objectToQueryString({
        ...data,
        perPage,
        currentPage,
      });
      if (query.length) {
        window.history.replaceState(
          {},
          '',
          `${pathname}?expand=ancillaries-reference-table&ancillaryOptionName=${EArtAncillaryType.ACCESSORIES}&${query}`,
        );
      } else {
        window.history.replaceState(
          {},
          '',
          `${pathname}?expand=ancillaries-reference-table&ancillaryOptionName=${EArtAncillaryType.ACCESSORIES}`,
        );
      }
    }
    onSubmit(data);
  };

  useEffect(() => {
    if (!isInitialFormLoad) {
      handleSubmit((val) => submitForm({ ...val, invokedByPageChange: true }))();
    } else {
      setIsInitialFormLoad(false);
    }
  }, [perPage, currentPage]);

  const setLegalEntity = () => {
    const formValues = {
      ...initialValues,
      ...routerState?.formValues,
    };

    setValues(formValues);
    reset(formValues);
  };

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchFormValues);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (!isLoadingDictionaries) {
      setValues(initialValues);
      reset(initialValues);
      handleSubmit(onSubmit)();
    }
  }, [initialValues, isLoadingDictionaries]);

  useEffect(() => {
    (async function initDictionaries() {
      setIsLoadingDictionaries(true);
      await loadDictionaries([
        EDictionaryTypes.ArtAccessoryType,
        EDictionaryTypes.ArtAccessoryCalibre,
        EDictionaryTypes.ArtAccessoryHostType,
        EDictionaryTypes.ArtaccessoryComposition,
        EDictionaryTypes.ArtAccessoryMagnification,
        EDictionaryTypes.ArtAccessoryUtility,
        EDictionaryTypes.ArtAccessoryReticle,
        EDictionaryTypes.ArtAccessoryLightColour,
        EDictionaryTypes.ArtAccessoryProofHouse,
        EDictionaryTypes.ArtAccessoryManufacturerFlashEliminator,
        EDictionaryTypes.ArtAccessoryManufacturerLaserLightModule,
        EDictionaryTypes.ArtAccessoryManufacturerMagazine,
        EDictionaryTypes.ArtAccessoryManufacturerOpticalSight,
        EDictionaryTypes.ArtAccessoryManufacturerSuppressor,
      ]);
      setIsLoadingDictionaries(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    (async function init() {
      if (routerState?.formValues) {
        const formValues = {
          ...initialValues,
          ...routerState.formValues,
          legalityUniqueId: routerState?.legalityUniqueId,
        };
        setValues(formValues);
        reset(formValues);
      }
      if (routerState?.legalEntity) {
        setLegalEntity();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAccessoryManufacturerDictionaryData = (accessoriesTypeId: EArtAccessoryType) => {
    switch (accessoriesTypeId) {
      case EArtAccessoryType.MAGAZINES:
        setAccessoryManufacturer(artAccessoryManufacturerMagazine);
        break;
      case EArtAccessoryType.LASER_LIGHT_MODULES:
        setAccessoryManufacturer(artAccessoryManufacturerLaserLightModule);
        break;
      case EArtAccessoryType.OPTICAL_SIGHTS:
        setAccessoryManufacturer(artAccessoryManufacturerOpticalSight);
        break;
      case EArtAccessoryType.FLASH_ELIMINATORS:
        setAccessoryManufacturer(artAccessoryManufacturerFlashEliminator);
        break;
      case EArtAccessoryType.SUPPRESSORS:
        setAccessoryManufacturer(artAccessoryManufacturerSuppressor);
        break;
      default:
        setAccessoryManufacturer(null);
        break;
    }
  };

  const accessoryTypeChange = (value: any) => {
    reset({ ...initialValues, accessoryTypeUniqueId: value });
    if (value) {
      const fields = getFieldsByAccessoriesType(value);
      setAccessFields(fields);
      setAccessoryManufacturerDictionaryData(value);
      setAccessoryHostType(
        filterHostTypeByAccessoryType(value as EArtAccessoryType, artAccessoryHostType),
      );
    } else {
      setAccessFields([]);
      setAccessoryManufacturer(null);
      setAccessoryHostType(null);
    }
  };

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name && name !== 'accessoryTypeUniqueId') {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialValues);
      accessoryTypeChange('');
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  useEffect(() => {
    if (initialValues.accessoryTypeUniqueId) {
      accessoryTypeChange(initialValues.accessoryTypeUniqueId);
    }
  }, [initialValues]);

  const checkDisabled = (fieldName: string) => !accessFields.includes(fieldName);

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(submitForm)}>
        <StyledAdvancedSearch container spacing={2} columns={10}>
          <Grid item xs={12}>
            <StyledSubtitle item lg={2.5} md={3} sm={5} xs={12}>
              <Typography variant="subtitle2">
                {t('ancillaries.accordion.subtilte.label')}
              </Typography>
            </StyledSubtitle>
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAccessoryType)}
              name="accessoryTypeUniqueId"
              label={t(ESearchFormLabels.accessoryTypeUniqueId)}
              setValue={(name: string, value: string) => {
                setValue(name, value);
                accessoryTypeChange(value);
              }}
              canBeEmpty
              control={control}
              errors={errors}
              onSelect={accessoryTypeChange}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              name="manufacturerUniqueId"
              options={mapDictionaryToOptionProp(accessoryManufacturer)}
              label={t(ESearchFormLabels.manufacturerUniqueId)}
              control={control}
              errors={errors}
              disabled={checkDisabled('manufacturerUniqueId')}
              setValue={setValue}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormInput
              name="productName"
              label={t(ESearchFormLabels.productName)}
              control={control}
              errors={errors}
              disabled={checkDisabled('productName')}
              setValue={setValue}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAccessoryCalibre)}
              name="calibreUniqueId"
              label={t(ESearchFormLabels.calibreUniqueId)}
              control={control}
              errors={errors}
              setValue={setValue}
              disabled={checkDisabled('calibreUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(accessoryHostType)}
              name="hostTypeUniqueId"
              label={t(ESearchFormLabels.hostTypeUniqueId)}
              canBeEmpty
              control={control}
              errors={errors}
              setValue={setValue}
              disabled={checkDisabled('hostTypeUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAccessoryComposition)}
              name="compositionUniqueId"
              label={t(ESearchFormLabels.compositionUniqueId)}
              canBeEmpty
              control={control}
              errors={errors}
              setValue={setValue}
              disabled={checkDisabled('compositionUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAccessoryMagnification)}
              name="magnificationUniqueId"
              label={t(ESearchFormLabels.magnificationUniqueId)}
              canBeEmpty
              control={control}
              errors={errors}
              setValue={setValue}
              disabled={checkDisabled('magnificationUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAccessoryUtility)}
              name="utilityUniqueId"
              label={t(ESearchFormLabels.utilityUniqueId)}
              canBeEmpty
              control={control}
              errors={errors}
              setValue={setValue}
              disabled={checkDisabled('utilityUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormInput
              name="capacity"
              label={t(ESearchFormLabels.capacity)}
              control={control}
              errors={errors}
              disabled={checkDisabled('capacity')}
              setValue={setValue}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAccessoryReticle)}
              name="rectileUniqueId"
              label={t(ESearchFormLabels.rectileUniqueId)}
              canBeEmpty
              control={control}
              errors={errors}
              setValue={setValue}
              disabled={checkDisabled('rectileUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAccessoryLightColour)}
              name="lightColourUniqueId"
              label={t(ESearchFormLabels.lightColourUniqueId)}
              canBeEmpty
              control={control}
              errors={errors}
              setValue={setValue}
              disabled={checkDisabled('lightColourUniqueId')}
            />
          </Grid>
          <Grid item lg={2.5} md={3} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAccessoryProofHouse)}
              name="proofHouseUniqueId"
              label={t(ESearchFormLabels.proofHouseUniqueId)}
              canBeEmpty
              control={control}
              errors={errors}
              setValue={setValue}
              disabled={checkDisabled('proofHouseUniqueId')}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            columns={10}
            columnSpacing={2}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Grid item xs={10} sm={10} md={5} lg={2}>
              <Button
                variant={EButtonVariants.contained}
                size={EButtonSizes.small}
                disabled={isSearchButtonDisabled}
                label={t('ancillaries.accordion.search.button')}
                type="submit"
                id="ancillary-reference-table-accessory-search"
                fullWidth
                sx={{
                  mt: {
                    sm: 3.5,
                  },
                }}
              />
            </Grid>
          </Grid>
        </StyledAdvancedSearch>
      </form>
      <SelectedFilters
        name="ancillaries_reference_accesories"
        values={values}
        labels={ESearchFormLabels}
        handleDelete={handleResetField}
        type={ESearchFilterKeys.ancillaries}
        showSaveFilters={false}
        saveQueryParams={['type', 'ancillaryOptionName']}
        dictionaries={{
          accessoryTypeUniqueId: prepareFilters(artAccessoryType, 'uniqueId', 'name'),
          manufacturerUniqueId: prepareFilters(accessoryManufacturer, 'uniqueId', 'name'),
          calibreUniqueId: prepareFilters(artAccessoryCalibre, 'uniqueId', 'name'),
          hostTypeUniqueId: prepareFilters(artAccessoryHostType, 'uniqueId', 'name'),
          compositionUniqueId: prepareFilters(artAccessoryComposition, 'uniqueId', 'name'),
          magnificationUniqueId: prepareFilters(artAccessoryMagnification, 'uniqueId', 'name'),
          utilityUniqueId: prepareFilters(artAccessoryUtility, 'uniqueId', 'name'),
          rectileUniqueId: prepareFilters(artAccessoryReticle, 'uniqueId', 'name'),
          lightColourUniqueId: prepareFilters(artAccessoryLightColour, 'uniqueId', 'name'),
          proofHouseUniqueId: prepareFilters(artAccessoryProofHouse, 'uniqueId', 'name'),
        }}
        saveRouterStateAfterClearAll={false}
      />
    </SearchWrapper>
  );
};

export { AncillariesReferenceAccesoriessSearchForm };

import { Navigate, Route, Routes } from 'react-router-dom';
import React, { ReactElement, useEffect, useState } from 'react';
import { ERouteLinks, IPageBuilderConfig } from 'models/route';
import { PageComponentTypes } from 'constants/PageComponentTypes';
import { AuthPage } from 'pages/AuthPage';
import { FC_SYSTEM_AUTH_ACCESS_TOKEN } from 'requests/auth';
import { usePermission } from 'hooks/usePermission';
import { useDrawerClicked } from 'state/contexts/drawer';

interface IPageBuilder {
  config: IPageBuilderConfig[];
}

const PageTemplate = ({ config }: IPageBuilder) => {
  const { hasPermissions } = usePermission();
  const { drawerClicked, setDrawerClicked } = useDrawerClicked();
  const [key, setKey] = useState<number>(1);

  useEffect(() => {
    if (drawerClicked) {
      setKey((prevState) => prevState + 1);
      setDrawerClicked(false);
    }
  }, [drawerClicked]);

  const redirectRoute = (route: IPageBuilderConfig) => {
    const { isProtected, componentType, permissions, permissionOrCondition } = route;
    const accessToken = localStorage.getItem(FC_SYSTEM_AUTH_ACCESS_TOKEN);
    const Component = PageComponentTypes[componentType];

    if (permissions && hasPermissions(permissions, permissionOrCondition) === false) {
      return <Navigate to={ERouteLinks.AccessDeniedPage} />;
    }

    return isProtected && !accessToken ? <AuthPage /> : <Component key={key} />;
  };
  const renderRoutes = () =>
    config.map(
      (route: IPageBuilderConfig): ReactElement => (
        <Route key={route.componentType} path={route.route} element={redirectRoute(route)}>
          {route.nestedRoutes?.map(
            (nestedRoute: IPageBuilderConfig): ReactElement => (
              <Route
                key={nestedRoute.componentType}
                path={nestedRoute.route}
                element={redirectRoute(route)}
              />
            ),
          )}
        </Route>
      ),
    );
  document.addEventListener(
    'itemInserted',
    () => {
      renderRoutes();
    },
    false,
  );

  return <Routes>{renderRoutes()}</Routes>;
};

export { PageTemplate };

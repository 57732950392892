import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import Grid from '@mui/material/Grid';
import {
  ResponsiveImage,
  StyledTypography,
  StyledValueFormHeader,
} from 'pages/SystemSettingsPage/styles';
import { FormRadioGroup } from 'components/molecules/FormRadioGroup';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import LogosImage from 'assets/images/logos_header.jpg';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import UploadDropzoneField from 'components/atoms/UploadDropzoneField';
import Button from 'components/atoms/Button';
import { EIconTypes } from 'constants/Icons';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { useLogoSetup } from 'pages/SystemSettingsPage/LogoSetupPage/hooks';
import { ELoginPartTypes, ILoginScreenItem } from 'models/loginScreenSetup';
import { diffObjects } from 'helpers/objects';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useNavigate } from 'react-router-dom';
import FormErrorLabel from 'components/atoms/FormErrorLabel';

enum EYesNo {
  YES = 'YES',
  NO = 'NO',
}

type FileFieldName = 'uploadedFile1' | 'uploadedFile2' | 'uploadedFile3';

export interface ILogoSetupFormValues {
  displayHeader: string;
  partnershipLogos: string;
  header: string;
  subheader: string;
  uploadedFile1: string;
  uploadedFile2: string;
  uploadedFile3: string;
}

const initialFormValues: ILogoSetupFormValues = {
  displayHeader: EYesNo.NO,
  partnershipLogos: EYesNo.NO,
  header: '',
  subheader: '',
  uploadedFile1: '',
  uploadedFile2: '',
  uploadedFile3: '',
};

const acceptFiles = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg', '.jpg'],
};

let parsedValues = { ...initialFormValues };

const parseLogoSetupData = (data: ILoginScreenItem[]) => {
  const values = { ...initialFormValues };

  data.map((item) => {
    switch (item.type) {
      case ELoginPartTypes.HEADER:
      case ELoginPartTypes.SUBHEADER:
        if (item.text) {
          if (item.type === ELoginPartTypes.HEADER) {
            values.header = item.text;
          } else {
            values.subheader = item.text;
          }

          values.displayHeader = EYesNo.YES;
        }
        break;

      case ELoginPartTypes.LOGO_1:
      case ELoginPartTypes.LOGO_2:
      case ELoginPartTypes.LOGO_3:
        if (item.type === ELoginPartTypes.LOGO_1 && item.file?.fileName) {
          values.uploadedFile1 = item.file.fileName;
          values.partnershipLogos = EYesNo.YES;
        } else if (item.type === ELoginPartTypes.LOGO_2 && item.file?.fileName) {
          values.uploadedFile2 = item.file.fileName;
          values.partnershipLogos = EYesNo.YES;
        } else if (item.type === ELoginPartTypes.LOGO_3 && item.file?.fileName) {
          values.uploadedFile3 = item.file.fileName;
          values.partnershipLogos = EYesNo.YES;
        }

        break;

      default:
        break;
    }
    return false;
  });

  return values;
};

const LogoSetupPage = () => {
  const { t } = useTranslations();
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const navigate = useNavigate();
  const [clearFilesOne, setClearFilesOne] = useState(false);
  const [clearFilesTwo, setClearFilesTwo] = useState(false);
  const [clearFilesThree, setClearFilesThree] = useState(false);
  const [attachments, setAttachments] = useState<{ [key: string]: File | null }>({
    '1': null,
    '2': null,
    '3': null,
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const { updateLoginPage, getLoginScreen, loginScreenData } = useLogoSetup();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    setError,
    getValues,
    formState: { errors },
  } = useForm<ILogoSetupFormValues>({
    defaultValues: initialFormValues,
  });

  const watchDisplayHeader = watch('displayHeader');
  const watchPartnershipLogos = watch('partnershipLogos');

  useEffect(() => {
    (async () => {
      await getLoginScreen(true);
    })();
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (loginScreenData) {
      const values = parseLogoSetupData(loginScreenData);
      parsedValues = values;
      reset(values);
    }
  }, [loginScreenData]);

  useEffect(() => {
    if (watchDisplayHeader === EYesNo.NO) {
      reset({ ...getValues(), header: '', subheader: '' });
    }
  }, [watchDisplayHeader]);

  useEffect(() => {
    if (watchPartnershipLogos === EYesNo.NO) {
      setClearFilesOne(true);
      setClearFilesTwo(true);
      setClearFilesThree(true);
      setAttachments({
        '1': null,
        '2': null,
        '3': null,
      });
      setValue('uploadedFile1', '');
      setValue('uploadedFile2', '');
      setValue('uploadedFile3', '');
    }
  }, [watchPartnershipLogos]);

  const formChanged = (
    initialValues?: ILogoSetupFormValues | null,
    currentValues?: ILogoSetupFormValues | null,
  ) =>
    initialValues?.header !== currentValues?.header ||
    initialValues?.subheader !== currentValues?.subheader ||
    initialValues?.uploadedFile1 !== currentValues?.uploadedFile1 ||
    initialValues?.uploadedFile2 !== currentValues?.uploadedFile2 ||
    initialValues?.uploadedFile3 !== currentValues?.uploadedFile3;

  const submitHandler = async (data: ILogoSetupFormValues) => {
    if (!formChanged(parsedValues, data)) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('general.noChanged.warning.message'),
        variant: EAlertVariants.warning,
      });
      return;
    }
    setIsSubmitLoading(true);
    clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    const errorArray = await updateLoginPage(diffObjects(parsedValues, data), attachments);

    if (errorArray.length > 0) {
      errorArray.forEach((errorField) =>
        setError(errorField as FileFieldName, {
          message: t('LoginScreenSetupFileException'),
          type: 'custom',
        }),
      );
    } else {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('logosSetup.success.message'),
      });
    }

    setIsSubmitLoading(false);
    parsedValues = data;
  };

  const clearAttachment = (index: number) => {
    switch (index) {
      case 1:
        setClearFilesOne(true);
        setAttachments({ ...attachments, '1': null });
        setValue('uploadedFile1', '');
        break;

      case 2:
        setClearFilesTwo(true);
        setAttachments({ ...attachments, '2': null });
        setValue('uploadedFile2', '');
        break;

      case 3:
        setClearFilesThree(true);
        setAttachments({ ...attachments, '3': null });
        setValue('uploadedFile3', '');
        break;

      default:
        break;
    }
  };

  const getClearFilesFunc = (index: number) => {
    switch (index) {
      case 1:
        return clearFilesOne;
      case 2:
        return clearFilesTwo;
      case 3:
      default:
        return clearFilesThree;
    }
  };

  const getSetClearFilesFunc = (index: number) => {
    switch (index) {
      case 1:
        return setClearFilesOne;
      case 2:
        return setClearFilesTwo;
      case 3:
      default:
        return setClearFilesThree;
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          { label: t('systemSettings.header'), route: ERouteLinks.SystemSettings },
          t('logosSetup.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('logosSetup.header')}</StyledPageTitle>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container columnSpacing={1}>
          <Grid item xs={12} md={6}>
            <Grid item xs={12}>
              <StyledValueFormHeader>
                {t('logosSetup.headerSubheader.header')}
              </StyledValueFormHeader>
              <Box m={1}>
                <FormRadioGroup
                  name="displayHeader"
                  label={t('logosSetup.displayHeader.label')}
                  options={[
                    {
                      label: t('general.confirmation.no.button'),
                      value: EYesNo.NO,
                    },
                    { label: t('general.confirmation.yes.button'), value: EYesNo.YES },
                  ]}
                  control={control}
                  errors={errors}
                />

                {watchDisplayHeader === EYesNo.YES && (
                  <>
                    <FormInput
                      name="header"
                      type={EFormInputTypes.textarea}
                      label={`${t('logosSetup.header.label')}`}
                      setValue={setValue}
                      control={control}
                      errors={errors}
                      multiline
                      minRows={4}
                      charsLimit={100}
                      showCounter={true}
                    />

                    <FormInput
                      name="subheader"
                      type={EFormInputTypes.text}
                      label={`${t('logosSetup.subheader.label')}`}
                      setValue={setValue}
                      control={control}
                      errors={errors}
                      charsLimit={50}
                      showCounter={true}
                    />
                  </>
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <StyledValueFormHeader>
                {t('logosSetup.partnershipLogos.header')}
              </StyledValueFormHeader>
              <Box m={1}>
                <FormRadioGroup
                  name="partnershipLogos"
                  label={t('logosSetup.partnershipLogos.label')}
                  options={[
                    {
                      label: t('general.confirmation.no.button'),
                      value: EYesNo.NO,
                    },
                    { label: t('general.confirmation.yes.button'), value: EYesNo.YES },
                  ]}
                  control={control}
                  errors={errors}
                />
              </Box>
            </Grid>

            {watchPartnershipLogos === EYesNo.YES && (
              <>
                {[1, 2, 3].map((index) => (
                  <Grid item container rowSpacing={2} lg={12} key={index}>
                    <Grid item container rowSpacing={0} columnSpacing={2} xs={12}>
                      <Grid item xs={12}>
                        <StyledTypography variant="subtitle2">
                          {t('logosSetup.partnershipLogo.label')} {index}
                        </StyledTypography>
                        <UploadDropzoneField
                          acceptFiles={acceptFiles}
                          onUpload={(files: any) => {
                            // @ts-ignore
                            setValue(`uploadedFile${index}`, files[0].name);
                            setAttachments({ ...attachments, [index]: files[0] });
                          }}
                          description={
                            <p>
                              {t('logosSetup.dragDrop.title')} <br />
                              {t('logosSetup.clickBrowse.title')}
                            </p>
                          }
                          shouldClearFiles={getClearFilesFunc(index)}
                          setFilesCleared={getSetClearFilesFunc(index)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      columnSpacing={2}
                      xs={12}
                      sx={{ alignItems: 'center' }}
                    >
                      <Grid item xs="auto" sx={{ width: 'calc(100% - 48px) !important' }}>
                        <FormInput
                          name={`uploadedFile${index}`}
                          label={t('licenceReplace.uploadedFile.title')}
                          control={control}
                          errors={errors}
                          disabled
                        />
                        {errors &&
                          errors[`uploadedFile${index}` as FileFieldName] &&
                          errors[`uploadedFile${index}` as FileFieldName]?.type ===
                            'custom' && (
                            <Box sx={{ marginTop: '-24px' }}>
                              <FormErrorLabel
                                label={
                                  errors[`uploadedFile${index}` as FileFieldName]
                                    ?.message as string
                                }
                              />
                            </Box>
                          )}
                      </Grid>
                      <Grid item xs="auto" sx={{ marginTop: '0px' }}>
                        <Button
                          icon={EIconTypes.delete}
                          variant={EButtonVariants.outlined}
                          sizePX={EButtonSizesPX.px32}
                          onClick={() => clearAttachment(index)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </>
            )}

            {samePageAlert && (
              <Box mt={2}>
                <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledValueFormHeader>{t('logosSetup.placement.header')}</StyledValueFormHeader>
            <ResponsiveImage src={LogosImage} alt="Placement" />
          </Grid>
          <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
            <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  type="submit"
                  fullWidth
                  label={t('general.save.button')}
                  id="save-button"
                  variant={EButtonVariants.contained}
                  isLoading={isSubmitLoading}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  fullWidth
                  label={t('general.cancel.button')}
                  id="cancel-button"
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(ERouteLinks.SystemSettings)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default LogoSetupPage;

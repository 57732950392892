import { IPaginator } from 'models/paginator';

export interface IAuditLog {
  uniqueId: string;
  moduleUniqueId: string;
  module: string;
  eventTypeUniqueId: string;
  eventType: string;
  eventNameUniqueId: string;
  eventName: string;
  objectUniqueId: string;
  eventDate: string;
  authorUniqueId: string;
  authorFirstName: string;
  authorLastName: string;
  accountRoles?: string[];
}

export interface IAuditLogResponse extends IPaginator {
  content: IAuditLog[];
}

export interface IAuditLogSearchFormValues {
  accountUniqueId: string;
  moduleUniqueId: string;
  eventTypeUniqueId: string;
  eventDateFrom: string | Date;
  eventDateTo: string | Date;
}

export const initialAuditLogSearchValues: IAuditLogSearchFormValues = {
  accountUniqueId: '',
  moduleUniqueId: '',
  eventTypeUniqueId: '',
  eventDateFrom: '',
  eventDateTo: '',
};

export interface IAuditLogItemResponse {
  uniqueId: string;
  author: {
    uniqueId: string;
    email: string;
    phoneNumber: {
      codeDictionaryUniqueId: string;
      value: string;
    };
    firstName: string;
    lastName: string;
  };
  accountRoles?: string[];
  moduleUniqueId: string;
  module: string;
  eventTypeUniqueId: string;
  eventType: string;
  eventNameUniqueId: string;
  eventName: string;
  objectType: string;
  objectUniqueId: string;
  previousObjectValue: object | null;
  eventDate: string | null;
}

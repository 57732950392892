import React, { useEffect, useRef, useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { TransactionSearchForm } from 'pages/TransactionsPage/TransactionSearchForm';
import { ISearchFormValues } from 'models/transaction';
import { useTransactions } from 'pages/TransactionsPage/hooks';
import { EPerPages, ISortOptions, ITableDataSource } from 'models/table';
import { Loader } from 'components/atoms/Loader';
import { Table } from 'components/molecules/Table';
import {
  mapTransactionsToDataSource,
  transactionsTableColumns,
} from 'pages/TransactionsPage/helpers';
import { Pagination } from 'components/molecules/Pagination';
import { formatDateToRequest } from 'utils/date';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { CardList } from 'components/molecules/CardList';
import { TransactionItemCard } from 'pages/TransactionsPage/TransactionItemCard';
import { ERouteLinks } from 'models/route';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useDictionary } from 'models/dictionaryContext';
import { useLang } from 'models/langContext';
import {
  renderDataColor,
  renderRowColor,
  renderRowColorHover,
} from 'components/molecules/Table/helpers';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { objectToQueryString, prepareQueryValues } from 'helpers/searchQuery';

export interface ISearchParams {
  [key: string]: string | number;
}

const initialFormValues: ISearchFormValues = {
  preset: '',
  transactionTypeUniqueId: '',
  transactionTimeFrom: '',
  transactionTimeTo: '',
  createTimeFrom: '',
  createTimeTo: '',
  legalityUniqueId: '',
  expirationTimeFrom: '',
  expirationTimeTo: '',
  ownerUniqueId: '',
  keeperUniqueId: '',
  ownerName: '',
  keeperName: '',
  asset: undefined,
};

const TransactionsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { getLegality, getActivity } = useDictionary();
  const { selectedLanguage } = useLang();
  const { isDesktop } = useThemeBreakpoint();
  const { pathname }: any = useLocation();
  const { getTransactionsData, transactions, paginator } = useTransactions();
  const [queryTransactions, setQueryTransactions] = useState<Partial<ISearchFormValues>>({});
  const [isQueryTransactionsLoading, setIsQueryTransactionsLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const perPageFromQuery = Number.parseInt(searchParams.get('perPage') || '25', 10);
  const currentPageFromQuery = Number.parseInt(searchParams.get('currentPage') || '1', 10);
  const [currentPage, setCurrentPage] = useState<number>(
    currentPageFromQuery > 0 ? currentPageFromQuery : 1,
  );
  const [perPage, setPerPage] = useState<EPerPages>(
    [25, 50, 100].includes(perPageFromQuery)
      ? (perPageFromQuery as EPerPages)
      : EPerPages.perPage25,
  );
  const [sort, setSort] = useState<ISortOptions | null>(null);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState<boolean | null>(null);
  const [isSortLoaded, setIsSortLoaded] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDataLoaded, setIsDataLoaded] = useState<number>(0);
  const [initialValues, setInitialValues] = useState<ISearchFormValues>(initialFormValues);
  const [isSearchParams, setIsSearchParams] = useState<boolean>(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { midDateFormat, getDateFormat } = useGlobalProperty();

  const scrollPosition = useRef(0);

  useEffect(() => {
    getDateFormat();
  }, []);

  const previousSortRef = useRef(sort);

  useEffect(() => {
    (async function getTransactionDataInit() {
      if (Object.keys(queryTransactions).length > 0) {
        if (!transactions.length) {
          setIsTransactionsLoading(true);
        }

        if (sort !== previousSortRef.current) {
          setIsSortLoaded(false);
        }
        setIsPaginationLoading(true);
        await getTransactionsData(currentPage, perPage, queryTransactions, sort);
        setIsTransactionsLoading(false);
        setIsQueryTransactionsLoading(false);
        setIsPaginationLoading(false);
        setIsSortLoaded(true);
        previousSortRef.current = sort;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryTransactions, sort, selectedLanguage, previousSortRef]);

  useEffect(() => {
    if (isSortLoaded) {
      window.scrollTo({ top: scrollPosition.current, behavior: 'auto' });
    }
  }, [isSortLoaded]);

  useEffect(() => {
    (async function initDictionaries() {
      getLegality();
      getActivity();
      setIsDataLoaded(isDataLoaded + 1);
    })();
  }, [selectedLanguage]);

  useEffect(() => {
    const query = new URLSearchParams(searchParams);
    const queryValues = prepareQueryValues(
      {
        ...initialFormValues,
        perPage,
        currentPage,
      },
      query,
      [
        'transactionTimeFrom',
        'transactionTimeTo',
        'createTimeFrom',
        'createTimeTo',
        'expirationTimeFrom',
        'expirationTimeTo',
      ],
    );

    if (queryValues && Object.keys(queryValues).length) {
      setIsSearchParams(true);
    }
    setInitialValues({ ...initialFormValues, ...queryValues });
  }, [searchParams]);

  const getNewQueryPath = (values: Partial<ISearchFormValues>) => {
    const valuesWithoutPreset = { ...values };
    valuesWithoutPreset.preset = undefined;

    const transactionTimeFrom = formatDateToRequest(values.transactionTimeFrom, true, true);
    const transactionTimeTo = formatDateToRequest(values.transactionTimeTo, true, true);
    const createTimeFrom = formatDateToRequest(values.createTimeFrom, true, true);
    const createTimeTo = formatDateToRequest(values.createTimeTo, true, true);
    const expirationTimeFrom = formatDateToRequest(values.expirationTimeFrom, true, true);
    const expirationTimeTo = formatDateToRequest(values.expirationTimeTo, true, true);
    const queryTransactionsvalues: ISearchParams = {
      ...valuesWithoutPreset,
      transactionTimeFrom,
      transactionTimeTo,
      createTimeFrom,
      createTimeTo,
      expirationTimeFrom,
      expirationTimeTo,
      currentPage,
      perPage,
    };
    const queryString = objectToQueryString(queryTransactionsvalues);
    if (queryString) {
      return `${pathname}?${objectToQueryString(queryTransactionsvalues)}`;
    }
    return pathname;
  };

  const areDatesEqual = (
    date1: Date | null | undefined | string,
    date2: Date | null | undefined | string,
  ): boolean => {
    if (!date1 && !date2) return true;
    if (!date1 || !date2) return false;

    return new Date(date1).getTime() === new Date(date2).getTime();
  };

  const handleSetQueryTransaction = (values: Partial<ISearchFormValues>) => {
    const valuesWithoutInvokedByPageChange = { ...values };
    valuesWithoutInvokedByPageChange.invokedByPageChange = undefined;

    window.history.replaceState({}, '', getNewQueryPath(valuesWithoutInvokedByPageChange));
    if (!isInitialLoad) {
      if (
        values.transactionTypeUniqueId !== queryTransactions.transactionTypeUniqueId ||
        !areDatesEqual(values.transactionTimeFrom, queryTransactions.transactionTimeFrom) ||
        !areDatesEqual(values.transactionTimeTo, queryTransactions.transactionTimeTo) ||
        !areDatesEqual(values.createTimeFrom, queryTransactions.createTimeFrom) ||
        !areDatesEqual(values.createTimeTo, queryTransactions.createTimeTo) ||
        !areDatesEqual(values.legalityUniqueId, queryTransactions.legalityUniqueId) ||
        !areDatesEqual(values.expirationTimeFrom, queryTransactions.expirationTimeFrom) ||
        !areDatesEqual(values.expirationTimeTo, queryTransactions.expirationTimeTo) ||
        (values.asset !== queryTransactions.asset &&
          (queryTransactions.asset !== undefined ||
            // @ts-ignore
            values.asset !== '')) ||
        values.keeperUniqueId !== queryTransactions.keeperUniqueId ||
        values.ownerUniqueId !== queryTransactions.ownerUniqueId
      ) {
        setCurrentPage(1);
      }
    } else {
      setIsInitialLoad(false);
    }

    if (values.invokedByPageChange !== true) {
      setIsQueryTransactionsLoading(true);
    }

    setQueryTransactions(values);
  };

  return (
    <>
      <Breadcrumbs items={[t('transactionsList.transactions.header')]} />
      <StyledPageTitle variant="h4" mb={2}>
        {t('transactionsList.transactions.header')}
      </StyledPageTitle>
      {!!isDataLoaded && initialValues && (
        <TransactionSearchForm
          onSubmit={handleSetQueryTransaction}
          initialFormValues={initialValues}
          isSearchParams={isSearchParams}
          isSearchButtonDisabled={isTransactionsLoading || isQueryTransactionsLoading}
          getNewQueryPath={getNewQueryPath}
          perPage={perPage}
          currentPage={currentPage}
        />
      )}
      <Box sx={{ height: '20px' }} />
      {isTransactionsLoading || isQueryTransactionsLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
          {isDesktop && !!isDataLoaded ? (
            <Table
              columns={transactionsTableColumns({
                midDateFormat,
                renderStateFieldColor: (data: ITableDataSource) =>
                  renderDataColor(data).stateField,
                t,
              })}
              dataSource={mapTransactionsToDataSource(transactions)}
              specificRowColor={renderRowColor}
              specificRowColorHover={renderRowColorHover}
              onSort={(it) => {
                setSort(it);
                scrollPosition.current = window.scrollY;
              }}
              translationsKeys={{
                noResults: 'transactionsList.noResults.text',
              }}
              isSortLoaded={isSortLoaded}
              customPaddingIfNoData="30px"
            />
          ) : (
            <CardList
              items={mapTransactionsToDataSource(transactions)}
              render={(data, index) => (
                <TransactionItemCard
                  data={data}
                  key={index}
                  handleView={(id: string) =>
                    navigate(ERouteLinks.ViewTransaction.replace(':id', id))
                  }
                />
              )}
            />
          )}
          {paginator && (
            <Pagination
              count={paginator?.totalElements}
              perPage={perPage}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
              onChangePerPage={(value) => {
                setPerPage(value);
              }}
              current={currentPage}
              isLoading={isPaginationLoading}
              isVisible={isSortLoaded}
            />
          )}
        </>
      )}
    </>
  );
};

export default TransactionsPage;

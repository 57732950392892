import React, { useEffect, useRef, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { Accordion } from 'components/molecules/Accordion';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { Loader } from 'components/atoms/Loader';
import { Table } from 'components/molecules/Table';
import { CardList } from 'components/molecules/CardList';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { Box, Grid, Tooltip } from '@mui/material';
import {
  ESearchFRTContextType,
  FRTTableColumns,
  mapFRTToDataSource,
  mapRegisteredFirearmsToDataSource,
  registeredFirearmsTableColumns,
} from 'pages/Firearms/helpers';
import { useFirearms } from 'pages/Firearms/hooks';
import { EPerPages, ISortOptions, ITableDataSource } from 'models/table';
import { Pagination } from 'components/molecules/Pagination';
import {
  initialForm as initialFormRegisteredFirearms,
  ISearchFormValues,
  RegisteredFirearmsSearchForm,
} from 'pages/Firearms/RegisteredFirearmsSearchForm';
import { ERouteLinks } from 'models/route';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useDictionary } from 'models/dictionaryContext';
import { useAlert } from 'models/alertContext';
import { Alert, EAlertVariants, IAlert } from 'components/atoms/Alert';
import { ActionTypes } from 'state/actions/alert';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { exportRegisteredFirearmsCSV } from 'requests/firearm';
import { RegisteredFirearmTableCard } from 'pages/Firearms/RegisteredFirearmTableCard';
import {
  FRTSearchForm,
  initialForm as initialFormReferenceFirearms,
  ISearchFormValues as ISearchFormValuesReference,
} from 'pages/Firearms/FRTSearchForm';
import { EIconTypes } from 'constants/Icons';
import { FRTCard } from 'pages/Firearms/FRTCard';
import SearchFilterStore from 'state/SearchFilterStore';
import { useQuery } from 'hooks/useQuery';
import { IFormElementOptionProp } from 'models/form';
import {
  ECustomFieldObjectType,
  ECustomFieldType,
  ICustomFieldByObjectType,
} from 'models/customField';
import { useLang } from 'models/langContext';
import {
  customFieldsPrepareToSearch,
  objectToQueryString,
  prepareDataWithCustomFieldsToSearch,
  prepareQueryValues,
} from 'helpers/searchQuery';
import { useGlobalProperty } from 'models/globalPropertyContext';
import {
  renderDataColor,
  renderRowColor,
  renderRowColorHover,
} from 'components/molecules/Table/helpers';
import { isExportAvailable } from 'constants/Export';
import { EFirearmStateId } from 'models/firearm';
import { ISearchFormCustomFieldValue } from 'pages/LegalEntitiesPage/IndividualSearchForm';

export enum LEAccordionNames {
  REGISTERED = 'registered-firearms',
  REFERENCE = 'frt',
}

const FirearmsPage = () => {
  const { isDesktop } = useThemeBreakpoint();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { state: routerState, pathname }: any = useLocation();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [ownerOptions, setOwnerOptions] = useState<IFormElementOptionProp[]>([]);
  const [keeperOptions, setKeeperOptions] = useState<IFormElementOptionProp[]>([]);
  const { getFirearmsData, firearms, paginator, FRT, getFRTData, paginatorFRT } =
    useFirearms();
  const [isRegisteredFirearmsLoading, setIsRegisteredFirearmsLoading] = useState<
    boolean | null
  >(null);
  const [isFRTLoading, setIsFRTLoading] = useState<boolean | null>(null);
  const [registeredFirearmsSort, setRegisteredFirearmsSort] = useState<ISortOptions | null>(
    null,
  );
  const [searchParams] = useSearchParams();
  const queryData = new URLSearchParams(searchParams);
  const expandQuery = queryData.get('expand');
  const perPageFromQuery = Number.parseInt(searchParams.get('perPage') || '25', 10);
  const currentPageFromQuery = Number.parseInt(searchParams.get('currentPage') || '1', 10);
  const [currentPageRegisteredFirearms, setCurrentPageRegisteredFirearms] = useState<number>(
    expandQuery === LEAccordionNames.REGISTERED ? currentPageFromQuery : 1,
  );
  const [perPageRegisteredFirearms, setPerPageRegisteredFirearms] = useState<EPerPages>(
    expandQuery === LEAccordionNames.REGISTERED ? perPageFromQuery : EPerPages.perPage25,
  );
  const [queryRegisteredFirearms, setQueryRegisteredFirearms] = useState<
    Partial<ISearchFormValues>
  >({});
  const [isRegisteredFirearmsSortLoaded, setIsRegisteredFirearmsSortLoaded] =
    useState<boolean>(true);
  const [formQuery, setFormQuery] = useState<Partial<ISearchFormValues>>({});
  const { nextPageAlert, clearAlert, setAlert, samePageAlert } = useAlert();
  const { selectedLanguage } = useLang();

  const [FRTSort, setFRTSort] = useState<ISortOptions | null>(null);
  const [isFRTSortLoaded, setIsFRTSortLoaded] = useState<boolean>(true);
  const [currentPageFRT, setCurrentPageFRT] = useState<number>(
    expandQuery === LEAccordionNames.REFERENCE ? currentPageFromQuery : 1,
  );
  const [perPageFRT, setPerPageFRT] = useState<EPerPages>(
    expandQuery === LEAccordionNames.REFERENCE ? perPageFromQuery : EPerPages.perPage25,
  );
  const { getCountry } = useDictionary();
  const { country, getCustomField, customFieldsFirearms, customFieldsLoaded } =
    useLegalEntities();
  const { hasPermissions } = usePermission();
  const query = useQuery();
  const [isReferenceFirearmsLoaded, setIsReferenceFirearmsLoaded] = useState<boolean>(false);
  const [accordSearchParams, setAccordSearchParams] = useState<LEAccordionNames | null>(null);
  const [initialFormRegisteredFirearmsQuery, setInitialFormRegisteredFirearmsQuery] =
    useState<ISearchFormValues | null>(null);
  const [areInitialFirearmsValuesLoaded, setAreInitialFirearmsValuesLoaded] = useState(false);
  const { shortDateFormat, getDateFormat } = useGlobalProperty();
  const isFromMapQuery: boolean =
    query.get('fromMap') === 'true' || routerState?.uniqueIds?.length > 0;
  const storedFirearms = SearchFilterStore.getFirearms();
  const [isExportLoading, setExportLoading] = useState(false);
  const [isFirearmPaginationLoading, setIsFirearmPaginationLoading] = useState(false);
  const [isFRTPaginationLoading, setIsFRTPaginationLoading] = useState(false);
  const [isQueryRegisteredFirearmsLoading, setIsQueryRegisteredFirearmsLoading] =
    useState<boolean>(false);
  const [isQueryFRTLoading, setIsQueryFRTLoading] = useState<boolean>(false);
  const [initialFormReferenceFirearmsQuery, setInitialFormReferenceFirearmsQuery] =
    useState<ISearchFormValuesReference | null>({
      ...initialFormReferenceFirearms,
      ...prepareQueryValues(initialFormReferenceFirearms, queryData),
    });
  const [queryFRT, setQueryFRT] = useState<Partial<ISearchFormValues>>(
    initialFormReferenceFirearmsQuery || {},
  );
  const [isRegisteredInitialLoad, setIsRegisteredInitialLoad] = useState(true);
  const [isFRTInitialLoad, setIsFRTInitialLoad] = useState(true);

  const uniqueIds =
    storedFirearms.length > 0
      ? storedFirearms.map((firearmsItem) => firearmsItem.uniqueId)
      : routerState?.uniqueIds;

  const scrollPosition = useRef(0);

  useEffect(() => {
    if (isRegisteredFirearmsSortLoaded && isFRTSortLoaded) {
      window.scrollTo({ top: scrollPosition.current, behavior: 'auto' });
      scrollPosition.current = 0;
    }
  }, [isRegisteredFirearmsSortLoaded, isFRTSortLoaded]);

  useEffect(() => {
    getDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialFormRegisteredFirearmsQuery !== null && customFieldsLoaded) {
      setAreInitialFirearmsValuesLoaded(true);
    }
  }, [initialFormRegisteredFirearmsQuery, customFieldsLoaded]);

  useEffect(() => {
    if (customFieldsLoaded) {
      setAreInitialFirearmsValuesLoaded(false);
      if (expandQuery === LEAccordionNames.REGISTERED) {
        const queryValues = prepareQueryValues(
          {
            ...initialFormRegisteredFirearms,
            currentPageRegisteredFirearms,
            perPageRegisteredFirearms,
          },
          queryData,
          [
            ...customFieldsFirearms
              .filter((cf) => cf.customFieldType === ECustomFieldType.DATE)
              .map((cf) => [`from_${cf.name}`, `to_${cf.name}`]),
          ].flat(),
        );
        setAccordSearchParams(LEAccordionNames.REGISTERED);
        setShowAdvanced(true);
        setInitialFormRegisteredFirearmsQuery({
          ...initialFormRegisteredFirearms,
          ...queryValues,
        });
      } else {
        setInitialFormRegisteredFirearmsQuery(initialFormRegisteredFirearms);
        setAreInitialFirearmsValuesLoaded(true);
      }
    }
  }, [customFieldsLoaded]);

  useEffect(() => {
    if (expandQuery === LEAccordionNames.REFERENCE) {
      setIsReferenceFirearmsLoaded(false);
      const queryValues = prepareQueryValues(
        { ...initialFormReferenceFirearms, currentPageFRT, perPageFRT },
        queryData,
      );
      if (queryValues) {
        setAccordSearchParams(LEAccordionNames.REFERENCE);
        setInitialFormReferenceFirearmsQuery({
          ...initialFormReferenceFirearms,
          ...queryValues,
        });
      }
      setIsReferenceFirearmsLoaded(true);
    } else {
      setIsReferenceFirearmsLoaded(true);
    }
  }, [queryData]);

  const getNewQueryPath = (values: Partial<ISearchFormValues>): string => {
    const queryString = objectToQueryString(
      {
        ...values,
        ...{
          perPage: perPageRegisteredFirearms,
          currentPage: currentPageRegisteredFirearms,
        },
      },
      customFieldsFirearms,
    );
    if (queryString.length) {
      return `${pathname}?expand=registered-firearms&${queryString}`;
    }
    if (isFromMapQuery) {
      return `${pathname}?fromMap=true`;
    }
    return `${pathname}`;
  };

  const loadCustomFields = async () => {
    if (hasPermissions([EPermission.VIEW_FIREARMS, EPermission.VIEW_ALL_FIREARMS], true)) {
      await getCustomField(ECustomFieldObjectType.FIREARM);
    }
  };

  const areCustomFieldValuesSame = (
    cfValues: ISearchFormCustomFieldValue[] | undefined,
    queryValues: ISearchFormCustomFieldValue[] | undefined,
  ): boolean => {
    if (!cfValues || !queryValues) {
      return true;
    }
    let areSame = true;
    cfValues.forEach((cfValue) => {
      const queryCf = queryValues.find(
        (it) => it.customFieldUniqueId === cfValue.customFieldUniqueId,
      );
      if (
        cfValue.bool !== queryCf?.bool ||
        cfValue.text !== queryCf?.text ||
        cfValue.dateFrom !== queryCf?.dateFrom ||
        cfValue.dateTo !== queryCf?.dateTo
      ) {
        areSame = false;
      }
    });

    return areSame;
  };

  const handleSetQueryRegisteredFirearms = (values: Partial<ISearchFormValues>) => {
    const valuesWithoutPreset = { ...values };
    valuesWithoutPreset.preset = undefined;
    valuesWithoutPreset.formSubmitted = undefined;

    const valuesWithoutInvokedByPageChange = { ...valuesWithoutPreset };
    valuesWithoutInvokedByPageChange.invokedByPageChange = undefined;

    if (isRegisteredInitialLoad && valuesWithoutPreset.invokedByPageChange !== true) {
      setIsRegisteredInitialLoad(false);
    } else {
      setIsRegisteredInitialLoad(false);

      window.history.replaceState({}, '', getNewQueryPath(valuesWithoutInvokedByPageChange));

      if (
        values.serialNumber !== queryRegisteredFirearms.serialNumber ||
        values.fireCycleId !== queryRegisteredFirearms.fireCycleId ||
        values.exhibitNumber !== queryRegisteredFirearms.exhibitNumber ||
        values.stateUniqueId !== queryRegisteredFirearms.stateUniqueId ||
        values.type !== queryRegisteredFirearms.type ||
        values.legalityUniqueId !== queryRegisteredFirearms.legalityUniqueId ||
        values.make !== queryRegisteredFirearms.make ||
        values.model !== queryRegisteredFirearms.model ||
        values.manufacturer !== queryRegisteredFirearms.manufacturer ||
        values.caliber !== queryRegisteredFirearms.caliber ||
        values.productCode !== queryRegisteredFirearms.productCode ||
        !areCustomFieldValuesSame(values.customFields, queryRegisteredFirearms.customFields) ||
        values.keeperUniqueId !== queryRegisteredFirearms.keeperUniqueId ||
        values.ownerUniqueId !== queryRegisteredFirearms.ownerUniqueId
      ) {
        setCurrentPageRegisteredFirearms(1);
      }
    }

    setQueryRegisteredFirearms({
      ...valuesWithoutPreset,
    });
  };

  const previousRegisteredFirearmsSortRef = useRef(registeredFirearmsSort);

  useEffect(() => {
    if (!hasPermissions([EPermission.VIEW_FIREARMS, EPermission.VIEW_ALL_FIREARMS], true)) {
      return;
    }

    (async function getFirearmsDataInit() {
      if (queryRegisteredFirearms.invokedByPageChange !== true) {
        setIsQueryRegisteredFirearmsLoading(true);
      }

      setIsFirearmPaginationLoading(true);

      if (!firearms.length) {
        setIsRegisteredFirearmsLoading(true);
      }

      if (registeredFirearmsSort !== previousRegisteredFirearmsSortRef.current) {
        setIsRegisteredFirearmsSortLoaded(false);
      }

      if (isFromMapQuery) {
        if (uniqueIds && uniqueIds.length) {
          queryRegisteredFirearms.uniqueIds = uniqueIds;
        } else {
          delete queryRegisteredFirearms.uniqueIds;
        }
      }

      let firearmsQuery: { [index: string]: any } = {};
      if (!routerState?.formValues) {
        firearmsQuery = queryRegisteredFirearms;
      } else {
        firearmsQuery = queryRegisteredFirearms;
        Object.keys(queryRegisteredFirearms).forEach((key) => {
          // @ts-ignore
          const value = queryRegisteredFirearms[key];
          if (value) {
            firearmsQuery[key] = value;
          }
        });
      }
      if (Object.keys(firearmsQuery).length !== 0) {
        customFieldsPrepareToSearch(firearmsQuery.customFields);
        const firearmsQueryData = prepareDataWithCustomFieldsToSearch(
          firearmsQuery,
          customFieldsFirearms.filter((item: ICustomFieldByObjectType) => item.searchCriteria),
        );
        await getFirearmsData(
          currentPageRegisteredFirearms,
          perPageRegisteredFirearms,
          firearmsQueryData,
          registeredFirearmsSort,
        );
        setFormQuery(firearmsQueryData);
      }

      setIsQueryRegisteredFirearmsLoading(false);
      setIsRegisteredFirearmsLoading(false);
      setIsFirearmPaginationLoading(false);
      setIsRegisteredFirearmsSortLoaded(true);
      previousRegisteredFirearmsSortRef.current = registeredFirearmsSort;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryRegisteredFirearms, registeredFirearmsSort, customFieldsLoaded, selectedLanguage]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async () => {
      loadCustomFields();
    })();
    if (!country) {
      getCountry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const previousFRTSortRef = useRef(FRTSort);

  useEffect(() => {
    (async function getFRTDataInit() {
      if (queryFRT.invokedByPageChange !== true) {
        setIsQueryFRTLoading(true);
      }

      setIsFRTPaginationLoading(true);
      if (FRTSort !== previousFRTSortRef.current) {
        setIsFRTSortLoaded(false);
      }
      if (!FRT.length) {
        setIsFRTLoading(true);
      }
      try {
        await getFRTData(
          currentPageFRT,
          perPageFRT,
          {
            ...queryFRT,
          },
          FRTSort,
        );
      } finally {
        setIsFRTSortLoaded(true);
        previousFRTSortRef.current = FRTSort;
        setIsFRTLoading(false);
        setIsFRTPaginationLoading(false);
        setIsQueryFRTLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryFRT, FRTSort]);

  const handleExport = async () => {
    setExportLoading(true);
    await exportRegisteredFirearmsCSV(queryRegisteredFirearms, registeredFirearmsSort);
    setExportLoading(false);
  };

  const handleSetQueryFRT = (values: Partial<ISearchFormValuesReference>) => {
    const valuesWithoutInvokedByPageChange = { ...values };
    valuesWithoutInvokedByPageChange.invokedByPageChange = undefined;

    const queryString = objectToQueryString({
      ...valuesWithoutInvokedByPageChange,
      ...{ perPage: perPageFRT, currentPage: currentPageFRT },
    });

    if (isFRTInitialLoad && values.invokedByPageChange !== true) {
      setIsFRTInitialLoad(false);
    } else {
      setIsFRTInitialLoad(false);

      if (queryString.length) {
        window.history.replaceState({}, '', `${pathname}?expand=frt&${queryString}`);
      }

      if (
        values.make !== queryFRT.make ||
        values.model !== queryFRT.model ||
        values.manufacturer !== queryFRT.manufacturer ||
        values.country !== queryFRT.country ||
        values.productCode !== queryFRT.productCode ||
        values.action !== queryFRT.action ||
        values.type !== queryFRT.type ||
        values.caliber !== queryFRT.caliber
      ) {
        setCurrentPageFRT(1);
      }
    }

    setQueryFRT(values);
  };

  const handleRedirectToEditPageButton = (item: ITableDataSource) => {
    if (customFieldsFirearms?.length && item.stateUniqueId !== EFirearmStateId.DISABLED) {
      navigate(`${ERouteLinks.EditRegisteredFirearm.replace(':id', item.id as string)}`, {
        state: {
          prevPage: `${ERouteLinks.Firearms}`,
        },
      });
    } else if (item.stateUniqueId === EFirearmStateId.DISABLED) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('firearm.disabled.edit.info'),
        variant: EAlertVariants.error,
      } as IAlert);
    } else {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('general.noAvaibleDataToEdit.label'),
        variant: EAlertVariants.warning,
      } as IAlert);
    }
  };

  return (
    <>
      <Breadcrumbs items={[t('module.firearm.name')]} />
      <StyledPageTitle variant="h4">{t('module.firearm.name')}</StyledPageTitle>
      {nextPageAlert && (
        <Box mt={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}
      {hasPermissions([EPermission.VIEW_FIREARMS]) && (
        <Box mt={2}>
          <Accordion
            title={t('firearms.registeredFirearms.accordion.name')}
            name="registered-firearms"
            expanded={routerState?.legalEntity}
          >
            <div>
              {initialFormRegisteredFirearmsQuery &&
                customFieldsLoaded &&
                areInitialFirearmsValuesLoaded && (
                  <RegisteredFirearmsSearchForm
                    ownerOptions={ownerOptions}
                    keeperOptions={keeperOptions}
                    setOwnerOptions={setOwnerOptions}
                    setKeeperOptions={setKeeperOptions}
                    showAdvanced={showAdvanced}
                    setShowAdvanced={setShowAdvanced}
                    getNewQueryPath={getNewQueryPath}
                    isSearchButtonDisabled={
                      isRegisteredFirearmsLoading === true || isQueryRegisteredFirearmsLoading
                    }
                    onSubmit={(values) => handleSetQueryRegisteredFirearms(values)}
                    uniqueIds={uniqueIds}
                    isFromMap={isFromMapQuery}
                    returnPath={`${pathname}?${query}`}
                    customFields={customFieldsFirearms.filter(
                      (item: ICustomFieldByObjectType) => item.searchCriteria,
                    )}
                    initialFormValues={initialFormRegisteredFirearmsQuery ?? undefined}
                    perPage={perPageRegisteredFirearms}
                    currentPage={currentPageRegisteredFirearms}
                  />
                )}
              {!isRegisteredFirearmsLoading && firearms.length ? (
                <Grid container columnSpacing={1} my={2} justifyContent="flex-end">
                  {!isRegisteredFirearmsLoading && (
                    <>
                      {hasPermissions([EPermission.EXPORT_FIREARMS]) && (
                        <Grid item xs={6} lg="auto">
                          {isExportAvailable(paginator) ? (
                            <Tooltip title={t('dataExportRecordsLimitAmount.label')} arrow>
                              <span>
                                <Button
                                  label={t('registeredFirearms.accordion.export.button')}
                                  id="registered_firearms-export"
                                  variant={EButtonVariants.outlined}
                                  fullWidth
                                  size={EButtonSizes.small}
                                  isLoading={isExportLoading}
                                  onClick={handleExport}
                                  disabled
                                />
                              </span>
                            </Tooltip>
                          ) : (
                            <Button
                              label={t('registeredFirearms.accordion.export.button')}
                              id="registered_firearms-export"
                              variant={EButtonVariants.outlined}
                              fullWidth
                              size={EButtonSizes.small}
                              isLoading={isExportLoading}
                              onClick={handleExport}
                            />
                          )}
                        </Grid>
                      )}
                      {hasPermissions([EPermission.VIEW_FIREARMS]) && (
                        <Grid item xs={6} lg="auto">
                          <Button
                            label={t('registeredFirearms.accordion.showOnMap.button')}
                            id="registered_firearms-show_on_map"
                            variant={EButtonVariants.contained}
                            size={EButtonSizes.small}
                            onClick={() => {
                              navigate(ERouteLinks.RegisteredFirearmsMap, {
                                state: {
                                  queryRegisteredFirearms: formQuery,
                                  showAdvanced,
                                  legalityUniqueId: queryRegisteredFirearms.legalityUniqueId,
                                  options: { ownerOptions, keeperOptions },
                                  navigateBack: -1,
                                },
                              });
                            }}
                            fullWidth
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              ) : null}

              {isRegisteredFirearmsLoading || isQueryRegisteredFirearmsLoading ? (
                <Loader isLoading={true} />
              ) : (
                <>
                  {isDesktop ? (
                    <Table
                      columns={registeredFirearmsTableColumns(
                        {
                          handleEdit: (item: ITableDataSource) =>
                            handleRedirectToEditPageButton(item),
                          renderStateFieldColor: (data: ITableDataSource) =>
                            renderDataColor(data).stateField,
                          hasPermission: hasPermissions([EPermission.VIEW_FIREARMS]),
                          customFields: customFieldsFirearms,
                          t,
                        },
                        selectedLanguage?.uniqueId,
                        shortDateFormat,
                      )}
                      id="registered-firearms_table"
                      dataSource={mapRegisteredFirearmsToDataSource(firearms)}
                      onSort={(it) => {
                        setRegisteredFirearmsSort(it);
                        scrollPosition.current = window.scrollY;
                      }}
                      specificRowColor={renderRowColor}
                      specificRowColorHover={renderRowColorHover}
                      translationsKeys={{
                        noResults: 'registeredFirearms.accordion.noResults.text',
                      }}
                      isSortLoaded={isRegisteredFirearmsSortLoaded}
                      customPaddingIfNoData="30px"
                    />
                  ) : (
                    <CardList
                      items={mapRegisteredFirearmsToDataSource(firearms)}
                      render={(data, index) => (
                        <RegisteredFirearmTableCard
                          data={data}
                          key={index}
                          handleEdit={handleRedirectToEditPageButton}
                          renderDataColor={() => renderDataColor(data)}
                        />
                      )}
                    />
                  )}

                  {paginator && (
                    <Pagination
                      count={paginator?.totalElements}
                      perPage={perPageRegisteredFirearms}
                      onChangePage={(page) => {
                        setCurrentPageRegisteredFirearms(page);
                      }}
                      onChangePerPage={(value) => {
                        setPerPageRegisteredFirearms(value);
                      }}
                      current={currentPageRegisteredFirearms}
                      isLoading={isFirearmPaginationLoading}
                      isVisible={isRegisteredFirearmsSortLoaded}
                    />
                  )}
                </>
              )}
            </div>
          </Accordion>
          {samePageAlert && (
            <Box mt={2} mb={2}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
        </Box>
      )}
      <Box>
        <Accordion title={t('firearms.firearmsReferenceTable.accordion.name')} name="frt">
          <div>
            {isReferenceFirearmsLoaded && (
              <FRTSearchForm
                onSubmit={handleSetQueryFRT}
                showSaveMyFilter={false}
                isSearchButtonDisabled={isFRTLoading === true || isQueryFRTLoading}
                initialFormValues={
                  accordSearchParams === LEAccordionNames.REFERENCE &&
                  initialFormReferenceFirearmsQuery
                    ? initialFormReferenceFirearmsQuery
                    : initialFormReferenceFirearms
                }
                perPage={perPageFRT}
                currentPage={currentPageFRT}
              />
            )}
            {hasPermissions([EPermission.MANAGE_FRT_FIREARM]) && (
              <Grid
                container
                item
                xs={12}
                lg={3}
                sx={{ marginLeft: 'auto', marginTop: '20px' }}
              >
                <Button
                  label={t('firearmsReferenceTable.accordion.addFirearm.button')}
                  id="firearms_reference-add_firearm"
                  variant={EButtonVariants.contained}
                  icon={EIconTypes.plus}
                  onClick={() => navigate(ERouteLinks.AddFRT)}
                  sx={{ marginBottom: '20px' }}
                  fullWidth
                  size={EButtonSizes.small}
                />
              </Grid>
            )}
            {isFRTLoading || isQueryFRTLoading ? (
              <Loader isLoading={true} />
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {isDesktop ? (
                  <Table
                    columns={FRTTableColumns(
                      {
                        handleEdit: (id: string) =>
                          navigate(ERouteLinks.EditFRT.replace(':frtId', id)),
                        hasPermissionManageFRT: hasPermissions([
                          EPermission.MANAGE_FRT_FIREARM,
                        ]),
                      },
                      ESearchFRTContextType.Firearms,
                    )}
                    dataSource={mapFRTToDataSource(FRT)}
                    onSort={(it) => {
                      setFRTSort(it);
                      scrollPosition.current = window.scrollY;
                    }}
                    translationsKeys={{
                      noResults: 'registeredFirearms.accordion.noResults.text',
                    }}
                    id="firearms_reference_table_table"
                    isSortLoaded={isFRTSortLoaded}
                    customPaddingIfNoData="30px"
                  />
                ) : (
                  <CardList
                    items={mapFRTToDataSource(FRT)}
                    render={(data, index) => (
                      <FRTCard
                        data={data}
                        key={index}
                        handleView={(id: string) =>
                          navigate(ERouteLinks.FRTDetails.replace(':frtId', id))
                        }
                      />
                    )}
                  />
                )}

                {paginatorFRT && (
                  <Pagination
                    count={paginatorFRT?.totalElements}
                    perPage={perPageFRT}
                    onChangePage={(page) => {
                      setCurrentPageFRT(page);
                    }}
                    onChangePerPage={(value) => {
                      if (value.toString() !== perPageFRT.toString()) {
                        setPerPageFRT(value);
                      }
                    }}
                    current={currentPageFRT}
                    isLoading={isFRTPaginationLoading}
                    isVisible={isFRTSortLoaded}
                  />
                )}
              </>
            )}
          </div>
        </Accordion>
      </Box>
    </>
  );
};

export default FirearmsPage;

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { initialRoleFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRolesFormSchema from 'pages/RolesPage/schemaValidation';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Tooltip, Typography } from '@mui/material';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { IRoleForm, IRoleFormValues } from 'models/role';
import { IParsedPermissions, useRoles } from 'pages/RolesPage/hooks';
import { EPermission, EPermissionRelatedModule, permissionsGroups } from 'models/permissions';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { useTranslations } from 'hooks/useTranslations';
import { StyledRoleFormCheckboxListWrapper } from 'pages/RolesPage/AddRolePage/styles';
import { ADMINISTRATOR_ROLE_ID } from 'pages/RolesPage/helpers';
import Icon from 'components/atoms/Icon/Icon';
import { EIconTypes } from 'constants/Icons';
import { colors } from 'theme/colors';
import { useLang } from 'models/langContext';
import { useScroll } from 'hooks/useScroll';
import { permissionsWithTooltip, prepareRoleList, roleIsDisabled } from './helpers';

const RoleForm = ({
  onCancel,
  onSubmit,
  initialFormValues,
  uniqueId,
  formDisabled,
}: IRoleForm) => {
  const { schema } = useRolesFormSchema();
  const { selectedLanguage } = useLang();
  const { elRef, scrollToError } = useScroll();
  const { t } = useTranslations();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IRoleFormValues>({
    defaultValues: initialFormValues || initialRoleFormValues,
    resolver: yupResolver(schema),
  });
  const [parsedPermissions, setParsedPermissions] = useState<IParsedPermissions>({});

  const [permissionIds, setPermissionIds] = useState<string[]>([]);

  const { roleAlert, clearAlert, setAlert } = useAlert();
  const { getPermissions, parsePermissions, permissions } = useRoles();

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    setParsedPermissions(parsePermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  useEffect(() => {
    reset(initialFormValues || initialRoleFormValues);
    if (initialFormValues) {
      if (initialFormValues.formPermissionIds) {
        const ids = Object.keys(initialFormValues.formPermissionIds).filter(
          (key) => initialFormValues.formPermissionIds?.[key] === true,
        );
        setPermissionIds(ids);
      }
    }
  }, [reset, initialFormValues]);

  const formChanged = (
    initialValues?: IRoleFormValues | null,
    currentValues?: IRoleFormValues | null,
  ) => {
    const baseValuesChanged =
      initialValues?.name !== currentValues?.name ||
      initialValues?.description !== currentValues?.description;

    const currentSelectedPermissions = Object.entries(currentValues?.formPermissionIds || {})
      // eslint-disable-next-line
      .filter(([_, value]) => value === true)
      .map(([key]) => key);

    const arePermissionsEqual = (arr1: string[], arr2: string[]): boolean => {
      if (arr1.length !== arr2.length) return false;
      return arr1.every((item) => arr2.includes(item));
    };

    const permissionsChanged = !arePermissionsEqual(
      // eslint-disable-next-line
      Object.entries(initialValues?.formPermissionIds || {}).map(([key, _]) => key),
      currentSelectedPermissions,
    );

    return baseValuesChanged || permissionsChanged;
  };

  const onSubmitForm = async (data: IRoleFormValues) => {
    if (!formChanged(initialFormValues, data)) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('general.noChanged.warning.message'),
        variant: EAlertVariants.warning,
      });
      return;
    }

    if (data.formPermissionIds) {
      data.permissionIds = Object.keys(data.formPermissionIds).filter(
        (key) => data?.formPermissionIds?.[key] === true,
      );
    }

    setIsSubmitLoading(true);
    if (initialFormValues) {
      await onSubmit({ ...initialFormValues, ...data });
    } else {
      await onSubmit(data);
    }
    setIsSubmitLoading(false);
  };

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_ROLE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (value.formPermissionIds && name?.includes('formPermissionIds')) {
        const ids = Object.keys(value.formPermissionIds).filter(
          (key) => value.formPermissionIds?.[key] === true,
        );
        setPermissionIds(ids);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const validateDisabled = (
    group: EPermissionRelatedModule,
    permissionId: string,
  ): boolean => {
    if (uniqueId === ADMINISTRATOR_ROLE_ID) {
      if (group === EPermissionRelatedModule.ADMINISTRATION) {
        return true;
      }
    }
    const isDisabled = roleIsDisabled(permissionId, permissionIds);
    if (isDisabled && permissionIds.includes(permissionId)) {
      setValue(`formPermissionIds.[${permissionId}]`, false);
    }
    return isDisabled;
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container ref={elRef}>
        {formDisabled && (
          <Grid item xs={12} style={{ margin: '10px 0 20px' }}>
            <Alert text={t('roles.addRole.limit.warning')} variant={EAlertVariants.warning} />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Box mb={1}>
            <FormInput
              name="name"
              label={`${t('addRole.roleName.label')}*`}
              setValue={setValue}
              control={control}
              errors={errors}
              disabled={uniqueId === ADMINISTRATOR_ROLE_ID || formDisabled}
              charsLimit={20}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={1}>
            <FormInput
              name="description"
              label={t('addRole.description.label')}
              control={control}
              setValue={setValue}
              errors={errors}
              multiline={true}
              minRows={3}
              disabled={uniqueId === ADMINISTRATOR_ROLE_ID || formDisabled}
            />
          </Box>
        </Grid>

        <StyledRoleFormCheckboxListWrapper isError={!!errors.formPermissionIds}>
          <Grid container item xs={12} rowGap={6}>
            {permissionsGroups.map(
              (group) =>
                parsedPermissions?.[group.module] && (
                  <Grid
                    item
                    xl={3}
                    lg={6}
                    md={6}
                    xs={12}
                    key={group.module}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box mb={1}>
                      <Typography variant="subtitle2">{t(group.title)}</Typography>
                      <div>
                        {prepareRoleList(parsedPermissions?.[group.module]).map(
                          (permission) => (
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              width="fit-content"
                            >
                              <FormCheckbox
                                disabled={
                                  validateDisabled(group.module, permission.uniqueId) ||
                                  formDisabled
                                }
                                key={permission.uniqueId}
                                name={`formPermissionIds.[${permission.uniqueId}]`}
                                label={permission.name}
                                control={control}
                                errors={errors}
                                checked={permissionIds.includes(permission.uniqueId)}
                              />
                              {!!permission.description &&
                                !!permission.description.trim().length &&
                                permissionsWithTooltip.includes(
                                  permission.authority as EPermission,
                                ) && (
                                  <Tooltip
                                    title={permission.description}
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          backgroundColor: colors.primary700,
                                          fontWeight: 400,
                                          padding: '5px 15px',
                                          fontSize: '0.75rem',
                                          lineHeight: '1rem',
                                        },
                                      },
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        ':hover': {
                                          cursor: 'pointer',
                                        },
                                      }}
                                    >
                                      <Icon
                                        type={EIconTypes.info2}
                                        color={colors.primary100}
                                      />
                                    </Box>
                                  </Tooltip>
                                )}
                              <Box sx={{ mr: 3 }} />
                            </Box>
                          ),
                        )}
                      </div>
                    </Box>
                  </Grid>
                ),
            )}
          </Grid>
        </StyledRoleFormCheckboxListWrapper>

        <Grid item lg={12} xs={12}>
          <FormErrorLabel label={errors?.formPermissionIds?.message?.toString() as string} />
        </Grid>

        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                type="submit"
                fullWidth
                label={t('general.save.button')}
                id="save-button"
                variant={EButtonVariants.contained}
                isLoading={isSubmitLoading}
                disabled={formDisabled}
              />
            </Grid>

            {onCancel && (
              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  fullWidth
                  label={t('general.cancel.button')}
                  id="cancel-button"
                  variant={EButtonVariants.outlined}
                  onClick={onCancel}
                  disabled={formDisabled}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item mt={2} xs={12}>
          <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
        </Grid>
        <Grid item mt={3} xs={12}>
          {roleAlert && <Alert text={roleAlert.text} variant={roleAlert.variant} />}
        </Grid>
      </Grid>
    </form>
  );
};

export { RoleForm };

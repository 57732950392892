import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { useTranslations } from 'hooks/useTranslations';
import { useForm } from 'react-hook-form';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { ESearchFilterKeys, ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { ERouteLinks } from 'models/route';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { useDictionary } from 'models/dictionaryContext';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { prepareFilters } from 'helpers/filters';
import { getDateFromISO, isDateAfter, isDateEqual } from 'utils/date';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useFilterPresets } from 'hooks/useFilterPresets';
import { StyledFormSelect } from 'pages/Firearms/RegisteredFirearmsSearchForm/styles';
import { ESelectLE } from 'pages/Firearms/RegisteredFirearmsSearchForm';
import { IFormElementOptionProp } from 'models/form';
import { ISearchFormValues } from 'models/transaction';
import { useLang } from 'models/langContext';
import { FormDateTimePicker } from 'components/molecules/FormDateTimePicker';
import { ETransactionTypes } from 'constants/TransactionTypes';
import { getSearchFilter } from 'requests/searchFilter';
import { EPerPages } from 'models/table';
import { isEmptyObject } from '../../Firearms/helpers';
import { StyledAdvancedSearch, StyledPresetSelect } from './styles';

export enum ESearchFormTransactionLabels {
  transactionTypeUniqueId = 'transactionsList.transactionType.label',
  stateUniqueId = 'transactionsList.state.label',
  transactionTimeFrom = 'transactionsList.transactionTimeFrom.label',
  transactionTimeTo = 'transactionsList.transactionTimeTo.label',
  createTimeFrom = 'transactionsList.createTimeFrom.label',
  createTimeTo = 'transactionsList.createTimeTo.label',
  legalityUniqueId = 'transactionsList.firearmLegality.label',
  expirationTimeFrom = 'transactionsList.transactionExpiryTimeFrom.label',
  expirationTimeTo = 'transactionsList.transactionExpiryTimeTo.label',
  ownerName = 'transactionsList.owner.label',
  keeperName = 'transactionsList.keeper.label',
  asset = 'transactionsList.asset.label',
}

export interface ISearchParams {
  [key: string]: string | Date;
}

interface ITransactionSearchForm {
  onSubmit: (arg: any) => void;
  initialFormValues: ISearchFormValues;
  isSearchParams: boolean;
  isSearchButtonDisabled?: boolean;
  getNewQueryPath?: (arg: any) => string;
  currentPage: number;
  perPage: EPerPages;
}

export enum Asset {
  FIREARM = 'FIREARM',
  ANCILLARY = 'ANCILLARY',
}

const assetOptions = [
  {
    label: 'transactionsList.asset.ancillaries',
    value: Asset.ANCILLARY,
  },
  {
    label: 'transactionsList.asset.firearms',
    value: Asset.FIREARM,
  },
];

const TransactionSearchForm = ({
  onSubmit,
  initialFormValues,
  isSearchParams,
  isSearchButtonDisabled = false,
  getNewQueryPath,
  perPage,
  currentPage,
}: ITransactionSearchForm) => {
  const [showAdvanced, setShowAdvanced] = useState<boolean>(isSearchParams);
  const [ownerOptions, setOwnerOptions] = useState<IFormElementOptionProp[]>([]);
  const [keeperOptions, setKeeperOptions] = useState<IFormElementOptionProp[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { isDesktop } = useThemeBreakpoint();
  const [values, setValues] = useState<ISearchFormValues>(initialFormValues);
  const { state: routerState, search }: any = useLocation();
  const { getFilterPresets, presets, getPresetValues, preparePresetsList } =
    useFilterPresets();
  const { transactionType, getTransactionType, legality, getLegality } = useDictionary();
  const [isInitialFormLoad, setIsInitialFormLoad] = useState(true);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<ISearchFormValues>({
    defaultValues: initialFormValues,
  });

  const assetDictionary = {
    FIREARM: t('transactionsList.asset.firearms'),
    ANCILLARY: t('transactionsList.asset.ancillaries'),
  };

  const dateKeys = [
    'transactionTimeFrom',
    'transactionTimeTo',
    'createTimeFrom',
    'createTimeTo',
    'expirationTimeFrom',
    'expirationTimeTo',
  ];

  useEffect(() => {
    if (!isInitialFormLoad) {
      if (getNewQueryPath) {
        handleSubmit((val) => onSubmit({ ...val, invokedByPageChange: true }))();
      }
    } else {
      setIsInitialFormLoad(false);
    }
  }, [perPage, currentPage]);

  useEffect(() => {
    if (isSearchParams) {
      setShowAdvanced(true);

      if (initialFormValues.ownerUniqueId && initialFormValues.ownerName) {
        setOwnerOptions([
          {
            label: initialFormValues.ownerName,
            value: initialFormValues.ownerUniqueId,
          },
        ]);
      }

      if (initialFormValues.keeperUniqueId && initialFormValues.keeperName) {
        setKeeperOptions([
          {
            label: initialFormValues.keeperName,
            value: initialFormValues.keeperUniqueId,
          },
        ]);
      }
    }
  }, []);

  const [searchParams] = useSearchParams();
  const presetId =
    searchParams.get('presetId') ||
    searchParams.get('preset') ||
    routerState?.presetTransaction;
  const watchPreset = watch('preset');

  const setPresetFilters = async (
    selectedPreset: string,
    isFromEmailLink: boolean = false,
    isClickedManually: boolean = false,
    isInitialLoad: boolean = false,
  ) => {
    const handleValues = async () => {
      if (isFromEmailLink) {
        const filter = await getSearchFilter(selectedPreset);
        if (filter) {
          const queryValues: ISearchParams = {};
          filter.values.forEach((it) => {
            if (dateKeys.includes(it.key)) {
              queryValues[it.key] = new Date(getDateFromISO(it.value as string));
            } else {
              queryValues[it.key] = it.value;
            }
          });
          return queryValues;
        }
      }
      return getPresetValues(selectedPreset);
    };

    const initialValues = isInitialLoad
      ? { ...initialFormValues, ...routerState?.formValues }
      : initialFormValues;

    const presetValues: any = {
      ...(isClickedManually ? initialFormValues : initialValues),
      preset: selectedPreset,
      ...(await handleValues()),
    };

    if (presetValues.ownerUniqueId && presetValues.ownerName) {
      setOwnerOptions([
        {
          label: presetValues.ownerName,
          value: presetValues.ownerUniqueId,
        },
      ]);
    }

    if (presetValues.keeperUniqueId && presetValues.keeperName) {
      setKeeperOptions([
        {
          label: presetValues.keeperName,
          value: presetValues.keeperUniqueId,
        },
      ]);
    }

    setValues(presetValues);
    reset(presetValues);

    if (isClickedManually) {
      await handleSubmit(onSubmit)();
    }
  };

  const setLegalEntity = () => {
    const formValues = {
      ...initialFormValues,
      ...routerState?.formValues,
    };

    if (routerState?.type && routerState?.legalEntity) {
      const legalName = routerState?.legalEntity?.['organization.organizationName']
        ? routerState?.legalEntity?.['organization.organizationName']
        : `${routerState?.legalEntity?.['individual.firstName']} ${routerState?.legalEntity?.['individual.lastName']}`;
      const option = [
        {
          label: legalName,
          value: routerState.legalEntity.id,
        },
      ];
      if (routerState.type === ESelectLE.keeper) {
        setKeeperOptions(option);
        formValues.keeperName = legalName;
        if (routerState?.options?.ownerOptions) {
          setOwnerOptions(routerState.options.ownerOptions);
        }
      } else {
        setOwnerOptions(option);
        formValues.ownerName = legalName;
        if (routerState?.options?.keeperOptions) {
          setKeeperOptions(routerState.options.keeperOptions);
        }
      }
      formValues[routerState?.type === ESelectLE.owner ? 'ownerUniqueId' : 'keeperUniqueId'] =
        routerState.legalEntity.id;
    }
    setValues(formValues);
    setShowAdvanced(true);
    reset(formValues);
  };

  useEffect(() => {
    (async function init() {
      await getFilterPresets(ESearchFilterTypes.transaction);
      if (routerState?.options?.keeperOptions.length) {
        setKeeperOptions(routerState.options.keeperOptions);
      }

      if (routerState?.options?.ownerOptions.length) {
        setOwnerOptions(routerState.options.ownerOptions);
      }

      if (presetId) {
        await setPresetFilters(presetId, true);
        setShowAdvanced(true);
        await handleSubmit(onSubmit)();
      }

      if (routerState?.formValues) {
        const formValues = {
          ...initialFormValues,
          ...routerState.formValues,
          legalityUniqueId: routerState?.legalityUniqueId,
          keeperUniqueId: routerState?.options?.keeperOptions[0]?.value || '',
          ownerUniqueId: routerState?.options?.ownerOptions[0]?.value || '',
        };
        setValues(formValues);
        reset(formValues);
      }
      if (!isEmptyObject(routerState?.formValues)) {
        setShowAdvanced(true);
      }
      if (routerState?.legalEntity) {
        setLegalEntity();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function init() {
      if (watchPreset) {
        await setPresetFilters(watchPreset, false, false, true);
      } else {
        await setPresetFilters('', false, false, true);
      }
      await handleSubmit(onSubmit)();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchFormValues);

      if (
        value.transactionTimeFrom &&
        value.transactionTimeTo &&
        (isDateAfter(value.transactionTimeFrom, value.transactionTimeTo) ||
          isDateEqual(value.transactionTimeFrom, values.transactionTimeTo))
      ) {
        setError('transactionTimeTo', {
          type: 'custom',
          message: t('general.validation.dateShouldBeLater.message'),
        });
      } else {
        clearErrors('transactionTimeTo');
      }

      if (
        value.createTimeFrom &&
        value.createTimeTo &&
        (isDateAfter(value.createTimeFrom, value.createTimeTo) ||
          isDateEqual(value.createTimeFrom, values.createTimeTo))
      ) {
        setError('createTimeTo', {
          type: 'custom',
          message: t('general.validation.dateShouldBeLater.message'),
        });
      } else {
        clearErrors('createTimeTo');
      }

      if (
        value.expirationTimeFrom &&
        value.expirationTimeTo &&
        (isDateAfter(value.expirationTimeFrom, value.expirationTimeTo) ||
          isDateEqual(value.expirationTimeFrom, values.expirationTimeTo))
      ) {
        setError('expirationTimeTo', {
          type: 'custom',
          message: t('general.validation.dateShouldBeLater.message'),
        });
      } else {
        clearErrors('expirationTimeTo');
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (routerState?.legalEntity) {
      setLegalEntity();
    }
  }, []);

  useEffect(() => {
    (async function loadDictionaries() {
      getTransactionType();
      getLegality();
    })();
  }, [selectedLanguage]);

  const resetValues = () => {
    const formValues = { ...getValues() };
    Object.keys(formValues).map((key: string) => {
      values[key] = '';
      return false;
    });
    reset(values);
  };

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      resetValues();
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent="space-between"
          sx={{ mb: { xs: 2, md: 0 } }}
          spacing={1}
        >
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <StyledPresetSelect
              options={presets ? preparePresetsList(presets) : []}
              name="preset"
              control={control}
              setValue={(name: string, value: string) => {
                setValue('preset', value);
                handleResetField();
              }}
              onSelect={(val) => {
                if (!val) {
                  handleResetField();
                } else {
                  setPresetFilters(val, false, true);
                }
              }}
              errors={errors}
              withValidation={false}
              withLabel={false}
            />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12} sx={{ mb: { md: 1 } }}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant={EButtonVariants.outlined}
                label={t('transactionsList.advancedFilters.button')}
                onClick={() => setShowAdvanced(!showAdvanced)}
                fullWidth={!isDesktop}
                size={EButtonSizes.small}
              />
            </Box>
          </Grid>
        </Grid>

        {showAdvanced && (
          <StyledAdvancedSearch container spacing={2} columns={10}>
            <Grid item xl={2} lg={5} md={5} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(
                  transactionType?.filter(
                    (type) => type.uniqueId !== ETransactionTypes.DISABLED,
                  ) ?? null,
                )}
                name="transactionTypeUniqueId"
                setValue={setValue}
                label={t(ESearchFormTransactionLabels.transactionTypeUniqueId)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xl={2} lg={5} md={5} sm={5} xs={10}>
              <FormDateTimePicker
                name="transactionTimeFrom"
                label={t(ESearchFormTransactionLabels.transactionTimeFrom)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xl={2} lg={5} md={5} sm={5} xs={10}>
              <FormDateTimePicker
                name="transactionTimeTo"
                label={t(ESearchFormTransactionLabels.transactionTimeTo)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xl={2} lg={5} md={5} sm={5} xs={10}>
              <FormDateTimePicker
                name="createTimeFrom"
                label={t(ESearchFormTransactionLabels.createTimeFrom)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xl={2} lg={5} md={5} sm={5} xs={10}>
              <FormDateTimePicker
                name="createTimeTo"
                label={t(ESearchFormTransactionLabels.createTimeTo)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xl={2} lg={5} md={5} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(legality)}
                name="legalityUniqueId"
                setValue={setValue}
                label={t(ESearchFormTransactionLabels.legalityUniqueId)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xl={2} lg={5} md={5} sm={5} xs={10}>
              <FormDateTimePicker
                name="expirationTimeFrom"
                label={t(ESearchFormTransactionLabels.expirationTimeFrom)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xl={2} lg={5} md={5} sm={5} xs={10}>
              <FormDateTimePicker
                name="expirationTimeTo"
                label={t(ESearchFormTransactionLabels.expirationTimeTo)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xl={2} lg={5} md={5} sm={5} xs={10}>
              <FormSelect
                options={assetOptions}
                name="asset"
                setValue={setValue}
                label={t(ESearchFormTransactionLabels.asset)}
                control={control}
                errors={errors}
                translate={true}
              />
            </Grid>
            <Grid item xs={12} container columns={10} columnSpacing={2}>
              <Grid item xs={10} sm={5} lg={5} xl={8}>
                <StyledFormSelect
                  name="ownerUniqueId"
                  label={t(ESearchFormTransactionLabels.ownerName)}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  options={ownerOptions}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={10} sm={5} lg={5} xl={2}>
                <Button
                  variant={EButtonVariants.outlined}
                  size={EButtonSizes.small}
                  label={t('transactionsList.selectLegalEntity.button')}
                  fullWidth
                  sx={{
                    mt: {
                      sm: 3.5,
                    },
                  }}
                  onClick={() =>
                    navigate(ERouteLinks.SelectLegalEntity, {
                      state: {
                        type: ESelectLE.owner,
                        formValues: getValues(),
                        options: { keeperOptions, ownerOptions },
                        context: 'searchTransactions',
                        query: search,
                        breadcrumbs: [
                          {
                            label: [t('transactionsList.transactions.header')],
                            route: ERouteLinks.Transactions,
                          },
                          t('transactionsList.selectLegalEntity.button'),
                        ],
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columns={10} columnSpacing={2}>
              <Grid item xs={10} sm={5} lg={5} xl={8}>
                <StyledFormSelect
                  name="keeperUniqueId"
                  label={t(ESearchFormTransactionLabels.keeperName)}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  options={keeperOptions}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={10} sm={5} lg={5} xl={2}>
                <Button
                  variant={EButtonVariants.outlined}
                  size={EButtonSizes.small}
                  label={t('transactionsList.selectLegalEntity.button')}
                  fullWidth
                  sx={{
                    mt: {
                      sm: 3.5,
                    },
                  }}
                  onClick={() =>
                    navigate(ERouteLinks.SelectLegalEntity, {
                      state: {
                        type: ESelectLE.keeper,
                        formValues: getValues(),
                        options: { keeperOptions, ownerOptions },
                        context: 'searchTransactions',
                        query: search,
                        breadcrumbs: [
                          {
                            label: [t('transactionsList.transactions.header')],
                            route: ERouteLinks.Transactions,
                          },
                          t('transactionsList.selectLegalEntity.button'),
                        ],
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item xs={12} lg={4}>
                <Button
                  variant={EButtonVariants.contained}
                  size={EButtonSizes.small}
                  label={t('transactionsList.search.button')}
                  type="submit"
                  fullWidth
                  sx={{ mt: 3.5 }}
                  disabled={!!Object.keys(errors).length || isSearchButtonDisabled}
                />
              </Grid>
            </Grid>
          </StyledAdvancedSearch>
        )}
      </form>
      <SelectedFilters
        name="transaction"
        values={values}
        excludes={['preset', 'perPage', 'currentPage']}
        labels={ESearchFormTransactionLabels}
        handleDelete={handleResetField}
        type={ESearchFilterKeys.transaction}
        saveRoute={ERouteLinks.SaveTransactionPreset.replace(
          ':type',
          ESearchFilterKeys.transaction,
        )}
        dictionaries={{
          transactionTypeUniqueId: prepareFilters(transactionType, 'uniqueId', 'name'),
          legalityUniqueId: prepareFilters(legality, 'uniqueId', 'name'),
          ownerName: prepareFilters(ownerOptions, 'value', 'label'),
          keeperName: prepareFilters(keeperOptions, 'value', 'label'),
          asset: assetDictionary,
        }}
        updatedQueryPath={getNewQueryPath ? getNewQueryPath(getValues()) : undefined}
        dictionariesToSave={['ownerName', 'keeperName']}
        saveRouterStateAfterClearAll={false}
      />
    </SearchWrapper>
  );
};

export { TransactionSearchForm };

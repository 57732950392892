import React, { useEffect, useState } from 'react';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { useForm } from 'react-hook-form';
import { FormInput } from 'components/molecules/FormInput';
import { FormSelect } from 'components/molecules/FormSelect';
import { ITranslationKeys, SelectedFilters } from 'components/organisms/SelectedFilters';
import { prepareFilters } from 'helpers/filters';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { ELEAccordionsPageTypes, LEAccordionNames } from 'components/organisms/LEAccordions';
import {
  StyledAdvancedSearch,
  StyledPresetSelect,
} from 'pages/LegalEntitiesPage/IndividualSearchForm/styles';
import { Box } from '@mui/material';
import { ESearchFilterKeys, ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { ERouteLinks } from 'models/route';
import { useFilterPresets } from 'hooks/useFilterPresets';
import { useTranslations } from 'hooks/useTranslations';
import { useLocation, useSearchParams } from 'react-router-dom';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { ISearchFormCustomFieldValue } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import {
  ECustomFieldObjectType,
  ECustomFieldType,
  ICustomFieldByObjectType,
} from 'models/customField';
import FormCustomField from 'components/organisms/FormCustomField';
import { isEmpty } from 'utils/search';
import { formatDateTimeToIso } from 'utils/date';
import { getSearchFilter } from 'requests/searchFilter';
import { ISearchParams } from 'pages/UsersPage';
import { isPathEqualToRouteName } from 'utils/route';
import { EPerPages } from 'models/table';

export interface ISearchFormValues {
  preset: string | undefined;
  name: string;
  organizationTypeUniqueId: string;
  departmentName: string;
  city: string;
  visibilityUniqueId: string;
  customFields?: ISearchFormCustomFieldValue[];

  [key: string]: any;
}

export interface ISearchFormILEValues {
  preset: string | undefined;
  firstname: string;
  lastName: string;
  city: string;
  birthdayFrom: string | number | Date | null;
  birthdayTo: string | number | Date | null;
  visibilityUniqueId: string;
  genderUniqueId: string;
  customFields?: ISearchFormCustomFieldValue[];
  prefixGroupUniqueId: string;
  invokedByPageChange?: boolean;
}

export enum ESearchFormLabels {
  name = 'searchForLegalEntity.legalEntityTable.name.column',
  organizationTypeUniqueId = 'searchForLegalEntity.legalEntityTable.organisationType.column',
  departmentName = 'searchForLegalEntity.legalEntityTable.departmentName.column',
  city = 'searchForLegalEntity.legalEntityTable.city.column',
  visibilityUniqueId = 'searchForLegalEntity.legalEntityTable.visibility.column',
  legalEntityType = 'searchForLegalEntity.legalEntityTable.LegalEntityType.column',
}

export const initialValues: ISearchFormValues = {
  preset: '',
  name: '',
  organizationTypeUniqueId: '',
  departmentName: '',
  city: '',
  visibilityUniqueId: '',
  customFields: [],
};

interface IOrganisationSearchForm {
  onSubmit: (arg: ISearchFormValues) => void;
  getNewQueryPath?: (arg: ISearchFormValues) => string;
  type: ELEAccordionsPageTypes;
  translationKeys?: ITranslationKeys;
  initialFormValues: ISearchFormValues;
  isSearchParams?: boolean;
  isSearchButtonDisabled?: boolean;
  saveRouterStateAfterClearAll: boolean;
  currentPage?: number;
  perPage?: EPerPages;
}

const customFieldWrapper = (children: React.ReactNode) => (
  <Grid item lg={2} md={5} sm={5} xs={10}>
    {children}
  </Grid>
);

const OrganisationSearchForm = ({
  onSubmit,
  getNewQueryPath,
  type,
  translationKeys,
  initialFormValues = initialValues,
  isSearchParams = false,
  isSearchButtonDisabled = false,
  saveRouterStateAfterClearAll,
  perPage,
  currentPage,
}: IOrganisationSearchForm) => {
  const {
    loadDictionaries,
    organizationType,
    legalVisibility,
    getCustomField,
    customFieldsOLE,
  } = useLegalEntities();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(isSearchParams);
  const [values, setValues] = useState<ISearchFormValues>(initialFormValues);
  const [customFields, setCustomFields] = useState<ICustomFieldByObjectType[]>([]);
  const { state: routerState, pathname }: any = useLocation();
  const [showSaveFilter, setShowSaveFilter] = useState(false);
  const [searchParams] = useSearchParams();
  const expand = searchParams.get('expand');
  const presetId = searchParams.get('preset');
  const [isInitialFormLoad, setIsInitialFormLoad] = useState(true);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ISearchFormValues>({
    defaultValues: initialFormValues,
  });

  const { isDesktop } = useThemeBreakpoint();
  const { getFilterPresets, presets, preparePresetsList, getPresetValues } =
    useFilterPresets();
  const watchPreset = watch('preset');
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();

  const setPresetFilters = async (
    selectedPreset: string,
    isFromEmailLink: boolean,
    isClickedManually: boolean = false,
  ) => {
    const handleValues = async () => {
      if (isFromEmailLink) {
        const filter = await getSearchFilter(selectedPreset);
        if (filter) {
          const queryValues: ISearchParams = {};
          filter.values.forEach((it) => {
            queryValues[it.key] = it.value;
          });
          return queryValues;
        }
      }
      return getPresetValues(selectedPreset);
    };

    if (selectedPreset) {
      setShowAdvanced(true);
    }

    const presetValues = {
      ...(isClickedManually ? initialValues : initialFormValues),
      preset: selectedPreset,
      ...(await handleValues()),
    };

    setValues(presetValues);
    reset(presetValues);
    await handleSubmit(onSubmit)();
  };

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      const formValues = { ...getValues() };

      Object.keys(formValues).map((key: string) => {
        if (typeof values[key] === 'string') {
          values[key] = '';
        } else if (typeof values[key] === 'object') {
          values[key] = null;
        }
        return false;
      });
      reset(values);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  useEffect(() => {
    if (!isInitialFormLoad) {
      handleSubmit((val) => onSubmit({ ...val, invokedByPageChange: true }))();
    } else {
      setIsInitialFormLoad(false);
    }
  }, [perPage, currentPage]);

  useEffect(() => {
    (async function init() {
      await getFilterPresets(ESearchFilterTypes.organisation);
      if (routerState?.presetOrganisation) {
        await setPresetFilters(routerState?.presetOrganisation, true, false);
      }
      if (presetId && expand === LEAccordionNames.ORGANISATION) {
        await setPresetFilters(presetId, true, false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await loadDictionaries([
        EDictionaryTypes.OrganizationType,
        EDictionaryTypes.LegalVisibility,
      ]);
      await getCustomField(ECustomFieldObjectType.LE_ORGANIZATION);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    setCustomFields(
      customFieldsOLE.filter((item: ICustomFieldByObjectType) => item.searchCriteria),
    );
  }, [customFieldsOLE]);

  useEffect(() => {
    (async function init() {
      if (watchPreset) {
        await setPresetFilters(watchPreset, false, false);
      } else {
        await setPresetFilters('', false, false);
      }
      await handleSubmit(onSubmit)();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isEmpty(values.name) &&
      isEmpty(values.departmentName) &&
      isEmpty(values.city) &&
      isEmpty(values.organizationTypeUniqueId) &&
      isEmpty(values.visibilityUniqueId)
    ) {
      setShowSaveFilter(false);
    } else {
      setShowSaveFilter(true);
    }
  }, [values]);

  const prepareData = (data: ISearchFormValues): ISearchFormValues => {
    const customFieldsPayload: ISearchFormCustomFieldValue[] = [];

    Object.keys(data).map((key) => {
      const isDateCF = key.startsWith('from_') || key.startsWith('to_');

      if (!isDateCF) {
        const customField = customFieldsOLE.find((item) => item.name === key);
        if (customField && data[key]) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (customField.customFieldType === ECustomFieldType.TEXT) {
            if (data[key] === undefined || data[key] === null) {
              customFieldItem.text = undefined;
            } else if (data[key].trim() === '') {
              customFieldItem.text = undefined;
            } else {
              customFieldItem.text = data[key];
            }
          } else if (customField.customFieldType === ECustomFieldType.BOOL) {
            customFieldItem.bool = data[key] === '1';
          }

          customFieldsPayload.push(customFieldItem);
          delete data[key];
        }
      } else {
        const customFieldKey = key.startsWith('from_')
          ? key.replace('from_', '')
          : key.replace('to_', '');

        const customField = customFieldsOLE.find((item) => item.name === customFieldKey);

        if (customField && (data[`from_${customFieldKey}`] || data[`to_${customFieldKey}`])) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (data[`from_${customFieldKey}`]) {
            customFieldItem.dateFrom =
              data[`from_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`from_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          if (data[`to_${customFieldKey}`]) {
            customFieldItem.dateTo =
              data[`to_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`to_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          customFieldsPayload.push(customFieldItem);
          if (data[`from_${customFieldKey}`]) {
            delete data[`from_${customFieldKey}`];
          }
          if (data[`to_${customFieldKey}`]) {
            delete data[`to_${customFieldKey}`];
          }
        }
      }
      return false;
    });

    if (customFieldsPayload?.length) {
      data.customFields = customFieldsPayload;
    } else {
      delete data.customFields;
    }
    return data;
  };

  const submitForm = (data: ISearchFormValues) => {
    onSubmit(prepareData(data));
  };

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (type === ELEAccordionsPageTypes.UserManagement) {
      setShowAdvanced(true);
    }
  }, [type]);

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(submitForm)}>
        {(type === ELEAccordionsPageTypes.LegalEntity ||
          type === ELEAccordionsPageTypes.SelectLegalEntity) && (
          <Grid
            container
            rowSpacing={1}
            justifyContent="space-between"
            sx={{ mb: { xs: 2, md: 0 } }}
          >
            <Grid item md={6} sm={12} xs={12}>
              <StyledPresetSelect
                options={presets ? preparePresetsList(presets) : []}
                name="preset"
                control={control}
                setValue={(name: string, value: string) => {
                  setValue('preset', value);
                  handleResetField();
                }}
                onSelect={(val) => {
                  if (!val) {
                    handleResetField();
                  } else {
                    setPresetFilters(val, false, true);
                  }
                }}
                errors={errors}
                withValidation={false}
                withLabel={false}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant={EButtonVariants.outlined}
                  label={t('legalEntities.organization.accordion.advancedFilters.button')}
                  id="legal_entities-organisation-advanced_filters"
                  onClick={() => setShowAdvanced(!showAdvanced)}
                  fullWidth={!isDesktop}
                  size={EButtonSizes.small}
                />
              </Box>
            </Grid>
          </Grid>
        )}

        {showAdvanced && (
          <>
            <StyledAdvancedSearch
              container
              justifyContent="space-between"
              spacing={2}
              columns={10}
            >
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormInput
                  name="name"
                  label={t('legalEntities.organization.accordion.name.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormSelect
                  options={mapDictionaryToOptionProp(organizationType)}
                  setValue={setValue}
                  name="organizationTypeUniqueId"
                  label={t('legalEntities.organization.accordion.organizationType.label')}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormInput
                  name="departmentName"
                  label={t('legalEntities.organization.accordion.departmentName.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormInput
                  name="city"
                  label={t('legalEntities.organization.accordion.city.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormSelect
                  options={mapDictionaryToOptionProp(legalVisibility)}
                  name="visibilityUniqueId"
                  setValue={setValue}
                  label={t('legalEntities.organization.accordion.visibility.label')}
                  control={control}
                  errors={errors}
                />
              </Grid>

              {customFields.map((customField) => (
                <FormCustomField
                  data={customField}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  key={customField.uniqueId}
                  wrapper={customFieldWrapper}
                  isSearch={true}
                />
              ))}

              {new Array(5 - customFields.length >= 0 ? 5 - customFields.length : 0)
                .fill('')
                .map((index) => (
                  <Grid item lg={2} md={5} sm={5} xs={10} key={index} />
                ))}
            </StyledAdvancedSearch>
            <Grid container justifyContent="space-between" sx={{ mb: { xs: 2, md: 0 } }}>
              <Grid item md={6} sm={12} xs={12} />
              <Grid item md={4} sm={12} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant={EButtonVariants.contained}
                    label={t('legalEntities.organization.accordion.search.button')}
                    id="legal_entities-organisation-search"
                    type="submit"
                    size={EButtonSizes.small}
                    fullWidth={!isDesktop}
                    disabled={isSearchButtonDisabled}
                    sx={{
                      minWidth: {
                        lg: '240px !important',
                        xs: undefined,
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </form>
      <SelectedFilters
        name="organisation"
        values={values}
        excludes={['preset', 'customFields']}
        labels={ESearchFormLabels}
        updatedQueryPath={
          getNewQueryPath ? getNewQueryPath(prepareData(getValues())) : undefined
        }
        type={ESearchFilterKeys.organisation}
        saveRoute={ERouteLinks.SavePreset.replace(':type', ESearchFilterKeys.organisation)}
        dictionaries={{
          visibilityUniqueId: prepareFilters(legalVisibility, 'uniqueId', 'name'),
          organizationTypeUniqueId: prepareFilters(organizationType, 'uniqueId', 'name'),
        }}
        handleDelete={handleResetField}
        showSaveFilters={
          showSaveFilter &&
          !isPathEqualToRouteName(pathname, ERouteLinks.SelectLegalEntity) &&
          !isPathEqualToRouteName(pathname, ERouteLinks.AssignLESearch)
        }
        translationKeys={translationKeys}
        customFields={customFieldsOLE}
        saveRouterStateAfterClearAll={saveRouterStateAfterClearAll}
      />
    </SearchWrapper>
  );
};

export { OrganisationSearchForm };

import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { IAuditLogItemResponse } from 'models/auditLog';
import { getRegisteredFirearm } from 'requests/firearm';
import { IRegisteredFirearmData } from 'models/registeredFirearm';
import { RegisteredFirearmDetails } from 'components/organisms/RegisteredFirearmDetails';
import { AuditLogEventName } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { useTranslations } from 'hooks/useTranslations';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { EAuditLogEvents } from 'pages/AuditLogPage/AuditLogViewPage/index';
import { IFRTDetails, IFRTSpecifications } from 'models/frt';
import { useFRT } from 'pages/FRT/hooks';
import { Box } from '@mui/system';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteCommentPage/styles';
import Grid from '@mui/material/Grid';
import { isFRTLocal } from 'pages/FRT/helpers';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { emptyFieldHolder } from '../../../components/molecules/CardList';

interface IFirearmEventDetails {
  auditLog: IAuditLogItemResponse;
}

const FirearmEventDetails = ({ auditLog }: IFirearmEventDetails) => {
  const [firearm, setFirearm] = useState<IRegisteredFirearmData | null>();
  const [previousFirearm, setPreviousFirearm] = useState<IRegisteredFirearmData | null>();
  const [previousFrt, setPreviousFrt] = useState<IFRTDetails | null>();
  const [previousFrtSpecifications, setPreviousFrtSpecifications] = useState<
    IFRTSpecifications[] | null
  >();
  const { hasPermissions } = usePermission();
  const { t } = useTranslations();
  const { getFRTDetailsData, FRTDetails, getFRTSpecificationsData, FRTSpecifications } =
    useFRT();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      auditLog.eventNameUniqueId === EAuditLogEvents.ADD_FIREARM_TO_FRT_LOCAL ||
      auditLog.eventNameUniqueId === EAuditLogEvents.EDIT_FIREARM_FROM_FRT_LOCAL
    ) {
      if (auditLog?.previousObjectValue) {
        (async () => {
          const previousObjectValue = auditLog.previousObjectValue as any;
          const previousFrtObjectValue = previousObjectValue.frt as any;
          const previousSpecificationsObjectValue = previousObjectValue.specifications as any;
          const previousFrtData: IFRTDetails = {
            action: previousFrtObjectValue.action || '',
            country: previousFrtObjectValue.country || '',
            frtId: previousFrtObjectValue.frtId || '',
            id: previousFrtObjectValue.uniqueId || '',
            isLocal: previousFrtObjectValue.isLocal || '',
            legalClassification: previousFrtObjectValue.legalClassification || '',
            level: previousFrtObjectValue.level || '',
            make: previousFrtObjectValue.make || '',
            manufacturer: previousFrtObjectValue.manufacturer || '',
            model: previousFrtObjectValue.model || '',
            productCode: previousFrtObjectValue.productCode || '',
            productCodes: previousFrtObjectValue.productCodes || [],
            type: previousFrtObjectValue.type || '',
          };
          setPreviousFrtSpecifications(previousSpecificationsObjectValue);
          setPreviousFrt(previousFrtData);
        })();
      }
      (async () => {
        if (auditLog?.objectUniqueId && hasPermissions([EPermission.MANAGE_FRT_FIREARM])) {
          try {
            await Promise.all([
              getFRTDetailsData(auditLog.objectUniqueId),
              getFRTSpecificationsData(auditLog.objectUniqueId),
            ]);
          } catch (e) {
            console.error(e);
          }
        }
      })();
    } else {
      if (auditLog?.previousObjectValue) {
        (async () => {
          const previousObjectValue = auditLog?.previousObjectValue as any;
          const previousFirearmData: IRegisteredFirearmData = {
            keeperVisibilityUniqueId: '',
            ownerVisibilityUniqueId: '',
            action: previousObjectValue.action || '',
            activityUniqueId: previousObjectValue.activityUniqueId || '',
            caliber: previousObjectValue.caliber || '',
            countryOfExport: previousObjectValue.countryOfExport || '',
            countryOfImport: previousObjectValue.countryOfImport || '',
            customFields: previousObjectValue.customFields || [],
            exhibitNumber: previousObjectValue.exhibitNumber || '',
            externalLocalReferenceNumber:
              previousObjectValue.externalLocalReferenceNumber || '',
            externalReferenceNumber: previousObjectValue.externalReferenceNumber || '',
            fireCycleId: previousObjectValue.fireCycleId || '',
            frtId: previousObjectValue.frtId || '',
            frtUniqueId: previousObjectValue.frtUniqueId || '',
            groupName: previousObjectValue.groupName || '',
            importer: previousObjectValue.importer || '',
            keeper: previousObjectValue.keeper || '',
            keeperId: previousObjectValue.keeperId || '',
            keeperType: previousObjectValue.keeperType || '',
            latitude: previousObjectValue.latitude || '',
            legality: previousObjectValue.legality || '',
            legalityUniqueId: previousObjectValue.legalityUniqueId || '',
            longitude: previousObjectValue.longitude || '',
            make: previousObjectValue.make || '',
            manufacturedAt: previousObjectValue.manufacturedAt || '',
            manufacturer: previousObjectValue.manufacturer || '',
            model: previousObjectValue.model || '',
            owner: previousObjectValue.owner || '',
            ownerId: previousObjectValue.ownerId || '',
            ownerType: previousObjectValue.ownerType || '',
            serialNumber: previousObjectValue.serialNumber || '',
            state: previousObjectValue.state || '',
            stateUniqueId: previousObjectValue.stateUniqueId || '',
            type: previousObjectValue.type || '',
            version: previousObjectValue.version || '',
            uniqueId: '',
          };
          setPreviousFirearm(previousFirearmData);
        })();
      }
      (async () => {
        if (auditLog?.objectUniqueId && hasPermissions([EPermission.VIEW_ALL_FIREARMS])) {
          const response = await getRegisteredFirearm(auditLog.objectUniqueId);
          if (response) {
            setFirearm(response);
          }
        }
      })();
    }
  }, [auditLog]);

  const isPreviousFrtLocal: boolean = isFRTLocal(previousFrt?.id || '');
  const isFrtLocal: boolean = isFRTLocal(FRTDetails?.id || '');

  let previousDetailsTitle = '';
  if (previousFrt !== null && previousFrt !== undefined) {
    const { make, model, action } = previousFrt;
    previousDetailsTitle = `${make}, ${model}, ${action}`;
  }

  let frtDetailsTitle = '';
  if (FRTDetails !== null) {
    const { make, model, action } = FRTDetails;
    frtDetailsTitle = `${make}, ${model}, ${action}`;
  }

  const previousFrtCalibres =
    previousFrtSpecifications?.map((spec) => spec.caliber).join(', ') || '-';
  const frtCalibres = FRTSpecifications.map((spec) => spec.caliber).join(', ') || '-';

  return (
    <>
      {previousFrt && (
        <>
          <AuditLogEventName sx={{ marginTop: '20px' }}>
            <Typography variant="h5">
              {isPreviousFrtLocal
                ? `${t('firearmsReferenceTable.editFirearm.viewLocalFirearm.header')} ${t(
                    'auditLogDetails.before.event.title',
                  )}`
                : `${t('firearmsReferenceTable.editFirearm.viewFirearm.header')} ${t(
                    'auditLogDetails.before.event.title',
                  )}`}
            </Typography>
          </AuditLogEventName>
          <DetailsTemplate
            title={previousDetailsTitle}
            pageType={EDetailsPageTypes.auditLogView}
            hasLongTitle={true}
            disableActions
          >
            <Grid item xs={12} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('firearmsReferenceTable.editFirearm.calibres.label')}
              </Typography>
              <Typography variant="body2">{previousFrtCalibres}</Typography>
            </Grid>
          </DetailsTemplate>
          <Box mb={2}>
            <StyledDetailsWrapper container rowGap={3}>
              <Grid item xs={12} sm={6} lg={2.4}>
                <Typography variant="subtitle2">
                  {isPreviousFrtLocal
                    ? t(
                        'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceLocalID.label',
                      )
                    : t(
                        'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceNo.label',
                      )}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '4px' }}>
                  {isPreviousFrtLocal ? previousFrt.frtId : previousFrt.id}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={2.4}>
                <Typography variant="subtitle2">
                  {t(
                    'firearmsReferenceTable.editFirearm.general.accordion.manufacturer.label',
                  )}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '4px' }}>
                  {emptyFieldHolder(previousFrt.manufacturer)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={2.4}>
                <Typography variant="subtitle2">
                  {t('firearmsReferenceTable.editFirearm.general.accordion.level.label')}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '4px' }}>
                  {emptyFieldHolder(previousFrt.level)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={2.4}>
                <Typography variant="subtitle2">
                  {t(
                    'firearmsReferenceTable.editFirearm.general.accordion.countryOfManufacturer.label',
                  )}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '4px' }}>
                  {emptyFieldHolder(previousFrt.country)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={2.4}>
                <Typography variant="subtitle2">
                  {t('firearmsReferenceTable.editFirearm.general.accordion.productCode.label')}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '4px' }}>
                  {previousFrt.productCodes.length > 0
                    ? previousFrt.productCodes.join(', ')
                    : '-'}
                </Typography>
              </Grid>
            </StyledDetailsWrapper>
          </Box>
        </>
      )}

      {FRTDetails && (
        <>
          <AuditLogEventName sx={{ marginTop: '20px' }}>
            <Typography variant="h5">
              {isFrtLocal
                ? `${t('firearmsReferenceTable.editFirearm.viewLocalFirearm.header')} ${t(
                    'auditLogDetails.current.data.event.title',
                  )}`
                : `${t('firearmsReferenceTable.editFirearm.viewFirearm.header')} ${t(
                    'auditLogDetails.current.data.event.title',
                  )}`}
            </Typography>
          </AuditLogEventName>
          <DetailsTemplate
            title={frtDetailsTitle}
            pageType={EDetailsPageTypes.auditLogView}
            hasLongTitle={true}
            redirectToView={
              isFrtLocal
                ? () => navigate(ERouteLinks.FRTDetails.replace(':frtId', FRTDetails.id))
                : undefined
            }
          >
            <Grid item xs={12} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('firearmsReferenceTable.editFirearm.calibres.label')}
              </Typography>
              <Typography variant="body2">{frtCalibres}</Typography>
            </Grid>
          </DetailsTemplate>
          <Box mb={2}>
            <StyledDetailsWrapper container rowGap={3}>
              <Grid item xs={12} sm={6} lg={2.4}>
                <Typography variant="subtitle2">
                  {isFrtLocal
                    ? t(
                        'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceLocalID.label',
                      )
                    : t(
                        'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceNo.label',
                      )}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '4px' }}>
                  {isFrtLocal ? FRTDetails.frtId : FRTDetails.id}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={2.4}>
                <Typography variant="subtitle2">
                  {t(
                    'firearmsReferenceTable.editFirearm.general.accordion.manufacturer.label',
                  )}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '4px' }}>
                  {emptyFieldHolder(FRTDetails.manufacturer)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={2.4}>
                <Typography variant="subtitle2">
                  {t('firearmsReferenceTable.editFirearm.general.accordion.level.label')}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '4px' }}>
                  {emptyFieldHolder(FRTDetails.level)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={2.4}>
                <Typography variant="subtitle2">
                  {t(
                    'firearmsReferenceTable.editFirearm.general.accordion.countryOfManufacturer.label',
                  )}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '4px' }}>
                  {emptyFieldHolder(FRTDetails.country)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={2.4}>
                <Typography variant="subtitle2">
                  {t('firearmsReferenceTable.editFirearm.general.accordion.productCode.label')}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: '4px' }}>
                  {FRTDetails.productCodes.length > 0
                    ? FRTDetails.productCodes.join(', ')
                    : '-'}
                </Typography>
              </Grid>
            </StyledDetailsWrapper>
          </Box>
        </>
      )}

      {previousFirearm && auditLog?.eventNameUniqueId !== EAuditLogEvents.ADD_FIREARM && (
        <>
          <AuditLogEventName>
            <Typography variant="h5">
              {`${t('auditLogDetails.firearmsDetails.title')} ${t(
                'auditLogDetails.before.event.title',
              )}`}
            </Typography>
          </AuditLogEventName>
          <RegisteredFirearmDetails
            firearm={previousFirearm}
            pageType={EDetailsPageTypes.auditLogView}
            disableActions={!previousFirearm.uniqueId}
          />
        </>
      )}

      {firearm && (
        <AuditLogEventName sx={{ marginTop: '20px' }}>
          <Typography variant="h5">
            {`${t('auditLogDetails.firearmsDetails.title')} ${t(
              'auditLogDetails.current.data.event.title',
            )}`}
          </Typography>
        </AuditLogEventName>
      )}

      {firearm && (
        <RegisteredFirearmDetails
          firearm={firearm}
          pageType={EDetailsPageTypes.auditLogView}
          disableActions={!firearm.uniqueId}
        />
      )}
    </>
  );
};

export default FirearmEventDetails;

import React, { useEffect, useRef, useState } from 'react';
import Breadcrumbs, { IBreadcrumbsLink } from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { ERouteLinks } from 'models/route';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { Accordion } from 'components/molecules/Accordion';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { Typography } from '@mui/material';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteCommentPage/styles';
import { getLegalEntity, getLegalType, getOrgLegalEntity } from 'requests/legalEntities';
import { ILegalEntityData } from 'components/organisms/LegalEntityDetails';
import { Loader } from 'components/atoms/Loader';
import { IOrgLegalEntityData } from 'components/organisms/OrgLegalEntityDetails';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { colors } from 'theme/colors';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { EIconTypes } from 'constants/Icons';
import { Alert, EAlertVariants, IAlert } from 'components/atoms/Alert';
import { FormInput } from 'components/molecules/FormInput';
import {
  StyledIconWrapper,
  StyledLocationMarkConfirmation,
} from 'pages/LegalEntitiesPage/LegalEntityAddTransactionForGroupPage/styles';
import Icon from 'components/atoms/Icon/Icon';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDictionary } from 'models/dictionaryContext';
import { EDictionaryTypes, IDictionary, ITranslation } from 'models/dictionary';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { EMIMETypes } from 'constants/MIMETypes';
import {
  createMiddleTransaction,
  createMiddleTransactionForGroup,
  getFirearmLastTransaction,
  getGroupLastTransaction,
  getPossibleTransactionType,
  getPossibleTransactionTypeForGroup,
} from 'requests/transaction';
import { ETransactionTypes } from 'constants/TransactionTypes';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useTranslations } from 'hooks/useTranslations';
import { useLang } from 'models/langContext';
import { formatDate, formatDateTimeToIso } from 'utils/date';
import { FormDateTimePicker } from 'components/molecules/FormDateTimePicker';
import {
  getFirearmGroupDetails,
  getGroupLastTransactionUniqueId,
  getLastFirearmTransactionUniqueId,
  getRegisteredFirearm,
} from 'requests/firearm';
import { isPathEqualToRouteName } from 'utils/route';
import { IRegisteredFirearmData } from 'models/registeredFirearm';
import { EFirearmStateId, IFirearm, IFirearmGroup } from 'models/firearm';
import { ECustomFieldObjectType, ECustomFieldType } from 'models/customField';
import { useFRT } from 'pages/FRT/hooks';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useScroll } from 'hooks/useScroll';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useDocumentsGeneratingSetup } from 'pages/SystemSettingsPage/DocumentsGeneratingSetupPage/hooks';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { removeFileExtension } from 'utils/removeFileExtension';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { StyledLegalLink } from 'components/atoms/StyledLegalLink';
import { FormWyswigEditor } from 'components/molecules/FormWyswigEditor';
import { countEditorCharacters } from 'components/organisms/HtmlSlate';

const MAX_FILE_SIZE_IN_MB = 3;

let DISABLED_FOR_TRANSACTION_FORM = [ETransactionTypes.DISABLED];

const TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS = [
  ETransactionTypes.SURRENDERED,
  ETransactionTypes.SEIZED,
  ETransactionTypes.RECOVERED,
];

const CHANGE_LE_REQUIRED_TRANSACTION_TYPES = [
  ETransactionTypes.DECLARED_IMPORTED,
  ETransactionTypes.RECOVERED,
  ETransactionTypes.SEIZED,
  ETransactionTypes.SOLD,
  ETransactionTypes.GIVEN,
  ETransactionTypes.LEASED,
  ETransactionTypes.ASSIGNED_TEMPORARILY,
  ETransactionTypes.ASSIGNED_INDEFINITELY,
  ETransactionTypes.TRANSFERRED,
  ETransactionTypes.SURRENDERED,
  ETransactionTypes.RETURNED,
];

export enum LETypes {
  individual = 'INDIVIDUAL',
  organization = 'ORGANIZATION',
}

const filterDisabled = (
  transactions: IDictionary[] | null,
  legalEntityIndividualKeeper: ILegalEntityData | null | undefined,
): IDictionary[] | null => {
  if (transactions === null) {
    return null;
  }
  if (legalEntityIndividualKeeper !== null && legalEntityIndividualKeeper !== undefined) {
    DISABLED_FOR_TRANSACTION_FORM = [ETransactionTypes.DISABLED];
  } else {
    DISABLED_FOR_TRANSACTION_FORM = [ETransactionTypes.DISABLED, ETransactionTypes.GIVEN];
  }
  return transactions.filter(
    (transaction) =>
      !DISABLED_FOR_TRANSACTION_FORM.map((disabled) => disabled.toString()).includes(
        transaction.uniqueId.toString(),
      ),
  );
};

const LegalEntityAddTransactionForGroupPage = () => {
  const navigate = useNavigate();
  const { state: routerState, pathname }: any = useLocation();
  const { loadInitData, eTraceEnabled, iArmsEnabled } = useDocumentsGeneratingSetup();
  const { id = '', groupId = '' } = useParams<{
    id: string;
    groupId: string;
    isTransactionForGroup: string;
  }>();
  const { elRef, scrollToError } = useScroll();

  const { firearm = {} } = routerState || {};
  const isTransactionForGroup = groupId === '' ? '0' : '1';
  const isMiddleTransactionPage = isPathEqualToRouteName(
    pathname,
    ERouteLinks.MiddleTransaction,
  );
  const isGroupTransactionPage = isPathEqualToRouteName(
    pathname,
    ERouteLinks.LegalEntityAddTransactionForGroup,
  );

  const { shortDateFormat } = useGlobalProperty();
  const { getFRTDetailsData, FRTDetails } = useFRT();

  const [frtTitleDetails, setFrtTitleDetails] = useState<string>('');
  const [isFrtInformationVisible, setIsFrtInformationVisible] = useState<boolean>(false);
  const [firearmGroup, setFirearmGroup] = useState<IFirearmGroup | null>(null);
  const [submitBlocked, setSubmitBlocked] = useState(false);
  const [registeredFirearm, setRegisteredFirearm] = useState<IRegisteredFirearmData | null>(
    null,
  );
  const [lastTransactionUniqueId, setLastTransactionUniqueId] = useState<string | null>();
  const [type, setType] = useState<'INDIVIDUAL' | 'ORGANIZATION' | null>(null);

  const initialFormValues = {
    typeUniqueId: routerState?.typeUniqueId || '',
    transactionDate: routerState?.transactionDate || '',
    firearmsQuantity: routerState?.firearmsQuantity || '',
    serialNumber: routerState?.serialNumber || '',
    externalReferenceNumber: routerState?.externalReferenceNumber || '',
    manufacturedAt: routerState?.manufacturedAt || '',
    countryOfImportUniqueId: routerState?.countryOfImportUniqueId || '',
    importer: routerState?.importer || '',
    countryOfExportUniqueId: routerState?.countryOfExportUniqueId || '',
    expirationDate: routerState?.expirationDate || '',
    expirationTime: routerState?.expirationTime || '',
    attachments: routerState?.attachments?.length ? routerState?.attachments : [],
    legalEntityFullName: routerState?.legalEntityFullName || '',
    legalEntityId: routerState?.legalEntityId || '',
    description: routerState?.description || '',
    address: routerState?.address || '',
  };

  const { t } = useTranslations();

  const schema = yup.object().shape({
    typeUniqueId: yup.string().required(t('general.field.validation.required')),
    attachments: yup.array().of(
      yup.object({
        attachmentName: yup
          .string()
          .test('unique', t('general.field.validation.unique'), (value, params) => {
            // @ts-ignore
            const attachments = params?.from[1]?.value.attachments ?? [];
            if (!value) return true;
            if (attachments.filter((r: any) => r.attachmentName === value).length > 1) {
              return params.createError({
                path: params.path,
                message: t('general.field.validation.unique'),
              });
            }
            return true;
          })
          .required(t('general.field.validation.required'))
          .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
          .matches(/^[a-zA-Z0-9_ -]+$/, t('general.field.validation.attachmentName'))
          .trim(),
      }),
    ),
    transactionDate: yup
      .string()
      .required(t('general.field.validation.required'))
      .test(
        'date-validation',
        t('legalEntity.validation.maxDate').replace('{0}', '255'),
        (value) => {
          if (typeof value !== 'string') {
            return false;
          }
          const maxValidDate = new Date();
          const passedDate = new Date(value);
          return passedDate.valueOf() <= maxValidDate.valueOf();
        },
      ),
    legalEntityFullName: yup.string().when('typeUniqueId', {
      is: (typeVar: any) => CHANGE_LE_REQUIRED_TRANSACTION_TYPES.includes(typeVar),
      then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
    }),
    countryOfImportUniqueId: yup.string().when('typeUniqueId', {
      is: ETransactionTypes.DECLARED_IMPORTED,
      then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
    }),
    countryOfExportUniqueId: yup.string().when('typeUniqueId', {
      is: ETransactionTypes.EXPORTED,
      then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
    }),
    description: yup
      .string()
      .test(
        'editor-validation',
        t('general.field.validation.maxCharacters').replace('{0}', '1000'),
        (value) => countEditorCharacters(value) <= 1000,
      )
      .when('typeUniqueId', {
        is: ETransactionTypes.MODIFIED,
        then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
      }),
    address: yup.string(),
    importer: yup
      .string()
      .max(250, t('general.field.validation.maxCharacters').replace('{0}', '250')),
    expirationTime: yup
      .string()
      .typeError(t('general.field.validation.required'))
      .when('typeUniqueId', {
        is: (typeVar: any) =>
          [ETransactionTypes.ASSIGNED_TEMPORARILY, ETransactionTypes.LEASED].includes(typeVar),
        then: (yupSchema) =>
          yupSchema
            .required(t('general.field.validation.required'))
            .test(
              'expiration-before-transaction-date-validation',
              t('legalEntity.validation.expirationDateValidation').replace('{0}', '255'),
              (value, context) => {
                if (typeof value !== 'string') {
                  return false;
                }
                return (
                  new Date(value).valueOf() >=
                  new Date(context.parent.transactionDate).valueOf()
                );
              },
            ),
      }),
    expirationDate: yup
      .string()
      .typeError(t('general.field.validation.required'))
      .when('typeUniqueId', {
        is: (typeVar: any) => [ETransactionTypes.TEST_FIRED].includes(typeVar),
        then: (yupSchema) =>
          yupSchema
            .required(t('general.field.validation.required'))
            .test(
              'expiration-before-transaction-date-validation',
              t('legalEntity.validation.expirationDateValidation').replace('{0}', '255'),
              (value, context) => {
                if (typeof value !== 'string') {
                  return false;
                }
                return (
                  new Date(value).valueOf() >=
                  new Date(context.parent.transactionDate).valueOf()
                );
              },
            ),
      }),
  });

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    getValues,
    clearErrors,
  } = useForm({ defaultValues: initialFormValues, resolver: yupResolver(schema) });
  const attachments = useFieldArray({
    control,
    name: 'attachments',
  });

  const [legalEntityIndividualKeeper, setLegalEntityIndividualKeeper] =
    useState<ILegalEntityData | null>();
  const [legalEntityOrganisationKeeper, setLegalEntityOrganisationKeeper] =
    useState<IOrgLegalEntityData | null>();
  const [legalEntityIndividualOwner, setLegalEntityIndividualOwner] =
    useState<ILegalEntityData | null>();
  const [legalEntityOrganisationOwner, setLegalEntityOrganisationOwner] =
    useState<IOrgLegalEntityData | null>();
  const [isLegalEntityLoading, setIsLegalEntityLoading] = useState<boolean>(false);
  const [isErrorLocation, setIsErrorLocation] = useState<boolean>(false);
  const { loadDictionaries, country, getCustomField } = useLegalEntities();
  const { isDesktop } = useThemeBreakpoint();
  const { transactionMiddleTypes } = useDictionary();
  const { setAlert } = useAlert();
  const [createTransactionError, setCreateTransactionError] = useState<string>('');
  const { selectedLanguage } = useLang();
  const [possibleTransactionDictionaries, setPossibleTransactionDictionaries] = useState<
    IDictionary[] | null
  >(null);
  const [showCurrentOwnerAndKeeper, setShowCurrentOwnerAndKeeper] = useState(false);
  const [isCurrentOwnerLoaded, setIsCurrentOwnerLoaded] = useState(false);
  const [isCurrentKeeperLoaded, setIsCurrentKeeperLoaded] = useState(false);

  const transactionTypeValue = watch('typeUniqueId');
  const isShowTracingDocumentsGeneratedMessage = [
    ETransactionTypes.STOLEN,
    ETransactionTypes.LOST,
  ].includes(transactionTypeValue);
  const isShowTestFiredDocumentsGeneratedMessage = [ETransactionTypes.TEST_FIRED].includes(
    transactionTypeValue,
  );
  const isShowLocation = [
    ETransactionTypes.SEIZED,
    ETransactionTypes.RECOVERED,
    ETransactionTypes.STOLEN,
    ETransactionTypes.LOST,
    ETransactionTypes.SPOT_CHECK,
  ].includes(transactionTypeValue);
  const isShowCountryOfImport = [ETransactionTypes.DECLARED_IMPORTED].includes(
    transactionTypeValue,
  );
  const isModificationDescription = [ETransactionTypes.MODIFIED].includes(
    transactionTypeValue,
  );
  const isShowCountryOfExport = transactionTypeValue === ETransactionTypes.EXPORTED;
  const canChangeLegalEntity = [...CHANGE_LE_REQUIRED_TRANSACTION_TYPES].includes(
    transactionTypeValue,
  );
  const canSetNewKeeperOnly = [
    ETransactionTypes.GIVEN,
    ETransactionTypes.LEASED,
    ETransactionTypes.ASSIGNED_TEMPORARILY,
    ETransactionTypes.ASSIGNED_INDEFINITELY,
    ETransactionTypes.RETURNED,
  ].includes(transactionTypeValue);
  const isShowExpirationDate = [ETransactionTypes.TEST_FIRED].includes(transactionTypeValue);
  const isShowExpirationTime = [
    ETransactionTypes.ASSIGNED_TEMPORARILY,
    ETransactionTypes.LEASED,
  ].includes(transactionTypeValue);
  const isMapOnlyForOrganization = [
    ETransactionTypes.RECOVERED,
    ETransactionTypes.SURRENDERED,
    ETransactionTypes.SEIZED,
    ETransactionTypes.TRANSFERRED,
  ].includes(transactionTypeValue);

  const { translations } = useLang();
  const { hasPermissions } = usePermission();

  useEffect(() => {
    loadInitData();
  }, []);

  useEffect(() => {
    if (firearmGroup && !firearmGroup?.firearms?.length) {
      setAlert(ActionTypes.SET_TRANSACTION_ALERT, {} as IAlert);
      navigate(
        ERouteLinks.ViewLegalEntityGroup.replace(':id', id).replace(':groupId', groupId),
      );
    }
  }, [firearmGroup]);

  useEffect(() => {
    if (
      registeredFirearm?.groupName &&
      registeredFirearm?.stateUniqueId !== EFirearmStateId.EXPORTED &&
      registeredFirearm?.stateUniqueId !== EFirearmStateId.LOST
    ) {
      setAlert(ActionTypes.SET_TRANSACTION_ALERT, {} as IAlert);
      navigate(`${ERouteLinks.ViewRegisteredFirearm.replace(':id', id)}?expand=transactions`);
    }
  }, [registeredFirearm]);

  useEffect(() => {
    if (isMiddleTransactionPage && id) {
      getRegisteredFirearm(id).then((res) => {
        setRegisteredFirearm(res);
      });
    } else if (isGroupTransactionPage && groupId) {
      getFirearmGroupDetails(groupId).then((res) => {
        setFirearmGroup(res);
      });
    }
  }, [id]);

  useEffect(() => {
    if (
      possibleTransactionDictionaries &&
      !possibleTransactionDictionaries?.map((d) => d.uniqueId).includes(transactionTypeValue)
    ) {
      setValue('typeUniqueId', '');
    }
  }, [possibleTransactionDictionaries]);

  useEffect(() => {
    if (firearmGroup) {
      const haveFirearmsSameFRT =
        !!firearmGroup?.firearms?.length &&
        firearmGroup?.firearms?.every(
          (f) => f.frtUniqueId === firearmGroup?.firearms[0].frtUniqueId,
        );
      setIsFrtInformationVisible(haveFirearmsSameFRT);
    } else if (registeredFirearm) {
      setIsFrtInformationVisible(true);
    }
  }, [firearmGroup, registeredFirearm]);

  useEffect(() => {
    (async function initFRTData() {
      if (isFrtInformationVisible && firearmGroup?.firearms?.length) {
        await getFRTDetailsData(firearmGroup?.firearms[0].frtUniqueId);
      }
    })();
  }, [isFrtInformationVisible]);

  useEffect(() => {
    if (isTransactionForGroup === '0' && id) {
      getLastFirearmTransactionUniqueId(id).then((result) =>
        setLastTransactionUniqueId(result.transactionUniqueId),
      );
    }
    if (isTransactionForGroup === '1') {
      getGroupLastTransactionUniqueId(groupId).then((result) =>
        setLastTransactionUniqueId(result.transactionUniqueId),
      );
    }
  }, [isTransactionForGroup, id]);

  useEffect(() => {
    if (!!id && isTransactionForGroup === '0' && !!type) {
      getPossibleTransactionType(id, type).then((possibleTransactions) => {
        setPossibleTransactionDictionaries(possibleTransactions || null);
      });
    }
    if (isTransactionForGroup === '1' && !!type) {
      getPossibleTransactionTypeForGroup(groupId, type).then((possibleTransactions) => {
        setPossibleTransactionDictionaries(possibleTransactions || null);
      });
    }
    if (!type) {
      if (firearm && firearm.keeperId) {
        getLegalType(firearm.keeperId).then((legalType) => {
          if (legalType) {
            getPossibleTransactionType(id, legalType.type).then((possibleTransactions) => {
              setPossibleTransactionDictionaries(possibleTransactions || null);
            });
          }
        });
      } else if (isTransactionForGroup === '0') {
        getPossibleTransactionType(id, undefined).then((possibleTransactions) => {
          setPossibleTransactionDictionaries(possibleTransactions || null);
        });
      }
    }
  }, [id, groupId, type]);

  useEffect(() => {
    if (isTransactionForGroup === '0') {
      setIsLegalEntityLoading(true);
      getFirearmLastTransaction(id, true).then((result) => {
        if (result.ownerId) {
          getLegalType(result.ownerId).then((legalType) => {
            if (legalType!.type === LETypes.organization) {
              getOrgLegalEntity(result.ownerId).then((owner) => {
                setLegalEntityOrganisationOwner(owner);
                setIsCurrentOwnerLoaded(true);
              });
            } else {
              getLegalEntity(result.ownerId).then((owner) => {
                setLegalEntityIndividualOwner(owner);
                setIsCurrentOwnerLoaded(true);
              });
            }
          });
        } else {
          setIsCurrentOwnerLoaded(true);
        }
        if (result.keeperId) {
          getLegalType(result.keeperId).then((legalType) => {
            if (legalType) {
              setType(legalType.type);
              if (legalType!.type === LETypes.organization) {
                getOrgLegalEntity(result.keeperId).then((keeper) => {
                  setLegalEntityOrganisationKeeper(keeper);
                  setIsCurrentKeeperLoaded(true);
                });
              } else {
                getLegalEntity(result.keeperId).then((keeper) => {
                  setLegalEntityIndividualKeeper(keeper);
                  setIsCurrentKeeperLoaded(true);
                });
              }
            }
          });
        } else {
          setIsCurrentKeeperLoaded(true);
        }
        setIsLegalEntityLoading(false);
      });
    } else {
      setIsLegalEntityLoading(true);
      getGroupLastTransaction(groupId).then((result) => {
        if (result.ownerId) {
          getLegalType(result.ownerId).then((legalType) => {
            if (legalType) {
              if (legalType!.type === LETypes.organization) {
                getOrgLegalEntity(result.ownerId).then((owner) => {
                  setLegalEntityOrganisationOwner(owner);
                  setIsCurrentOwnerLoaded(true);
                });
              } else {
                getLegalEntity(result.ownerId).then((owner) => {
                  setLegalEntityIndividualOwner(owner);
                  setIsCurrentOwnerLoaded(true);
                });
              }
              if (!result.keeperId) {
                setType(legalType.type);
              }
            }
          });
        } else {
          setIsCurrentOwnerLoaded(true);
        }
        if (result.keeperId) {
          getLegalType(result.keeperId).then((legalType) => {
            if (legalType) {
              setType(legalType.type);
              if (legalType!.type === LETypes.organization) {
                getOrgLegalEntity(result.keeperId).then((keeper) => {
                  setLegalEntityOrganisationKeeper(keeper);
                  setIsCurrentKeeperLoaded(true);
                });
              } else {
                getLegalEntity(result.keeperId).then((keeper) => {
                  setLegalEntityIndividualKeeper(keeper);
                  setIsCurrentKeeperLoaded(true);
                });
              }
            }
          });
        } else {
          setIsCurrentKeeperLoaded(true);
        }
      });
      setIsLegalEntityLoading(false);
    }
  }, [id]);

  useEffect(() => {
    (async function initData() {
      await getCustomField(ECustomFieldObjectType.FIREARM);
      await loadDictionaries([EDictionaryTypes.CountryAll, EDictionaryTypes.TransactionType]);
    })();
  }, [selectedLanguage]);

  const filteredTransactionMiddleTypes = useRef<IDictionary[] | null>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<(string | IBreadcrumbsLink)[] | []>([]);

  useEffect(() => {
    if (translations?.length && type?.length) {
      setBreadcrumbs(
        isTransactionForGroup === '1'
          ? [
              { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
              {
                label:
                  type === LETypes.individual
                    ? t('legalEntities.individual.accordion.label')
                    : t('legalEntities.organization.accordion.label'),
                route: `${ERouteLinks.LegalEntities}?expand=${
                  type === LETypes.individual ? 'individual' : 'organisation'
                }`,
              },
              {
                label: t('legalEntityDetails.legalEntityDetails.text'),
                route: `${ERouteLinks.ViewLegalEntity.replace(':id', id as string)}?type=${
                  type === LETypes.individual ? 'individual' : 'organisation'
                }&expand=groups`,
              },
              {
                label: t('groupDetails.header'),
                route: `${ERouteLinks.ViewLegalEntityGroup.replace(
                  ':id',
                  id as string,
                ).replace(':groupId', groupId as string)}?type=${
                  type === LETypes.individual ? 'individual' : 'organisation'
                }&expand=groups`,
              },
              t('addTransaction.addTransaction.breadcrumb'),
            ]
          : [
              { label: t('addTransaction.firearm.breadcrumb'), route: ERouteLinks.Firearms },
              {
                label: t('addTransaction.firearmDetails.breadcrumb'),
                route: ERouteLinks.ViewRegisteredFirearm.replace(':id', id),
              },
              t('addTransaction.addTransaction.breadcrumb'),
            ],
      );
    }
  }, [translations, type]);

  useEffect(() => {
    if (transactionMiddleTypes) {
      if (type === LETypes.individual) {
        filteredTransactionMiddleTypes.current = transactionMiddleTypes?.filter(
          ({ uniqueId: id1 }) =>
            !TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS.some((id2) => id2 === id1),
        );
      } else if (type === LETypes.organization) {
        filteredTransactionMiddleTypes.current = transactionMiddleTypes;
      }
    }
  }, [type, transactionMiddleTypes]);

  useEffect(() => () => window.history.replaceState({}, document.title), []);

  useEffect(() => {
    setShowCurrentOwnerAndKeeper(
      legalEntityIndividualOwner !== undefined ||
        legalEntityIndividualKeeper !== undefined ||
        legalEntityOrganisationOwner !== undefined ||
        legalEntityOrganisationKeeper !== undefined,
    );
  }, [
    legalEntityIndividualOwner,
    legalEntityIndividualKeeper,
    legalEntityOrganisationOwner,
    legalEntityOrganisationKeeper,
  ]);

  useEffect(() => {
    if (routerState?.firearm) {
      const { make, model, action, manufacturer, calibre } = routerState.firearm;
      const manufacturerName = manufacturer ? `${manufacturer}, ` : '';
      const calibreName = calibre ? `, ${calibre}` : '';
      setFrtTitleDetails(`${make}, ${model}, ${manufacturerName} ${action}${calibreName}`);
    } else if (firearmGroup?.firearms.length) {
      const { make, model, action, manufacturer, caliber } = firearmGroup.firearms[0];
      const manufacturerName = manufacturer ? `${manufacturer}, ` : '';
      const calibreName = caliber ? `, ${caliber}` : '';
      setFrtTitleDetails(`${make}, ${model}, ${manufacturerName} ${action}${calibreName}`);
    } else if (registeredFirearm) {
      const { make, model, action, manufacturer, caliber } = registeredFirearm;
      const manufacturerName = manufacturer ? `${manufacturer}, ` : '';
      const calibreName = caliber ? `, ${caliber}` : '';
      setFrtTitleDetails(`${make}, ${model}, ${manufacturerName} ${action}${calibreName}`);
    }
  }, [routerState, firearmGroup, registeredFirearm]);

  const clearUserInput = () => {
    setValue('countryOfImportUniqueId', '');
    setValue('countryOfExportUniqueId', '');
    setValue('expirationDate', '');
    setValue('expirationTime', '');
    setValue('importer', '');
    setValue('legalEntityId', '');
    setValue('legalEntityFullName', '');
  };

  const onCancel = () => {
    if (isMiddleTransactionPage) {
      navigate(`${ERouteLinks.ViewRegisteredFirearm.replace(':id', id)}?expand=transactions`);
    } else if (isGroupTransactionPage) {
      navigate(
        `${ERouteLinks.ViewLegalEntityGroup.replace(':id', id as string).replace(
          ':groupId',
          groupId as string,
        )}?type=${type === LETypes.individual ? 'individual' : 'organisation'}&expand=groups`,
      );
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', () => {
      if (window.location.href.includes(ERouteLinks.SelectLegalEntity)) {
        onCancel();
      }
    });
  }, []);

  const onSubmit = () => {
    let ownerId: any = null;
    let keeperId: any = null;
    setCreateTransactionError('');
    handleSubmit(async (data) => {
      setIsErrorLocation(false);
      const date = formatDateTimeToIso(data.transactionDate);

      const fullDate = date;
      if (canSetNewKeeperOnly) {
        if (type === LETypes.individual || type === LETypes.organization) {
          keeperId = routerState?.legalEntityId;
          ownerId =
            legalEntityIndividualOwner?.uniqueId || legalEntityOrganisationOwner?.uniqueId;
        } else {
          keeperId = routerState.legalEntityId;
          ownerId =
            legalEntityIndividualOwner !== undefined && legalEntityIndividualOwner !== null
              ? legalEntityIndividualOwner.uniqueId
              : legalEntityOrganisationOwner!.uniqueId;
        }
      } else if ([ETransactionTypes.TEST_FIRED].includes(transactionTypeValue)) {
        keeperId = legalEntityIndividualKeeper
          ? legalEntityIndividualKeeper.uniqueId
          : legalEntityOrganisationKeeper?.uniqueId;
        ownerId = legalEntityIndividualOwner
          ? legalEntityIndividualOwner.uniqueId
          : legalEntityOrganisationOwner?.uniqueId;
      } else if (
        [ETransactionTypes.STOLEN, ETransactionTypes.LOST].includes(transactionTypeValue)
      ) {
        keeperId = null;
        ownerId = legalEntityIndividualOwner
          ? legalEntityIndividualOwner.uniqueId
          : legalEntityOrganisationOwner?.uniqueId;
      } else if (
        [ETransactionTypes.EXPORTED, ETransactionTypes.DESTROYED].includes(
          transactionTypeValue,
        )
      ) {
        keeperId = null;
        ownerId = null;
      } else if ([ETransactionTypes.SPOT_CHECK].includes(transactionTypeValue)) {
        if (legalEntityIndividualKeeper) {
          keeperId = legalEntityIndividualKeeper.uniqueId;
        } else if (legalEntityOrganisationKeeper) {
          keeperId = legalEntityOrganisationKeeper.uniqueId;
        }
        if (legalEntityIndividualOwner) {
          ownerId = legalEntityIndividualOwner.uniqueId;
        } else if (legalEntityOrganisationOwner) {
          ownerId = legalEntityOrganisationOwner.uniqueId;
        }
      } else if ([ETransactionTypes.REPAIRED_OR_MAINTAINED].includes(transactionTypeValue)) {
        keeperId = legalEntityIndividualKeeper
          ? legalEntityIndividualKeeper.uniqueId
          : legalEntityOrganisationKeeper?.uniqueId;
        ownerId = legalEntityIndividualOwner
          ? legalEntityIndividualOwner.uniqueId
          : legalEntityOrganisationOwner?.uniqueId;
      } else if ([ETransactionTypes.DEACTIVATED].includes(transactionTypeValue)) {
        if (legalEntityIndividualKeeper) {
          keeperId = legalEntityIndividualKeeper.uniqueId;
        } else if (legalEntityOrganisationKeeper) {
          keeperId = legalEntityOrganisationKeeper.uniqueId;
        }
        if (legalEntityIndividualOwner) {
          ownerId = legalEntityIndividualOwner.uniqueId;
        } else if (legalEntityOrganisationOwner) {
          ownerId = legalEntityOrganisationOwner.uniqueId;
        }
      } else if ([ETransactionTypes.PROOFED].includes(transactionTypeValue)) {
        if (legalEntityIndividualKeeper) {
          keeperId = legalEntityIndividualKeeper.uniqueId;
        } else if (legalEntityOrganisationKeeper) {
          keeperId = legalEntityOrganisationKeeper.uniqueId;
        }
        if (legalEntityIndividualOwner) {
          ownerId = legalEntityIndividualOwner.uniqueId;
        } else if (legalEntityOrganisationOwner) {
          ownerId = legalEntityOrganisationOwner.uniqueId;
        }
      } else if (
        [
          ETransactionTypes.MODIFIED,
          ETransactionTypes.MARKED,
          ETransactionTypes.MARKED_PENDING,
        ].includes(transactionTypeValue)
      ) {
        if (legalEntityIndividualKeeper) {
          keeperId = legalEntityIndividualKeeper.uniqueId;
        } else if (legalEntityOrganisationKeeper) {
          keeperId = legalEntityOrganisationKeeper.uniqueId;
        }
        if (legalEntityIndividualOwner) {
          ownerId = legalEntityIndividualOwner.uniqueId;
        } else if (legalEntityOrganisationOwner) {
          ownerId = legalEntityOrganisationOwner.uniqueId;
        }
      } else if (routerState?.type || routerState?.legalEntityId) {
        keeperId = routerState?.legalEntityId;
        ownerId = routerState?.legalEntityId;
      } else if (type === LETypes.individual) {
        keeperId = legalEntityIndividualKeeper
          ? legalEntityIndividualKeeper.uniqueId
          : legalEntityOrganisationKeeper?.uniqueId;
        ownerId = legalEntityIndividualOwner
          ? legalEntityIndividualOwner.uniqueId
          : legalEntityOrganisationOwner?.uniqueId;
      } else if (type === LETypes.organization) {
        keeperId = legalEntityOrganisationKeeper?.uniqueId;
        ownerId = legalEntityOrganisationOwner?.uniqueId;
      }

      if (isShowLocation && !routerState?.coords) {
        setSubmitBlocked(false);
        return setIsErrorLocation(true);
      }
      const body = {
        request: {
          typeUniqueId: data.typeUniqueId,
          keeperId,
          ownerId,
          date: fullDate,
          ...(isTransactionForGroup === '1' ? { groupUniqueId: groupId } : {}),
          ...(isTransactionForGroup === '0' ? { firearmUniqueId: id } : {}),
          ...(isShowCountryOfImport
            ? {
                countryOfImportUniqueId: data.countryOfImportUniqueId,
                importer: data.importer,
              }
            : {}),
          ...(isShowCountryOfExport
            ? {
                countryOfExportUniqueId: data.countryOfExportUniqueId,
              }
            : {}),
          ...(isShowLocation && routerState?.coords
            ? {
                latitude: routerState?.coords[0],
                longitude: routerState?.coords[1],
                address: routerState?.address,
                city: routerState?.city,
                countryOfTransaction: routerState?.country,
              }
            : {}),
          ...(isModificationDescription ? { description: data.description } : {}),
          ...(isShowExpirationDate
            ? {
                expirationDate: formatDateTimeToIso(data.expirationDate),
              }
            : {}),
          ...(isShowExpirationTime
            ? {
                expirationDate: formatDateTimeToIso(data.expirationTime),
              }
            : {}),
          lastTransactionUniqueId,
        },
        ...(data.attachments?.filter((attachment: any) => attachment.file !== null)?.length
          ? { attachments: data.attachments }
          : {}),
      };
      try {
        setSubmitBlocked(true);
        if (isTransactionForGroup === '1') {
          const transaction = await createMiddleTransactionForGroup(body);
          if (transaction) {
            if (isShowTracingDocumentsGeneratedMessage) {
              if (eTraceEnabled || iArmsEnabled) {
                setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
                  text: t('addTransaction.transactionAdded.tracingDocumentsGenerated.text'),
                });
              } else {
                setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
                  text: t('addTransaction.transactionAdded.text'),
                });
              }
            } else if (isShowTestFiredDocumentsGeneratedMessage) {
              if (eTraceEnabled) {
                setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
                  text: t('addTransaction.transactionAdded.testFireDocumentsGenerated.text'),
                });
              } else {
                setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
                  text: t('addTransaction.transactionAdded.text'),
                });
              }
            } else {
              setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
                text: t('addTransaction.transactionAdded.text'),
              });
            }
            navigate(pathname, {
              state: {
                ...routerState,
                typeUniqueId: undefined,
                transactionDate: undefined,
                firearmsQuantity: undefined,
                serialNumber: undefined,
                externalReferenceNumber: undefined,
                manufacturedAt: undefined,
                countryOfImportUniqueId: undefined,
                importer: undefined,
                countryOfExportUniqueId: undefined,
                expirationDate: undefined,
                expirationTime: undefined,
                attachments: undefined,
                legalEntityFullName: undefined,
                legalEntityId: undefined,
                address: undefined,
                currentKeeperUniqueId: undefined,
                currentOwnerUniqueId: undefined,
                type: undefined,
                breadcrumbs: undefined,
              },
              replace: true,
            });
            navigate(
              `${ERouteLinks.ViewTransaction.replace(
                ':id',
                transaction.uniqueId,
              )}?expand=firearmDetails`,
            );
          }
        } else if (isTransactionForGroup === '0') {
          const transaction = await createMiddleTransaction(body);
          if (transaction) {
            if (isShowTracingDocumentsGeneratedMessage) {
              if (eTraceEnabled || iArmsEnabled) {
                setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
                  text: t('addTransaction.transactionAdded.tracingDocumentsGenerated.text'),
                });
              } else {
                setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
                  text: t('addTransaction.transactionAdded.text'),
                });
              }
            } else if (isShowTestFiredDocumentsGeneratedMessage) {
              if (eTraceEnabled) {
                setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
                  text: t('addTransaction.transactionAdded.testFireDocumentsGenerated.text'),
                });
              } else {
                setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
                  text: t('addTransaction.transactionAdded.text'),
                });
              }
            } else {
              setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
                text: t('addTransaction.transactionAdded.text'),
              });
            }
            clearErrors();
            navigate(pathname, {
              state: {
                ...routerState,
                typeUniqueId: undefined,
                transactionDate: undefined,
                firearmsQuantity: undefined,
                serialNumber: undefined,
                externalReferenceNumber: undefined,
                manufacturedAt: undefined,
                countryOfImportUniqueId: undefined,
                importer: undefined,
                countryOfExportUniqueId: undefined,
                expirationDate: undefined,
                expirationTime: undefined,
                attachments: undefined,
                legalEntityFullName: undefined,
                legalEntityId: undefined,
                address: undefined,
                currentKeeperUniqueId: undefined,
                currentOwnerUniqueId: undefined,
                type: undefined,
                breadcrumbs: undefined,
              },
              replace: true,
            });
            navigate(
              `${ERouteLinks.ViewTransaction.replace(
                ':id',
                transaction.uniqueId,
              )}?expand=firearmDetails`,
            );
          }
        }
      } catch (e: any) {
        setCreateTransactionError(e?.response?.data?.message);
        setSubmitBlocked(false);
      }
      return true;
    })();
  };

  useEffect(() => {
    (async function () {
      scrollToError(errors);
    })();
  }, [errors]);

  const validateFile = (file: File, index: number) => {
    const fileType: any = file.type;
    if (file) {
      if (file.size === 0) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.emptyFile.text'),
        });
        return false;
      }
      const fileSizeInMB = file.size / 1024 / 1024;
      if (file.size > 0 && fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.fileSizeValidation.text'),
        });
        return false;
      }
      if (
        ![
          EMIMETypes.JPG,
          EMIMETypes.JPEG,
          EMIMETypes.PNG,
          EMIMETypes.PDF,
          EMIMETypes.XLSX,
        ].includes(fileType)
      ) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.fileTypeValidation.text'),
        });
        return false;
      }
    }
    clearErrors('attachments');
    return true;
  };

  const handleUploadFile = (file: File, index: number) => {
    if (validateFile(file, index)) {
      attachments.update(index, {
        file,
      });
    }
  };

  const renderRemoveAttachmentButton = (index: number) => (
    <Grid item xs={1} sx={!isDesktop ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
      <Button
        variant={EButtonVariants.outlined}
        icon={EIconTypes.delete}
        onClick={() => attachments.remove(index)}
        sizePX={EButtonSizesPX.px32}
        sx={{ ...(isDesktop ? { marginLeft: '20px' } : {}), marginTop: '28px' }}
      />
    </Grid>
  );

  const getCustomFieldTranslation = (customFieldTranslation?: ITranslation[]) => {
    if (selectedLanguage && customFieldTranslation) {
      return customFieldTranslation.filter(
        (trans) => trans.languageUniqueId === selectedLanguage?.uniqueId,
      )[0].name;
    }
    return '';
  };

  const renderCustomFieldInputs = (frm: IFirearm) =>
    frm?.customFields?.map(
      (customField) =>
        customField.value && (
          <Grid item xs={12} sm={6} md={6} lg={3} key={customField.customFieldUniqueId}>
            <FormInput
              label={getCustomFieldTranslation(customField?.translations)}
              name={customField.customFieldName}
              control={control}
              setValue={setValue}
              errors={errors}
              defaultValue={
                customField.customFieldType === ECustomFieldType.DATE
                  ? formatDate(customField.value, shortDateFormat)
                  : customField.value
              }
              disabled
            />
          </Grid>
        ),
    );

  const handleLimitToParam = () => {
    if (isMapOnlyForOrganization) {
      return `?limitTo=${LETypes.organization}`;
    }
    if (transactionTypeValue === ETransactionTypes.GIVEN) {
      return `?limitTo=${LETypes.individual}`;
    }
    return '';
  };

  const attachmentNames = watch('attachments');

  useEffect(() => {
    attachments.fields.forEach((attachment: any, index) => {
      if (attachment?.file?.name && !attachmentNames[index]?.attachmentName) {
        setValue(
          `attachments.${index}.attachmentName`,
          removeFileExtension(attachment.file.name),
        );
      }
    });
  }, [attachments.fields, attachmentNames]);

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">
          {t('addTransaction.addTransaction.header')}
        </StyledPageTitle>
      </Box>
      <div ref={elRef}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <FormSelect
              options={mapDictionaryToOptionProp(
                filterDisabled(possibleTransactionDictionaries, legalEntityIndividualKeeper),
              )}
              name="typeUniqueId"
              label={t('addFirearm.location.section.transactionType')}
              setValue={setValue}
              onSelect={clearUserInput}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormDateTimePicker
              name="transactionDate"
              label={t('addFirearm.location.section.transactionDate')}
              control={control}
              errors={errors}
              currentHour={true}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {showCurrentOwnerAndKeeper && (
              <Accordion
                title={t('addTransaction.currentOwnerAndKeeper.section.name')}
                alwaysExpanded
              >
                <Box mb={5}>
                  <div>
                    {isLegalEntityLoading ? (
                      <Loader isLoading={isLegalEntityLoading} />
                    ) : (
                      <StyledDetailsWrapper container rowGap={3}>
                        {legalEntityIndividualOwner || legalEntityOrganisationOwner ? (
                          <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t('addTransaction.currentOwnerAndKeeper.section.owner.label')}
                            </Typography>
                            {legalEntityIndividualOwner && (
                              <StyledLegalLink
                                legalId={legalEntityIndividualOwner.uniqueId}
                                legalType={ELegalEntityTypes.individual}
                                labelText={`${legalEntityIndividualOwner?.firstName} ${legalEntityIndividualOwner?.lastName}`}
                                legalVisibilityUniqueId={
                                  legalEntityIndividualOwner.visibilityUniqueId
                                }
                              />
                            )}
                            {legalEntityOrganisationOwner && (
                              <StyledLegalLink
                                legalId={legalEntityOrganisationOwner.uniqueId}
                                legalType={ELegalEntityTypes.organisation}
                                labelText={legalEntityOrganisationOwner?.organizationName}
                                legalVisibilityUniqueId={
                                  legalEntityOrganisationOwner.visibilityUniqueId
                                }
                              />
                            )}
                          </Grid>
                        ) : (
                          <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t('addTransaction.currentOwnerAndKeeper.section.owner.label')}
                            </Typography>
                            <Typography variant="body2">-</Typography>
                          </Grid>
                        )}
                        {legalEntityOrganisationKeeper || legalEntityIndividualKeeper ? (
                          <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t('addTransaction.currentOwnerAndKeeper.section.keeper.label')}
                            </Typography>
                            {legalEntityIndividualKeeper && (
                              <StyledLegalLink
                                legalId={legalEntityIndividualKeeper.uniqueId}
                                legalType={ELegalEntityTypes.individual}
                                labelText={`${legalEntityIndividualKeeper?.firstName} ${legalEntityIndividualKeeper?.lastName}`}
                                legalVisibilityUniqueId={
                                  legalEntityIndividualKeeper.visibilityUniqueId
                                }
                              />
                            )}
                            {legalEntityOrganisationKeeper && (
                              <StyledLegalLink
                                legalId={legalEntityOrganisationKeeper.uniqueId}
                                legalType={ELegalEntityTypes.organisation}
                                labelText={legalEntityOrganisationKeeper?.organizationName}
                                legalVisibilityUniqueId={
                                  legalEntityOrganisationKeeper.visibilityUniqueId
                                }
                              />
                            )}
                          </Grid>
                        ) : (
                          <Grid item xs={12} md={6}>
                            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                              {t('addTransaction.currentOwnerAndKeeper.section.keeper.label')}
                            </Typography>
                            <Typography variant="body2">-</Typography>
                          </Grid>
                        )}
                      </StyledDetailsWrapper>
                    )}
                  </div>
                </Box>
              </Accordion>
            )}
          </Grid>
          {canChangeLegalEntity && (
            <Grid item xs={12}>
              <Accordion
                title={
                  [
                    ETransactionTypes.ASSIGNED_INDEFINITELY,
                    ETransactionTypes.ASSIGNED_TEMPORARILY,
                    ETransactionTypes.GIVEN,
                    ETransactionTypes.RETURNED,
                    ETransactionTypes.LEASED,
                  ].includes(transactionTypeValue)
                    ? t('addTransaction.newKeeper.section.name')
                    : t('addTransaction.newOwnerAndKeeper.section.name')
                }
                alwaysExpanded
              >
                <Grid container mb={5} rowGap={2}>
                  <Grid item xs={12} lg={6}>
                    <FormInput
                      name="legalEntityFullName"
                      label={`${t(
                        'addTransaction.newOwnerAndKeeper.section.legalEntity.label',
                      )}*`}
                      control={control}
                      errors={errors}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3} sx={{ marginTop: { lg: '28px' }, ml: { lg: 2 } }}>
                    <Button
                      fullWidth
                      label={t(
                        'addTransaction.newOwnerAndKeeper.section.searchForLegalEntity.button',
                      )}
                      variant={EButtonVariants.contained}
                      size={EButtonSizes.small}
                      onClick={() => {
                        navigate(`${ERouteLinks.SelectLegalEntity}${handleLimitToParam()}`, {
                          state: {
                            context: 'transactions',
                            ...routerState,
                            ...getValues(),
                            id,
                            groupId,
                            currentKeeperUniqueId:
                              legalEntityIndividualKeeper?.uniqueId ||
                              legalEntityOrganisationKeeper?.uniqueId,
                            currentOwnerUniqueId:
                              transactionTypeValue === ETransactionTypes.SOLD
                                ? legalEntityOrganisationOwner?.uniqueId ||
                                  legalEntityIndividualOwner?.uniqueId
                                : undefined,
                            type,
                            breadcrumbs: [
                              ...breadcrumbs,
                              t('transactions.search.selectLegal.label'),
                            ],
                            returnLink:
                              isTransactionForGroup === '1'
                                ? `${ERouteLinks.LegalEntityAddTransactionForGroup.replace(
                                    ':id',
                                    id,
                                  ).replace(':groupId', groupId)}?type=${type}&expand=groups`
                                : `${ERouteLinks.MiddleTransaction.replace(':id', id)}`,
                          },
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
          )}
          <Grid item xs={12}>
            <Accordion title={t('addTransaction.firearmDetails.section.name')} alwaysExpanded>
              {isFrtInformationVisible && (
                <Grid
                  container
                  sx={{
                    border: `1px solid ${colors.primary25}`,
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}
                  mb={4}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: colors.primary25,
                    }}
                    px={2}
                    py={1}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        {frtTitleDetails}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container p={2} rowSpacing={2} sx={{ alignItems: 'center' }}>
                    <Grid item xs={12} lg={6} container direction="column" padding="4px">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('addTransaction.firearmDetails.section.firearmsReferenceNo.label')}
                      </Typography>
                      <Typography variant="body2">
                        {FRTDetails?.frtId || registeredFirearm?.frtId}
                      </Typography>
                    </Grid>
                    {hasPermissions([EPermission.VIEW_FIREARMS]) && (
                      <Grid item xs={12} lg={6}>
                        <Link
                          href={`${ERouteLinks.FirearmsReferenceTableDetails}?frtId=${
                            firearmGroup
                              ? firearmGroup?.firearms[0]?.frtUniqueId
                              : registeredFirearm?.frtUniqueId
                          }`}
                          underline="none"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            fullWidth
                            label={t(
                              'addTransaction.firearmDetails.section.showFRTInformation.button',
                            )}
                            variant={EButtonVariants.contained}
                            size={EButtonSizes.small}
                          />
                        </Link>
                      </Grid>
                    )}
                    {registeredFirearm && (
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{
                            height: '1px',
                            backgroundColor: colors.primary25,
                            padding: 0,
                            margin: '20px 0 10px',
                          }}
                        />
                        <Grid item xs={12} lg={3} direction="column">
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addTransaction.registeredFirearm.fireCycleId.label')}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder(registeredFirearm.fireCycleId)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} direction="column">
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addTransaction.registeredFirearm.serialNumber.label')}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder(registeredFirearm.serialNumber)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} direction="column">
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t(
                              'addTransaction.registeredFirearm.externalReferenceNumber.label',
                            )}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder(registeredFirearm.externalReferenceNumber)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} direction="column">
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addTransaction.registeredFirearm.manufacturedDate.label')}
                          </Typography>
                          <Typography variant="body2">
                            {formatDate(registeredFirearm.manufacturedAt, shortDateFormat)}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </Accordion>
          </Grid>

          {firearmGroup && (
            <Grid sx={{ marginBottom: '20px' }}>
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '14px',
                  letterSpacing: '2%',
                }}
              >
                {t('addTransaction.firearmDetails.section.groupName.label')}
              </Typography>
              <Typography sx={{ fontSize: '14px;' }}>{firearmGroup.groupName}</Typography>
            </Grid>
          )}
          {isGroupTransactionPage &&
            firearmGroup?.firearms.map((frm, index) => (
              <Grid
                item
                xs={12}
                container
                columnSpacing={2}
                sx={{ marginBottom: '20px', marginLeft: 0 }}
              >
                <Grid
                  item
                  lg={0.4}
                  sx={{
                    padding: '0 !important',
                    display: 'flex',
                    justifyContent: 'start',
                    ...(!isDesktop ? { marginBottom: '20px' } : {}),
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: '0 !important',
                      marginTop: 4,
                      fontWeight: 'bold',
                    }}
                  >
                    {`${index + 1}.`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  lg={11}
                  columnSpacing={2}
                  sx={{ padding: '0 !important' }}
                >
                  {frm.fireCycleId && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormInput
                        name="firearmFireCycleId"
                        label={t('addTransaction.firearmDetails.section.firecycleID.label')}
                        control={control}
                        errors={errors}
                        defaultValue={frm.fireCycleId}
                        disabled
                      />
                    </Grid>
                  )}
                  {frm.serialNumber && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormInput
                        name="firearmSerialNumber"
                        label={t('addTransaction.firearmDetails.section.serialNumber.label')}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        defaultValue={frm.serialNumber}
                        disabled
                      />
                    </Grid>
                  )}
                  {frm.externalReferenceNumber && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormInput
                        name="firearmExternalReferenceNumber"
                        label={t(
                          'addTransaction.firearmDetails.section.externalReferenceNo.label',
                        )}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        defaultValue={frm.externalReferenceNumber}
                        disabled
                      />
                    </Grid>
                  )}
                  {frm.manufacturedAt && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormInput
                        name="firearmManufacturedDate"
                        label={t(
                          'addTransaction.firearmDetails.section.manufacturedDate.label',
                        )}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        defaultValue={formatDate(frm.manufacturedAt, shortDateFormat)}
                        disabled
                      />
                    </Grid>
                  )}
                  {frm.exhibitNumber && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormInput
                        name="firearmExhibitNumber"
                        label={t('addTransaction.firearmDetails.section.exhibitNumber.label')}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        defaultValue={frm.exhibitNumber}
                        disabled
                      />
                    </Grid>
                  )}
                  {renderCustomFieldInputs(frm)}
                </Grid>
                <Grid lg={0.6} sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Link
                    href={ERouteLinks.ViewRegisteredFirearm.replace(':id', frm.uniqueId)}
                    target="_blank"
                  >
                    <Button
                      variant={EButtonVariants.outlined}
                      icon={EIconTypes.view}
                      sizePX={EButtonSizesPX.px32}
                      sx={
                        isDesktop
                          ? {
                              marginTop: '28px',
                            }
                          : {
                              marginBottom: '20px',
                            }
                      }
                    />
                  </Link>
                </Grid>
              </Grid>
            ))}

          {isShowLocation && (
            <Grid item xs={12}>
              <Accordion title={t('addTransaction.location.section.name')} alwaysExpanded>
                <Grid
                  container
                  mb={4}
                  lg={12}
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  columnGap={3}
                >
                  <Grid sx={{ flexBasis: '40%' }}>
                    <FormInput
                      name="address"
                      label={t('addFirearm.location.section.address.text')}
                      control={control}
                      errors={errors}
                      placeholder={routerState?.address || ''}
                      InputProps={{ readOnly: true }}
                      disabled
                      sx={{ minWidth: '272px' }}
                      helperText={t('addFirearm.location.section.address.helperText')}
                    />
                  </Grid>
                  {routerState?.coords && (
                    <StyledLocationMarkConfirmation>
                      <Typography variant="body1" color={colors.success900}>
                        {t('addTransaction.location.section.locationMarked.text')}
                      </Typography>
                      <StyledIconWrapper>
                        <Icon type={EIconTypes.checkmark} color={colors.white} fontSize={14} />
                      </StyledIconWrapper>
                    </StyledLocationMarkConfirmation>
                  )}
                  <Button
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.SelectLocation.replace(
                          ':id',
                          id,
                        )}?type=${type}&expand=firearms`,
                        {
                          state: {
                            ...routerState,
                            ...getValues(),
                            breadcrumbs: [...breadcrumbs, t('selectLocationOnMap.page.text')],
                            leId: id,
                            type,
                            returnLink:
                              isTransactionForGroup === '1'
                                ? `${ERouteLinks.LegalEntityAddTransactionForGroup.replace(
                                    ':id',
                                    id,
                                  ).replace(':groupId', groupId)}?type=${type}&expand=groups`
                                : `${ERouteLinks.MiddleTransaction.replace(':id', id)}`,
                          },
                        },
                      )
                    }
                    fullWidth={!isDesktop}
                    label={t('selectLocationOnMap.page.text')}
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    sx={{ marginTop: '28px' }}
                  />
                  {isErrorLocation && (
                    <Grid item xs={12}>
                      <FormErrorLabel
                        label={t(
                          'addTransaction.location.section.selectLocationOnMap.errorLabel',
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Accordion>
            </Grid>
          )}
          {isShowCountryOfImport && (
            <Grid item xs={12}>
              <Accordion title={t('addFirearm.countryOfImport.section.name')} alwaysExpanded>
                <Grid mb={2} container columnSpacing={2}>
                  <Grid item xs={12} lg={6}>
                    <FormInput
                      name="importer"
                      label={t('addFirearm.countryOfImport.section.importer.label.validation')}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormSelect
                      options={mapDictionaryToOptionProp(country)}
                      name="countryOfImportUniqueId"
                      setValue={setValue}
                      label={t('addFirearm.countryOfImport.section.country.label.validation')}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
          )}
          {isShowCountryOfExport && (
            <Grid item xs={12}>
              <Accordion title={t('addFirearm.countryOfExport.section.name')} alwaysExpanded>
                <Box mb={4}>
                  <Grid item xs={12} lg={6}>
                    <FormSelect
                      options={mapDictionaryToOptionProp(country)}
                      name="countryOfExportUniqueId"
                      label={t('addFirearm.countryOfExport.section.country.label.validation')}
                      setValue={setValue}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Box>
              </Accordion>
            </Grid>
          )}
          {isShowExpirationDate && (
            <Grid item xs={12}>
              <Accordion title={t('addTransaction.expiration.section.name')} alwaysExpanded>
                <Box mb={4}>
                  <Grid item xs={12} lg={6}>
                    <FormDatePicker
                      name="expirationDate"
                      label={t(
                        'addTransaction.expiration.section.expirationDate.label.validation',
                      )}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Box>
              </Accordion>
            </Grid>
          )}
          {isShowExpirationTime && (
            <Grid item xs={12}>
              <Accordion title={t('addTransaction.expiration.section.name')} alwaysExpanded>
                <Box mb={4}>
                  <Grid item xs={12} lg={6}>
                    <FormDateTimePicker
                      name="expirationTime"
                      label={`${t(
                        'addTransaction.expiration.section.expirationTime.label.validation',
                      )}*`}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Box>
              </Accordion>
            </Grid>
          )}

          {isModificationDescription && (
            <Grid item xs={12}>
              <Accordion title={t('firearms.transaction.modification.label')} alwaysExpanded>
                <Box mb={4}>
                  <Grid item xs={12} lg={12}>
                    <FormWyswigEditor
                      name="description"
                      label={`${t('firearms.transaction.modification.label')}*`}
                      placeholder={t('firearms.transaction.modification.label')}
                      setValue={setValue}
                      control={control}
                      errors={errors}
                      setError={setError}
                      clearErrors={clearErrors}
                      charsLimit={1000}
                    />
                  </Grid>
                </Box>
              </Accordion>
            </Grid>
          )}

          <Grid item xs={12}>
            <Accordion title={t('addTransaction.document.section.name')} alwaysExpanded>
              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Alert
                        text={t('general.field.availableAttachmentFormats.text')}
                        variant={EAlertVariants.warning}
                      />
                    </Box>
                  </Grid>
                  {attachments?.fields?.map((attachment: any, index) => (
                    <Grid key={attachment.id} item xs={12} container mb={!isDesktop ? 2 : 0}>
                      <Grid item xs={attachments.fields?.length > 1 ? 11 : 12} lg={6}>
                        <FormInput
                          name={`attachments[${index}].attachmentName`}
                          defaultValue={
                            attachment?.file?.name
                              ? removeFileExtension(attachment?.file?.name)
                              : ''
                          }
                          label={t('addTransaction.document.section.attachmentName.label')}
                          control={control}
                          errors={errors}
                          arrayName="attachments"
                          arrayIndex={index}
                          fieldName="attachmentName"
                          InputProps={{ disabled: attachment?.file === null }}
                        />
                      </Grid>
                      {!isDesktop && renderRemoveAttachmentButton(index)}
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ marginTop: { lg: '28px' }, ml: { lg: 2 } }}
                      >
                        <Button
                          isUploadButton
                          onClick={(files) => handleUploadFile(files[0], index)}
                          fullWidth
                          label={t('addTransaction.document.section.browseFiles.button')}
                          id="add_file-button"
                          icon={EIconTypes.plus}
                          size={EButtonSizes.small}
                          isMultiple
                        />
                        {errors &&
                          errors.attachments &&
                          errors.attachments[index] &&
                          errors.attachments[index].file && (
                            <FormErrorLabel label={errors.attachments[index].file?.message} />
                          )}
                      </Grid>
                      {isDesktop && renderRemoveAttachmentButton(index)}
                    </Grid>
                  ))}
                  <Grid item xs={12} lg={3} sx={{ mt: { xs: 2, lg: 0 } }}>
                    <Button
                      onClick={() =>
                        attachments.append({
                          attachmentName: '',
                          file: null,
                        })
                      }
                      fullWidth
                      label={t('addFirearm.document.section.addFile.button')}
                      id="add_file-button"
                      variant={EButtonVariants.outlined}
                      size={EButtonSizes.small}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Accordion>
          </Grid>
          <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
            <Grid item xs={12} lg={6} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={onSubmit}
                  fullWidth
                  isLoading={submitBlocked}
                  disabled={!isCurrentOwnerLoaded || !isCurrentKeeperLoaded}
                  label={t('addTransaction.save.button')}
                  variant={EButtonVariants.contained}
                  sx={{ mr: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('addTransaction.cancel.button')}
                  variant={EButtonVariants.outlined}
                  onClick={onCancel}
                />
              </Grid>
              <Grid xs={12} item>
                <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            {createTransactionError && (
              <Box mb={2}>
                <Alert text={createTransactionError} variant={EAlertVariants.error} />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export { LegalEntityAddTransactionForGroupPage };

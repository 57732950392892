import React from 'react';
import { slateToHtml, slateToHtmlConfig } from '@slate-serializers/html';
import { emptyFieldHolder } from 'components/molecules/CardList';
import styled from '@emotion/styled';
import { Node } from 'slate';

const config = {
  ...slateToHtmlConfig,
  elementMap: {
    ...slateToHtmlConfig.elementMap,
    'list-item': 'li',
    'numbered-list': 'ol',
  },
};

const StyledEditable = styled.div(() => ({
  overflowWrap: 'anywhere',
  '& pre': {
    display: 'contents',
  },
  '& ol': {
    paddingLeft: '25px',
  },
}));

export const getHtmlText = (slate: any) => {
  try {
    const json = JSON.parse(slate);
    const html = slateToHtml(json, config);
    // eslint-disable-next-line react/no-danger
    return (
      <StyledEditable dangerouslySetInnerHTML={{ __html: html.replace(/\n/g, '<br />') }} />
    );
  } catch {
    return emptyFieldHolder(slate);
  }
};

export const countEditorCharacters = (val: any, json: boolean = false) => {
  if (!val || !val.length) {
    return 0;
  }
  const value = json ? val : JSON.parse(val);

  const serializeString = value.map((n: any) => Node.string(n)).join();
  return serializeString.length;
};

import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { AuditLogEventName } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { IAuditLogItemResponse } from 'models/auditLog';
import { IRole } from 'models/role';
import { getRole } from 'requests/role';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import Grid from '@mui/material/Grid';
import { useTranslations } from 'hooks/useTranslations';
import { colors } from 'theme/colors';
import { EPermission, permissionsGroups } from 'models/permissions';
import { IParsedPermissions, useRoles } from 'pages/RolesPage/hooks';
import { usePermission } from 'hooks/usePermission';
import { EAuditLogEvents } from 'pages/AuditLogPage/AuditLogViewPage/index';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';

interface IAdministrationRoleEventDetails {
  auditLog: IAuditLogItemResponse;
}

const AdministrationRoleEventDetails = ({ auditLog }: IAdministrationRoleEventDetails) => {
  const [role, setRole] = useState<IRole | null>();
  const [previousRole, setPreviousRole] = useState<IRole | null>();
  const [parsedPermissions, setParsedPermissions] = useState<IParsedPermissions>({});
  const { getPermissions, parsePermissions, permissions } = useRoles();
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();
  const navigate = useNavigate();

  useEffect(() => {
    if (auditLog?.previousObjectValue) {
      const previousObjectValue = auditLog.previousObjectValue as any;
      const roleData: IRole = {
        createdDate: '',
        description: previousObjectValue.description || '',
        formPermissionIds: previousObjectValue.formPermissionIds || {},
        name: previousObjectValue.name || '',
        permissionIds: previousObjectValue.permissionIds || [],
        uniqueId: '',
      };
      setPreviousRole(roleData);
    }

    if (auditLog?.objectUniqueId && hasPermissions([EPermission.MANAGE_ROLES])) {
      (async () => {
        const response = await getRole(auditLog.objectUniqueId);
        if (response) {
          setRole(response);
        }
      })();
    }
  }, [auditLog]);

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setParsedPermissions(parsePermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  return (
    <>
      {previousRole && (
        <>
          <AuditLogEventName>
            <Typography variant="h5">
              {auditLog?.eventNameUniqueId !== EAuditLogEvents.ADD_ROLE
                ? `${t('roleDetails.header')} ${t('auditLogDetails.before.event.title')}`
                : `${t('roleDetails.header')} ${t('auditLogDetails.after.event.title')}`}
            </Typography>
          </AuditLogEventName>
          <DetailsTemplate
            pageType={EDetailsPageTypes.auditLogView}
            title={previousRole.name}
            disableActions
          >
            <Grid item lg={12} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('roleDetails.description.label')}
              </Typography>
              <Typography variant="body2">
                {previousRole?.description ? previousRole.description : '---'}
              </Typography>
            </Grid>

            {!!previousRole?.permissionIds?.length && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: colors.primary500,
                      textTransform: 'uppercase',
                      letterSpacing: '0.02em',
                    }}
                  >
                    {t('roleDetails.selectedPermissions.label')}
                  </Typography>
                </Grid>

                {permissionsGroups.map(
                  (group) =>
                    parsedPermissions?.[group.module] && (
                      <Grid item lg={3} md={6} xs={12} key={group.module} sx={{ mb: 2 }}>
                        <Box mb={1}>
                          <Typography variant="subtitle2">{t(group.title)}</Typography>

                          <div>
                            {parsedPermissions?.[group.module].map(
                              (permission) =>
                                previousRole?.permissionIds?.includes(permission.uniqueId) && (
                                  <Typography variant="body2" sx={{ mt: 0.5 }}>
                                    {permission.name}
                                  </Typography>
                                ),
                            )}
                          </div>
                        </Box>
                      </Grid>
                    ),
                )}
              </>
            )}
          </DetailsTemplate>
        </>
      )}

      {previousRole && auditLog?.eventNameUniqueId !== EAuditLogEvents.DELETE_ROLE && (
        <AuditLogEventName sx={{ marginTop: '20px' }}>
          <Typography variant="h5">
            {`${t('roleDetails.header')} ${t('auditLogDetails.current.data.event.title')}`}
          </Typography>
        </AuditLogEventName>
      )}

      {role && (
        <DetailsTemplate
          pageType={EDetailsPageTypes.auditLogView}
          title={role.name}
          redirectToView={() =>
            navigate(`${ERouteLinks.ViewRole.replace(':id', role.uniqueId)}`)
          }
          disableActions={!role.uniqueId}
        >
          <Grid item lg={12} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('roleDetails.description.label')}
            </Typography>
            <Typography variant="body2">
              {role?.description ? role.description : '---'}
            </Typography>
          </Grid>

          {!!role?.permissionIds?.length && (
            <>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: colors.primary500,
                    textTransform: 'uppercase',
                    letterSpacing: '0.02em',
                  }}
                >
                  {t('roleDetails.selectedPermissions.label')}
                </Typography>
              </Grid>

              {permissionsGroups.map(
                (group) =>
                  parsedPermissions?.[group.module] && (
                    <Grid item lg={3} md={6} xs={12} key={group.module} sx={{ mb: 2 }}>
                      <Box mb={1}>
                        <Typography variant="subtitle2">{t(group.title)}</Typography>

                        <div>
                          {parsedPermissions?.[group.module].map(
                            (permission) =>
                              role?.permissionIds?.includes(permission.uniqueId) && (
                                <Typography variant="body2" sx={{ mt: 0.5 }}>
                                  {permission.name}
                                </Typography>
                              ),
                          )}
                        </div>
                      </Box>
                    </Grid>
                  ),
              )}
            </>
          )}
        </DetailsTemplate>
      )}
    </>
  );
};

export default AdministrationRoleEventDetails;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import { useApiKeys } from 'pages/ApiKeysPage/hooks';
import { IApiKeyRequest } from 'models/apiKey';
import { getDateFromISO } from 'utils/date';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Box } from '@mui/material';
import { useAlert } from 'models/alertContext';
import { parseISO } from 'date-fns';
import { ApiKeyForm, initialValues } from '../ApiKeyForm';

const EditApiKeyPage = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { getApiKeyHandler, apiKey, updateApiKeyHandler } = useApiKeys();
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const [initialApiKeyValues, setInitialApiKeyValues] = useState<{
    [key: string]: string | boolean | Date;
  }>();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async () => {
      if (id) {
        await getApiKeyHandler(id);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (apiKey) {
      const formInitialValues = { ...initialValues };

      formInitialValues.type = apiKey.type;
      formInitialValues.username = apiKey.username;
      formInitialValues.expiryDate = apiKey.expiryDate
        ? getDateFromISO(apiKey.expiryDate)
        : '';
      formInitialValues.description = apiKey.description;
      formInitialValues.contactEmail = apiKey.contactEmail;
      formInitialValues.firstName = apiKey.firstName;
      formInitialValues.lastName = apiKey.lastName;
      formInitialValues.phonePrefix = apiKey.phonePrefixDictionaryUniqueId;
      formInitialValues.phoneNumber = apiKey.phoneNumber;

      const scopeKeys = [
        'apikeyAncillaryAccessory',
        'apikeyAncillaryAmmunition',
        'apikeyAncillaryComponent',
        'apikeyFirearm',
        'apikeyLegalEntity',
        'apikeyTransaction',
      ];

      scopeKeys.map((scopeKey) => {
        // @ts-ignore
        if (apiKey?.[scopeKey]) {
          // @ts-ignore
          Object.keys(apiKey[scopeKey]).map((scope) => {
            // @ts-ignore
            const value = apiKey[scopeKey][scope];
            // @ts-ignore
            formInitialValues[`${scopeKey}_${scope}`] = value;

            return false;
          });
          // @ts-ignore
          const isSetScope = Object.values(apiKey[scopeKey]).every((item) => item === true);
          if (isSetScope) {
            formInitialValues[`${scopeKey}`] = true;
          }
        }
        return false;
      });

      setInitialApiKeyValues(formInitialValues);
    }
  }, [apiKey]);

  const areObjectSame = (obj1: any, obj2: any): boolean => {
    let result = true;

    Object.keys(obj1).forEach((objKey: string) => {
      if (obj1[objKey] !== obj2[objKey]) {
        result = false;
      }
    });

    return result;
  };

  const areChangesMade = (editedData: IApiKeyRequest): boolean => {
    if (!apiKey) return false;
    const apikeyExpiryDate = apiKey.expiryDate
      ? parseISO(apiKey.expiryDate).toISOString()
      : undefined;
    const editedDataExpiryDate = editedData.expiryDate
      ? new Date(editedData.expiryDate).toISOString()
      : undefined;

    return (
      editedData.type !== apiKey.type ||
      editedData.username !== apiKey.username ||
      editedDataExpiryDate !== apikeyExpiryDate ||
      editedData.description !== apiKey.description ||
      editedData.contactEmail !== apiKey.contactEmail ||
      editedData.firstName !== apiKey.firstName ||
      editedData.lastName !== apiKey.lastName ||
      editedData.contactPhone.codeDictionaryUniqueId !==
        apiKey.phonePrefixDictionaryUniqueId ||
      editedData.contactPhone.value !== apiKey.phoneNumber ||
      !areObjectSame(editedData.apikeyLegalEntity, apiKey.apikeyLegalEntity) ||
      !areObjectSame(editedData.apikeyFirearm, apiKey.apikeyFirearm) ||
      !areObjectSame(editedData.apikeyAncillaryAccessory, apiKey.apikeyAncillaryAccessory) ||
      !areObjectSame(editedData.apikeyAncillaryAmmunition, apiKey.apikeyAncillaryAmmunition) ||
      !areObjectSame(editedData.apikeyAncillaryComponent, apiKey.apikeyAncillaryComponent) ||
      !areObjectSame(editedData.apikeyTransaction, apiKey.apikeyTransaction)
    );
  };

  const onSubmit = async (payload: IApiKeyRequest) => {
    if (!areChangesMade(payload)) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('general.noChanged.warning.message'),
        variant: EAlertVariants.warning,
      });
      return;
    }
    try {
      if (id) {
        await updateApiKeyHandler(id, payload);
        setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
          text: t('editApiKey.success.text'),
          variant: EAlertVariants.success,
        });
        navigate(ERouteLinks.ApiKeysPage);
      }
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data.errors[0].message,
        variant: EAlertVariants.error,
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('apiKeys.header'),
            route: ERouteLinks.ApiKeysPage,
          },
          t('editApiKey.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('editApiKey.header')}</StyledPageTitle>

      <ApiKeyForm onSubmit={onSubmit} initialFormValues={initialApiKeyValues} isEdit={true} />

      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export default EditApiKeyPage;

import { ITableColumns, ITableDataSource } from 'models/table';
import { IAuditLog } from 'models/auditLog';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import React from 'react';
import { ILimitedRole, IRole } from 'models/role';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';

export const mapAuditLogToDataSource = (auditLogs: IAuditLog[]): ITableDataSource[] =>
  auditLogs.map((auditLog) => ({
    id: auditLog.uniqueId,
    author: `${auditLog.authorFirstName} ${auditLog.authorLastName}`,
    accountRoles: auditLog.accountRoles,
    module: auditLog.module,
    eventType: auditLog.eventType,
    eventTypeUniqueId: auditLog.eventTypeUniqueId,
    eventName: auditLog.eventName,
    eventDate: auditLog?.eventDate,
  }));

export enum EventTypes {
  ADD = '9f815892-29a2-4471-adab-e0358545f2c3',
  EDIT = 'b5643ce1-cb61-4839-b7b1-296c54066034',
  DELETE = 'c700995c-d3b5-4562-949d-8ae1935fe259',
  DATA_EXPORT = '0157cdfd-0090-406f-9909-cee2b2384115',
  ENABLE = 'cb8c00c3-10ed-4686-a8a7-31bcee580f90',
  DISABLE = '93a3654c-3cb7-403b-ba42-576f2e655854',
  DISCARD = '234cca19-f7fe-4e21-8593-3394a76ac6ea',
  ACTIVATE = '4d064986-7fcc-4533-8d20-4c12a068c450',
  DEACTIVATE = '95d20adc-9e5b-46df-8813-255bb279fdd8',
  LOGIN = '7d41df96-dbc2-425b-985a-467585f52ac4',
  LOGOUT = 'f4e6c23a-eb0b-450f-9524-6d004db53447',
  BLOCK = '64b007ab-6d8c-4959-9833-60d8e48d0391',
  PASSWORD_CHANGE = 'ccf0cf7c-53a8-4e61-9ecf-da59c5d7a0f7',
}

export const getEventTypeColor = (eventType: EventTypes) => {
  switch (eventType) {
    case EventTypes.ADD:
    case EventTypes.ACTIVATE:
    case EventTypes.ENABLE:
      return EChipsVariants.success;

    case EventTypes.EDIT:
      return EChipsVariants.primary;

    case EventTypes.DELETE:
    case EventTypes.DISABLE:
    case EventTypes.DEACTIVATE:
    case EventTypes.DISCARD:
    case EventTypes.BLOCK:
      return EChipsVariants.error;

    case EventTypes.LOGIN:
    case EventTypes.LOGOUT:
    case EventTypes.PASSWORD_CHANGE:
      return EChipsVariants.warning100;

    case EventTypes.DATA_EXPORT:
    default:
      return EChipsVariants.inactive;
  }
};

interface IAuditLogTableColumns {
  dateFormat?: string;
}

export const getRoleNames = (rolesId: string[], roles: IRole[] | ILimitedRole[]) => {
  if (!rolesId) {
    return [];
  }
  const rolesArr = rolesId.map((roleId) => {
    const roleName = roles.find((role) => role.uniqueId === roleId);
    if (roleName) {
      return roleName.name;
    }
    return null;
  });
  return rolesArr.filter((item) => item);
};

export const auditLogTableColumns = ({
  dateFormat,
}: IAuditLogTableColumns): ITableColumns[] => [
  {
    id: '4eaea410-d36f-400b-895b-231c81f091cf',
    key: 'author',
    title: 'searchForAuditLog.auditLogTable.eventAuthor.column',
    isSortable: false,
  },
  {
    id: '4eaea411-d36f-400b-895b-231c81f091cf',
    key: 'accountRoles',
    isSortable: false,
    title: 'searchForAuditLog.auditLogTable.roleName.column',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) =>
      props.accountRoles
        ? props.accountRoles.map((roleName: string) => (
            <Chips text={roleName as string} variant={EChipsVariants.primary} />
          ))
        : '-',
  },
  {
    id: '4eaea412-d36f-400b-895b-231c81f091cf',
    key: 'module',
    title: 'searchForAuditLog.auditLogTable.module.column',
    isSortable: false,
  },
  {
    id: '4eaea413-d36f-400b-895b-231c81f091cf',
    key: 'eventType',
    title: 'searchForAuditLog.auditLogTable.eventType.column',
    isSortable: false,
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Chips text={props?.eventType} variant={getEventTypeColor(props.eventTypeUniqueId)} />
    ),
  },
  {
    id: '4eaea414-d36f-400b-895b-231c81f091cf',
    key: 'eventName',
    title: 'searchForAuditLog.auditLogTable.eventName.column',
    isSortable: false,
  },
  {
    id: '4eaea415-d36f-400b-895b-231c81f091cf',
    key: 'eventDate',
    title: 'searchForAuditLog.auditLogTable.eventDate.column',
    isSortable: false,
    dateFormat: dateFormat ?? undefined,
  },
  {
    id: '4eaea416-d36f-400b-895b-231c81f091cf',
    key: '',
    title: 'searchForAuditLog.auditLogTable.action.column',
    isSortable: false,
    width: '60px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <Link to={ERouteLinks.AuditLogViewPage.replace(':id', props.id)}>
        <Button variant={EButtonVariants.outlined} tableButton={true} icon={EIconTypes.view} />
      </Link>
    ),
  },
];

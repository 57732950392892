import React, { useEffect, useState } from 'react';
import { IAmmunitionFormValues } from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceAmmunitionSearchForm';
import { useTranslations } from 'hooks/useTranslations';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useAmmunitionFieldsFormSchema from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTAmmunitionForm/schemaValidation';
import { useLang } from 'models/langContext';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { EArtAmmunitionType } from 'constants/ArtAccessoryType';
import { getFieldsByAmmunitionType } from 'pages/AncillariesPage/helpers';
import Grid from '@mui/material/Grid';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { Typography } from '@mui/material';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { useScroll } from 'hooks/useScroll';
import { FormWyswigEditor } from 'components/molecules/FormWyswigEditor';

export enum ESearchFormLabels {
  ammunitionTypeUniqueId = 'ancillaryReferenceTable.addAncillary.ammunition.ammunitionType.label',
  manufacturerUniqueId = 'ancillaryReferenceTable.addAncillary.ammunition.ammunitionManufacturer.label',
  productName = 'ancillaryReferenceTable.addAncillary.ammunition.productName.label',
  factoryUniqueId = 'ancillaryReferenceTable.addAncillary.ammunition.factory.label',
  calibreUniqueId = 'ancillaryReferenceTable.addAncillary.ammunition.calibre.label',
  cartridgeCompositionUniqueId = 'ancillaryReferenceTable.addAncillary.ammunition.cartridgeComposition.label',
  colourOfPrimerSealantUniqueId = 'ancillaryReferenceTable.addAncillary.ammunition.colourOfPrimeSealant.label',
  projectileShapeUniqueId = 'ancillaryReferenceTable.addAncillary.ammunition.projectileShape.label',
  functionalTypeUniqueId = 'ancillaryReferenceTable.addAncillary.ammunition.functionalType.label',
  munitionTypeUniqueId = 'ancillaryReferenceTable.addAncillary.ammunition.munitionType.label',
  shotSizeUniqueId = 'ancillaryReferenceTable.addAncillary.ammunition.shotSize.label',
  munitionVelocityUniqueId = 'ancillaryReferenceTable.addAncillary.ammunition.munitionVelocity.label',
  headstampYearMarking = 'ancillaryReferenceTable.addAncillary.ammunition.headstampYearMarking.label',
  alphanumericalMarkings = 'ancillaryReferenceTable.addAncillary.ammunition.alphanumericalMarkings.label',
  notes = 'ancillaryReferenceTable.addAncillary.ammunition.notes.label',
}

const initialFormValues: IAmmunitionFormValues = {
  ammunitionTypeUniqueId: '',
  manufacturerUniqueId: '',
  productName: '',
  factoryUniqueId: '',
  calibreUniqueId: '',
  cartridgeCompositionUniqueId: '',
  colourOfPrimerSealantUniqueId: '',
  projectileShapeUniqueId: '',
  functionalTypeUniqueId: '',
  munitionTypeUniqueId: '',
  shotSizeUniqueId: '',
  munitionVelocityUniqueId: '',
  headstampYearMarking: null,
  alphanumericalMarkings: '',
  notes: '',
};

interface IARTAmmunitionForm {
  onSubmit: (arg: IAmmunitionFormValues) => void;
  onCancel: () => void;
  initialData?: IAmmunitionFormValues;
  isEdit?: boolean;
}

const ARTAmmunitionForm = ({
  onSubmit,
  onCancel,
  initialData,
  isEdit = false,
}: IARTAmmunitionForm) => {
  const { t } = useTranslations();
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();
  const { schema } = useAmmunitionFieldsFormSchema();
  const { elRef, scrollToError } = useScroll();
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm<IAmmunitionFormValues>({
    defaultValues: isEdit ? initialData : initialFormValues,
    resolver: yupResolver(schema),
  });

  const { selectedLanguage } = useLang();

  const {
    loadDictionaries,
    artAmmunitionType,
    artAmmunitionManufacturer,
    artAmmunitionFactory,
    artAmmunitionCalibreGauge,
    artAmmunitionCartridgeComposition,
    artAmmunitionColourOfPrimerSealant,
    artAmmunitionProjectileShape,
    artAmmunitionFunctionalType,
    artAmmunitionShotSize,
    artAmmunitionMunitionType,
    artAmmunitionMunitionVelocity,
  } = useAncillaries();

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([
        EDictionaryTypes.ArtAmmunitionType,
        EDictionaryTypes.ArtAmmunitionManufacturer,
        EDictionaryTypes.ArtAmmunitionFactory,
        EDictionaryTypes.ArtAmmunitionCalibreGauge,
        EDictionaryTypes.ArtAmmunitionCartridgeComposition,
        EDictionaryTypes.ArtAmmunitionColourOfPrimerSealant,
        EDictionaryTypes.ArtAmmunitionProjectileShape,
        EDictionaryTypes.ArtAmmunitionFunctionalType,
        EDictionaryTypes.ArtAmmunitionShotSize,
        EDictionaryTypes.ArtAmmunitionMunitionType,
        EDictionaryTypes.ArtAmmunitionMunitionVelocity,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    reset(initialFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  const submitForm = async (data: IAmmunitionFormValues) => {
    setSubmitLoading(true);
    await onSubmit(data);
    setSubmitLoading(false);
  };

  const handleCancel = () => {
    if (routerState?.prevPage) {
      navigate(routerState.prevPage);
    } else {
      onCancel();
    }
  };

  const ammunitionTypeChange = (value: EArtAmmunitionType | '') => {
    const notes = getValues('notes');
    if (isEdit) {
      reset({ ...initialData, ammunitionTypeUniqueId: value, notes });
    } else {
      reset({ ...initialFormValues, ammunitionTypeUniqueId: value, notes });
    }
    if (value) {
      const fields = getFieldsByAmmunitionType(value);
      setAccessFields(fields);
    } else {
      setAccessFields([]);
    }
  };

  useEffect(() => {
    if (isEdit && initialData?.ammunitionTypeUniqueId) {
      ammunitionTypeChange(initialData.ammunitionTypeUniqueId as EArtAmmunitionType);
      reset(initialData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkDisabled = (fieldName: string) => !accessFields.includes(fieldName);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Grid container spacing={2} columns={10} ref={elRef}>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          <SearchWrapper>
            <Typography variant="h5">
              {t('ancillariesDetails.accordion.title.label')}
            </Typography>
          </SearchWrapper>
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={12}>
          <FormSelect
            options={mapDictionaryToOptionProp(artAmmunitionType)}
            name="ammunitionTypeUniqueId"
            label={t(ESearchFormLabels.ammunitionTypeUniqueId)}
            setValue={setValue}
            setValueAdditionalBehavior={ammunitionTypeChange}
            canBeEmpty
            control={control}
            errors={errors}
            onSelect={ammunitionTypeChange}
            disabled={isEdit}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormSelect
            options={mapDictionaryToOptionProp(artAmmunitionManufacturer)}
            name="manufacturerUniqueId"
            label={t(ESearchFormLabels.manufacturerUniqueId)}
            setValue={setValue}
            canBeEmpty
            control={control}
            errors={errors}
            disabled={checkDisabled('manufacturerUniqueId')}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormInput
            name="productName"
            label={t(ESearchFormLabels.productName)}
            control={control}
            errors={errors}
            setValue={setValue}
            disabled={checkDisabled('productName')}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormSelect
            options={mapDictionaryToOptionProp(artAmmunitionFactory)}
            name="factoryUniqueId"
            label={t(ESearchFormLabels.factoryUniqueId)}
            setValue={setValue}
            canBeEmpty
            control={control}
            errors={errors}
            disabled={checkDisabled('factoryUniqueId')}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormSelect
            options={mapDictionaryToOptionProp(artAmmunitionCalibreGauge)}
            name="calibreUniqueId"
            label={t(ESearchFormLabels.calibreUniqueId)}
            setValue={setValue}
            canBeEmpty
            control={control}
            errors={errors}
            disabled={checkDisabled('calibreUniqueId')}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormSelect
            options={mapDictionaryToOptionProp(artAmmunitionCartridgeComposition)}
            name="cartridgeCompositionUniqueId"
            label={t(ESearchFormLabels.cartridgeCompositionUniqueId)}
            setValue={setValue}
            canBeEmpty
            control={control}
            errors={errors}
            disabled={checkDisabled('cartridgeCompositionUniqueId')}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormSelect
            options={mapDictionaryToOptionProp(artAmmunitionColourOfPrimerSealant)}
            name="colourOfPrimerSealantUniqueId"
            label={t(ESearchFormLabels.colourOfPrimerSealantUniqueId)}
            setValue={setValue}
            canBeEmpty
            control={control}
            errors={errors}
            disabled={checkDisabled('colourOfPrimerSealantUniqueId')}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormSelect
            options={mapDictionaryToOptionProp(artAmmunitionProjectileShape)}
            name="projectileShapeUniqueId"
            label={t(ESearchFormLabels.projectileShapeUniqueId)}
            setValue={setValue}
            canBeEmpty
            control={control}
            errors={errors}
            disabled={checkDisabled('projectileShapeUniqueId')}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormSelect
            options={mapDictionaryToOptionProp(artAmmunitionFunctionalType)}
            name="functionalTypeUniqueId"
            label={t(ESearchFormLabels.functionalTypeUniqueId)}
            setValue={setValue}
            canBeEmpty
            control={control}
            errors={errors}
            disabled={checkDisabled('functionalTypeUniqueId')}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormSelect
            options={mapDictionaryToOptionProp(artAmmunitionShotSize)}
            name="shotSizeUniqueId"
            label={t(ESearchFormLabels.shotSizeUniqueId)}
            setValue={setValue}
            canBeEmpty
            control={control}
            errors={errors}
            disabled={checkDisabled('shotSizeUniqueId')}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormSelect
            options={mapDictionaryToOptionProp(artAmmunitionMunitionType)}
            name="munitionTypeUniqueId"
            label={t(ESearchFormLabels.munitionTypeUniqueId)}
            setValue={setValue}
            canBeEmpty
            control={control}
            errors={errors}
            disabled={checkDisabled('munitionTypeUniqueId')}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormSelect
            options={mapDictionaryToOptionProp(artAmmunitionMunitionVelocity)}
            name="munitionVelocityUniqueId"
            label={t(ESearchFormLabels.munitionVelocityUniqueId)}
            setValue={setValue}
            canBeEmpty
            control={control}
            errors={errors}
            disabled={checkDisabled('munitionVelocityUniqueId')}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormInput
            name="headstampYearMarking"
            type={EFormInputTypes.number}
            InputProps={{ inputProps: { min: 1, max: 3000 } }}
            label={t(ESearchFormLabels.headstampYearMarking)}
            control={control}
            errors={errors}
            setValue={setValue}
            disabled={checkDisabled('headstampYearMarking')}
            required={true}
          />
        </Grid>
        <Grid item lg={2.5} md={3} sm={5} xs={10}>
          <FormInput
            name="alphanumericalMarkings"
            label={t(ESearchFormLabels.alphanumericalMarkings)}
            control={control}
            errors={errors}
            setValue={setValue}
            disabled={checkDisabled('alphanumericalMarkings')}
            required={true}
          />
        </Grid>

        <Grid item xs={12} lg={12}>
          <FormWyswigEditor
            name="notes"
            label={t(ESearchFormLabels.notes)}
            setValue={setValue}
            control={control}
            errors={errors}
            disabled={checkDisabled('notes')}
            setError={setError}
            clearErrors={clearErrors}
            charsLimit={5000}
          />
        </Grid>

        <Grid
          item
          xs={12}
          container
          columns={10}
          columnSpacing={2}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Grid item xs={12} container columnSpacing={3} rowGap={2} mt={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                type="submit"
                fullWidth
                label={t('general.save.button')}
                id="save-button"
                isLoading={isSubmitLoading}
                variant={EButtonVariants.contained}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                id="cancel-button"
                variant={EButtonVariants.outlined}
                onClick={handleCancel}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ARTAmmunitionForm;
